import React, { useContext } from 'react';
import { Thing } from '../../Api/Dto/CommonDto';
import { PreparedPlanThingElement } from '../common/PreparedPlanThingElement';
import { ThingStatus } from '../../Api/Dto/ThingDto';
import { PlanThingsContext } from '../Contexts/PlanThingsContext';

interface Props {
  things: Thing<Date>[];
  toggleStatus: (thing: Thing<Date>) => void;
  isEditMode: boolean;
  canRemove: boolean;
}

export const PreparedPlanThings = ({ things, toggleStatus, isEditMode, canRemove }: Props) => {
  const { planThingsActions } = useContext(PlanThingsContext);

  return (
    <>
      {things.map(thing => <PreparedPlanThingElement
        key={thing.id}
        thing={thing}
        isDone={thing.status !== ThingStatus.TO_DO}
        isEditMode={isEditMode}
        onCheck={() => toggleStatus(thing)}
        canCheck={!isEditMode || (thing.status !== ThingStatus.DONE && thing.status !== ThingStatus.CLOSED)}
        onRemove={() => canRemove && thing.status === ThingStatus.TO_DO && planThingsActions.remove(thing)}
      />)}
    </>
  );
}