import React, { useState, FormEvent } from 'react';
import { AuthContainer } from '../../Common/AuthContainer';
import { AuthForm } from '../Common/AuthForm';
import { handleEntityChange } from '../../Common/FormsHelper';
import { AuthInput } from '../Common/AuhInput';
import { AuthSubmit } from '../Common/AuthSubmit';
import { H1 } from '../Common/AuthTypography';
import { AuthClient, AuthTokenClient } from '../../Api';
import { handleErrors } from '../../Common/Forms/FormHandler';
import { AuthTermsConditions } from '../Common/AuthTermsConditions';
import { AuthInputPassword } from '../Common/AuthInputPassword';
import { Redirect } from 'react-router-dom';

interface EntityProps {
  email: string;
  firstName: string;
  password: string;
  termsConditionsAccepted: boolean;
}

interface ErrorProps {
  email: string[];
  firstName: string[];
  password: [];
  termsConditionsAccepted: string[];
}

export const SignUp: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [entity, setEntity] = useState<EntityProps>({
    email: '',
    firstName: '',
    password: '',
    termsConditionsAccepted: false,
  });
  const [errors, setErrors] = useState<ErrorProps>({
    email: [],
    firstName: [],
    password: [],
    termsConditionsAccepted: [],
  });

  const handleChange = (event: FormEvent) => !isLoading && handleEntityChange(event, setEntity, entity);

  const createAccount = async () => {
    await AuthClient.signUp(entity);
    return AuthTokenClient.generateOrganizationToken();
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading === true || !entity.firstName || !entity.email) {
      return;
    }

    setIsLoading(true);

    createAccount()
      .then(() => {
        setIsLoading(false);
        setIsFormSubmitted(true);
      })
      .catch(err => {
        setIsLoading(false);
        setErrors(handleErrors(errors, err));
      });
  };

  if (isFormSubmitted && entity.email) {
    return <Redirect to="/" />;
  }

  return (
    <AuthContainer isLoading={isLoading}>
      <H1>Sign up</H1>
      <AuthForm method="post" onSubmit={handleSubmit}>
        <AuthInput
          name="firstName"
          placeholder="Your first name"
          icon="person"
          value={entity.firstName}
          errors={errors.firstName}
          onChange={handleChange}
        />
        <AuthInput
          name="email"
          type="email"
          placeholder="Your e-mail address"
          icon="email"
          value={entity.email}
          errors={errors.email}
          onChange={handleChange}
        />
        <AuthInputPassword
          showErrors={true}
          value={entity.password}
          errors={errors.password}
          onChange={handleChange}
        />
        <AuthTermsConditions
          isChecked={entity.termsConditionsAccepted}
          errors={errors.termsConditionsAccepted}
          onCheck={() => setEntity({
            ...entity, 
            termsConditionsAccepted: !entity.termsConditionsAccepted,
          })}
        />
        <AuthSubmit
          buttonText="Create account"
          alterHref="/login"
          alterText="I already have account"
        />
      </AuthForm>
    </AuthContainer>
  );
}