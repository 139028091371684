import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { User } from '../Dto/CommonDto';
import { IsoDate } from '../Interface';
import { transformEntity } from '../Helpers';

export class SubscriptionApiClient extends AccessTokenApiClient {
  trial(): Promise<User<Date>> {
    return this.apiRequest
      .post<User<IsoDate>>('trial', {})
      .then(({ data }) => transformEntity<User<Date>>(data));
  }
}
