import React, { useState, useEffect, useContext } from 'react';
import glamorous from 'glamorous';
import { H3, Small } from '../../Common/Typography';
import { MediaQuery } from '../../Common/Styles';
import { DynamicButton } from '../../Common/DynamicButton';
import { PlanThingsContext } from '../Contexts/PlanThingsContext';
import { CalendarContext } from '../Contexts/CalendarContext';
import { MonthlyPlansContext } from '../Contexts/MonthlyPlansContext';
import { ResponsiveList } from '../../Common/Hoc/ResponsiveList';
import { ThingElement } from '../common/ThingElement';
import { DailyPlanClient } from '../../Api';
import { ShowMobileThingsContext } from '../Contexts/ShowMobileThingsContext';
import { CalendarOverviewContext } from '../Contexts/CalendarOverviewContext';
import { AddThingsTutorial } from '../common/AddThingsTutorial';
import useI18n from '../../Common/Hooks/useI18n';

const Header = glamorous.div({
  [MediaQuery.mobileOnly]: {
    display: 'none',
  },
});

export const CreatePlan = () => {
  const { t } = useI18n();
  const { calendarOverviewActions } = useContext(CalendarOverviewContext);
  const { setShowMobileThings } = useContext(ShowMobileThingsContext);
  const { calendarActions } = useContext(CalendarContext);
  const { monthlyPlansActions } = useContext(MonthlyPlansContext);
  const { planThings, planThingsActions } = useContext(PlanThingsContext);
  const [newThingName, setNewThingName] = useState<string>('');
  const [canCreate, setCanCreate] = useState<boolean>(false);

  const validateCanCreate = (): boolean => {
    if (planThings.length === 0) {
      return false;
    }

    const currentPlan = monthlyPlansActions.getPlan(calendarActions.getCurrentDay());

    if (!currentPlan) {
      return true;
    }

    return !monthlyPlansActions.hasSameThings(currentPlan, planThings);
  }

  const createPlan = () => canCreate && DailyPlanClient
    .create(calendarActions.getCurrentDate(), planThings)
    .then(data => {
      monthlyPlansActions.add(data);
      calendarOverviewActions.decrease();
      setShowMobileThings !== undefined && setShowMobileThings(false);
    });

  useEffect(() => {
    const validation = validateCanCreate();

    validation !== canCreate && setCanCreate(validation);
  }, [planThings]);

  return (
    <>
      <Header>
        <H3>{t('Day plan')}</H3>
        <Small>{t('No things planned this day')}</Small>
      </Header>
      {
        planThings.length > 0
          ? (
            <ResponsiveList>
              {planThings.map(planThing => <ThingElement
                key={planThing.id} 
                thing={planThing}
                onClick={() => planThingsActions.remove(planThing)}
              />)}
            </ResponsiveList>
          ) : (
            <AddThingsTutorial /> 
          )
      }
      <DynamicButton
        text="Save plan"
        isActive={canCreate}
        fullWidth={true}
        onClick={createPlan}
        styles={{
          marginTop: '10px',
        }}
      />
    </>
  );
};