import React, { useState, useEffect } from 'react'
import { AuthContainer } from '../../Common/AuthContainer';
import { H1 } from './AuthTypography';
import { Icon, P, A } from './AuthStyles';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  email: string;
  type: EmailType;
}

export enum EmailType {
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

const parseDomain = (email: string): string => {
  const splittedEmail = email.split('@');

  return splittedEmail.length === 2 ? splittedEmail[1] : '';
}

export const ConfirmEmail = ({ email, type = EmailType.ACTIVATE_ACCOUNT }: Props) => {
  const { t } = useI18n();
  const [domain, setDomain] = useState<string>(parseDomain(email));

  useEffect(() => {
    setDomain(parseDomain(email));
  }, [email]);

  return (
    <AuthContainer>
      <H1><Icon>email</Icon></H1>
      <P>{t("We've sent you a message to")} { email ? <strong>{email}</strong> : t('your e-mail address') }.</P>
      <P>{t('Please check for an email from us and click on the included link')} {
        type === EmailType.FORGOT_PASSWORD ? ` ${t('to set a new password')}.` : ` ${t('to activate account')}.`
      }</P>
      { email && <A to={`//${domain}`} target="_blank">{t('Go to')} <strong>{domain}</strong></A> }
    </AuthContainer>
  )
}
