import React from 'react';
import { ProTrialModal } from './ProTrialModal';

export const DrawersLimitModal = () => (
  <ProTrialModal
    title="You have reached limit of maximum active drawers"
    icon="inbox"
    freeLimits="3 active drawers"
    proLimits="200 active drawers"
  />
);
