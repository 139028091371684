import React, { useContext, useEffect, useState } from 'react';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';
import { TemplateContext } from '../Common/Contexts/TemplateContext';
import { PageLayout } from '../Common/PageLayout';
import { H1 } from '../Common/Typography';
import { DailyPlanClient, ThingClient } from '../Api';
import { DailyPlan, DailyPlanStatus, Thing } from '../Api/Dto/CommonDto';
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import { AppStatus } from '../Common/AppStatus';
import glamorous from 'glamorous';
import { PageLayoutTheme, MonthRaw, MonthJS } from '../Common/Interface';
import { toMonthRaw } from '../Calendar/helpers';
import { AxiosError } from 'axios';
import { Color } from '../Common/Styles';
import { isReadyProps } from '../SmartRating/interface';
import { PlanOverview } from './Elements/PlanOverview';
import { ThingStatus } from '../Api/Dto/ThingDto';
import dayjs from 'dayjs';
import { YmdStrict } from '../Calendar/interfaces';
import { TypeOfDay } from './constants';
import useI18n from '../Common/Hooks/useI18n';

const Container = glamorous.div({
  flex: '1 1 auto',
  display: 'flex',
});

const DayChanger = glamorous.span<isReadyProps>(
  {
    color: Color.appleGreen,
    textDecoration: 'underline',
    cursor: 'pointer',
    transitionProperty: 'opacity, transform',
    transitionDuration: '.3s',

    ':hover': {
      textDecoration: 'none',
    }
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
    transform: `translate3d(0, ${isReady ? '0' : '30px'}, 0)`,
  }),
);

export const YourDay = () => {
  const { t } = useI18n();
  const { isTemplateHidden, templateActions } = useContext(TemplateContext);

  const [appStatus, setAppStatus] = useAppStatus();

  const [yesterdayPlan, setYesterdayPlan] = useState<DailyPlan<Date> | null>(null);
  const [todayPlan, setTodayPlan] = useState<DailyPlan<Date> | null>(null);
  const [tomorrowPlan, setTomorrowPlan] = useState<DailyPlan<Date> | null>(null);

  const [typeOfDay, setTypeOfDay] = useState<TypeOfDay | undefined>(undefined);

  const parseTypeOfDay = (plan: DailyPlan<Date>): TypeOfDay | null => {
    const todayDate = new Date();

    const [today, activePlan]: Date[] = [
      new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate()),
      new Date(plan.deadline.year, plan.deadline.month, plan.deadline.day || 1),
    ];

    if (today > activePlan) {
      return TypeOfDay.YESTERDAY;
    }

    if (today < activePlan) {
      return Math.abs(dayjs(today).diff(activePlan, 'day')) === 1
        ? TypeOfDay.TOMORROW
        : null;
    }

    return TypeOfDay.TODAY;
  }

  const handleSetTypeOfDay = (plan: DailyPlan<Date>): void => {
    const parsedTypeOfDay = parseTypeOfDay(plan);

    setTypeOfDay(parsedTypeOfDay || TypeOfDay.TODAY);

    switch (parsedTypeOfDay) {
      case TypeOfDay.YESTERDAY:
        return setYesterdayPlan(plan);
      case TypeOfDay.TODAY:
        return setTodayPlan(plan);
      case TypeOfDay.TOMORROW:
        return setTomorrowPlan(plan);
    }
  }

  const isToday = (deadline: YmdStrict): boolean => {
    const currentTime = new Date();

    return deadline.year === currentTime.getFullYear()
      && deadline.month === currentTime.getMonth()
      && deadline.day === currentTime.getDate();
  }

  const getNextDay = ({year, month, day}: YmdStrict): YmdStrict => {
    const date = dayjs(new Date(year, month, day)).add(1, 'day').toDate();

    return {
      year: date.getFullYear(),
      month: date.getMonth() as MonthJS,
      day: date.getDate(),
    }
  }

  const fetchTomorrow = (deadline: YmdStrict) => {
    const { year, month, day } = getNextDay(deadline);

    DailyPlanClient
      .getOne(year, toMonthRaw(month), day)
      .then(data => setTomorrowPlan(data))
      .catch(() => {});
  }

  const fetchData = (year: number, month: MonthRaw, day: number) => Promise
    .all([
      DailyPlanClient
        .getOne(year, month, day)
        .catch((err: AxiosError)  => {
          if (err.response && err.response.status === 404) {
            return null;
          }

          setAppStatus(AppStatus.READY);

          return null;
        }),
      DailyPlanClient
        .getActive()
        .then(activePlan => {
          activePlan !== null && handleSetTypeOfDay(activePlan);

          return activePlan;
        })
        .catch(() => {
          setAppStatus(AppStatus.READY);

          return null;
        }),
    ])
    .then(([planForToday, activePlan]) => {
      setAppStatus(AppStatus.READY);

      if (planForToday === null) {
        return;
      }

      isToday(planForToday.deadline) && fetchTomorrow(planForToday.deadline);

      setTodayPlan(planForToday);

      activePlan === null && handleSetTypeOfDay(planForToday)
    });

  useEffect(() => {
    isTemplateHidden && templateActions.show();

    if (appStatus === AppStatus.LOADING) {
      const date = new Date();

      fetchData(date.getFullYear(), toMonthRaw(date.getMonth()), date.getDate());
    }
  }, [appStatus]);

  const togglePlan = () => {
    switch (typeOfDay) {
      case TypeOfDay.TODAY:
        return setTypeOfDay(
          yesterdayPlan !== null && yesterdayPlan.status !== DailyPlanStatus.COMPLETED
            ? TypeOfDay.YESTERDAY
            : TypeOfDay.TOMORROW
        );
      default:
        return setTypeOfDay(TypeOfDay.TODAY);
    }
  }

  const hasPlanThing = (plan: DailyPlan<Date> | null, thing: Thing<Date>): boolean => (
    plan !== null 
    && (plan.status !== DailyPlanStatus.COMPLETED && plan.status !== DailyPlanStatus.DELETED)
    && plan.things.find(planThing => planThing.id === thing.id) !== undefined
  );

  const updatePlanThing = (plan: DailyPlan<Date>, thing: Thing<Date>): DailyPlan<Date> => ({
    ...plan,
    things: plan.things.map(planThing => planThing.id === thing.id
      ? thing
      : planThing  
    ),
  });

  const setThingStatus = (thing: Thing<Date>, status: ThingStatus) => {
    const newThing: Thing<Date> = {
      ...thing,
      status,
    };

    hasPlanThing(yesterdayPlan, thing) && setYesterdayPlan(updatePlanThing(yesterdayPlan as DailyPlan<Date>, newThing));
    hasPlanThing(todayPlan, thing) && setTodayPlan(updatePlanThing(todayPlan as DailyPlan<Date>, newThing));
    hasPlanThing(tomorrowPlan, thing) && setTomorrowPlan(updatePlanThing(tomorrowPlan as DailyPlan<Date>, newThing));
  }

  const toggleThingStatus = (thing: Thing<Date>) => {
    if (thing.status === ThingStatus.CLOSED || thing.status === ThingStatus.DELETED) {
      return;
    }

    const oldStatus: ThingStatus = thing.status;
    const newStatus: ThingStatus = oldStatus === ThingStatus.TO_DO ? ThingStatus.DONE : ThingStatus.TO_DO;

    setThingStatus(thing, newStatus);

    setTimeout(() => {
      ThingClient
        .update(thing, {
          status: newStatus
        })
        .catch(() => setThingStatus(thing, oldStatus));
    }, 300);
  }

  const getSelectedPlan = () => {
    switch (typeOfDay) {
      case TypeOfDay.TOMORROW:
        return tomorrowPlan;
      case TypeOfDay.YESTERDAY:
        return yesterdayPlan;
      case TypeOfDay.TODAY:
      default:
        return todayPlan;
    }
  }

  return (
    <DefaultTheme>
      <PageLayout asFlex theme={PageLayoutTheme.NO_BOTTOM_PADDING} scrollable={false}>
        <H1>
          {t('Your plan')} <DayChanger isReady={typeOfDay !== undefined} onClick={togglePlan}>
            {typeOfDay !== undefined && typeOfDay.toLowerCase()}
          </DayChanger>
        </H1>
        <Container>
          <PlanOverview plan={getSelectedPlan()} typeOfDay={typeOfDay || TypeOfDay.TODAY} appStatus={appStatus} toggleThingStatus={toggleThingStatus} togglePlan={togglePlan} />
        </Container>
      </PageLayout>
    </DefaultTheme>
  )
};
