import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { ThingsAppMode } from './interfaces';
import { Font, Color } from '../Common/Styles';
import { ThingsContext } from './Contexts/ThingsContext';
import { Drawer } from '../Api/Dto/CommonDto';
import useI18n from '../Common/Hooks/useI18n';

interface Props {
  thingsAppMode: ThingsAppMode;
  thingsSelectedQnt: number;
  drawer: Drawer<Date>;
}

interface ContainerProps {
  thingsAppMode: ThingsAppMode;
}

const Container = glamorous.div<ContainerProps>(
  {
    margin: '12px 0',
    width: '100%',
    fontFamily: Font.condensed,
    letterSpacing: '-.3px',
    color: Color.dark74,
    fontSize: '14px',
  },
  ({ thingsAppMode }) => ({
    color: thingsAppMode === ThingsAppMode.VIEW ? Color.dark74 : Color.twilight,
  }),
);

export const ThingsCounter = ({ thingsAppMode, thingsSelectedQnt, drawer }: Props) => {
  const { t } = useI18n();
  const { thingsActions } = useContext(ThingsContext);

  const changeThingsByQuantity = (quantity: number): string => quantity === 1 ? t('thing') : t('things')

  return (
    <Container thingsAppMode={thingsAppMode}>
      {thingsAppMode === ThingsAppMode.VIEW
        ? `${thingsActions.count(drawer)} ${changeThingsByQuantity(thingsActions.count(drawer))} ${t('to do')}` 
        : `${thingsSelectedQnt} ${changeThingsByQuantity(thingsSelectedQnt)} ${t('selected')}`
      }
    </Container>
  );
};
