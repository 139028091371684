import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import glamorous from 'glamorous';
import { Size } from './Styles';

interface Props {
  percentage: number;
  size: Size;
  pathColor: string;
  trailColor: string;
  pathTransitionDuration?: number;
  pathTransitionDelay?: number
}

interface ContainerProps {
  size: Size;
  pathColor: string;
  trailColor: string;
  pathTransitionDuration: number;
  pathTransitionDelay: number
}

const getWidth = (size: Size): number => {
  switch (size) {
    case Size.huge:
      return 200;
    case Size.tiny:
      return 30;
    case Size.small:
      return 50;
    case Size.medium:
    default:
      return 65;
  }
}

const getTrailWidth = (size: Size): number => {
  switch (size) {
    case Size.huge:
      return .25;
    case Size.medium:
    default:
      return 5;
  }
}

const getPathWidth = (size: Size): number => {
  switch (size) {
    case Size.huge:
      return 5;
    case Size.medium:
    default:
      return 6;
  }
}

const Container = glamorous.div<ContainerProps>(
  {},
  ({ size, pathColor, trailColor, pathTransitionDelay, pathTransitionDuration }) => ({
    '& .CircularProgressbar': {
      width: `${getWidth(size)}px`,
    },
    '& .CircularProgressbar .CircularProgressbar-trail': {
      stroke: trailColor,
      fill: trailColor,
      strokeWidth: `${getTrailWidth(size)}px`,
    },

    '& .CircularProgressbar .CircularProgressbar-path': {
      stroke: pathColor,
      strokeWidth: `${getPathWidth(size)}px`,
      ransitionProperty: 'fill,stroke,stroke-dashoffset',
      transitionDuration: `${pathTransitionDuration}ms`,
      transitionDelay: `${pathTransitionDelay}ms`,
    }
  })
);

export const CircularProgressBar = ({
  percentage,
  pathColor,
  trailColor,
  size,
  pathTransitionDelay = 0,
  pathTransitionDuration = 500,
}: Props) => {
  return (
    <Container
      // @ts-ignore
      size={size}
      pathColor={pathColor}
      trailColor={trailColor}
      pathTransitionDelay={pathTransitionDelay}
      pathTransitionDuration={pathTransitionDuration}
    >
      <CircularProgressbar percentage={percentage} />
    </Container>
  )
};
