import { createContext, Dispatch } from 'react';
import { Thing } from '../../Api/Dto/CommonDto';
import { PlanThingsReducerAction, PlanThingsAction } from '../Reducers/PlanThingsReducer';

interface PlanThingsActionsProps {
  add: (thing: Thing<Date>) => void;
  update: (thing: Thing<Date>) => void;
  remove: (thing: Thing<Date>) => void;
  setThings: (things: Thing<Date>[]) => void;
  has: (thing: Thing<Date>) => boolean;
}

interface PlanThingsContextProps {
  planThings: Thing<Date>[];
  planThingsActions: PlanThingsActionsProps;
}

const planThingsActions = (planThings: Thing<Date>[], dispatch: Dispatch<PlanThingsReducerAction>): PlanThingsActionsProps => ({
  add: (thing) => dispatch({ type: PlanThingsAction.ADD, value: thing }),
  update: (thing) => dispatch({ type: PlanThingsAction.UPDATE, value: thing }),
  remove: (thing) => dispatch({ type: PlanThingsAction.REMOVE, value: thing }),
  setThings: (things) => dispatch({ type: PlanThingsAction.SET_ALL, values: things }),
  has: (thing) => planThings.find(planThing => planThing.id === thing.id) !== undefined,
});

export const SetPlanThingsContext = (
  planThings: Thing<Date>[],
  dispatch: Dispatch<PlanThingsReducerAction>,
): PlanThingsContextProps => ({
  planThings,
  planThingsActions: planThingsActions(planThings, dispatch),
});

export const PlanThingsContext = createContext<PlanThingsContextProps>({} as unknown as PlanThingsContextProps);
