import React, { useState, useEffect, ReactNode } from 'react';
import glamorous from 'glamorous';
import { MediaQuery } from './Styles';
import { Div100vh } from './Libs/Div100vh';
import { PageLayoutTheme } from './Interface';

type SCROLL_MODE = 'horizontal' | 'vertical';

interface Props {
  children: ReactNode;
  scrollable?: boolean;
  scrollMode?: SCROLL_MODE;
  theme?: PageLayoutTheme;
  asFlex?: boolean;
  flexDirection?: string;
  showMobileMenu?: boolean;
}

interface ContainerProps {
  scrollable: boolean;
  scrollMode: SCROLL_MODE;
  isShown: boolean;
  theme: PageLayoutTheme;
  asFlex: boolean;
  flexDirection: string;
  showMobileMenu: boolean;
}

const getThemePadding = (theme: PageLayoutTheme, pixels: number = 30, isMobile: boolean) => {
  const bottomPadding = isMobile ? 45 : 0;

  switch (theme) {
    case PageLayoutTheme.NO_TITLE:
      return `0 ${pixels}px ${pixels + bottomPadding}px ${pixels}px`;
    case PageLayoutTheme.NO_PADDING:
      return `0 0 ${bottomPadding}px 0`;
    case PageLayoutTheme.NO_BOTTOM_PADDING:
      return `${pixels}px ${pixels}px ${bottomPadding}px ${pixels}px`;
    case PageLayoutTheme.NO_BOTTOM_RIGHT_PADDING:
      return `${pixels}px 0 ${bottomPadding}px ${pixels}px`;
    case PageLayoutTheme.DEFAULT:
    default:
      return `${pixels}px ${pixels}px ${pixels + bottomPadding}px ${pixels}px`;
  }
}

const Container = glamorous.div<ContainerProps>(
  {
    position: 'relative',
    transitionProperty: 'transform, opacity',
    transitionDuration: '500ms',

    [MediaQuery.mobileOnly]: {
      height: '100%',
    },
    [MediaQuery.tableDesktop]: {
      height: '100%',
      scrollbarWidth: 'none',

      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  // @ts-ignore
  ({ scrollable, scrollMode, isShown, theme, asFlex, flexDirection, showMobileMenu }) => {
    const transitionObj: any = {
      transform: `translate3d(0, ${isShown ? '0' : '10px'}, 0)`,
      opacity: isShown ? 1 : 0,
      padding: getThemePadding(theme, 30, false),

      [MediaQuery.mobileOnly]: {
        padding: getThemePadding(theme, 15, showMobileMenu),
      },
    }

    if (asFlex) {
      transitionObj.display = 'flex';
      transitionObj.flexDirection = flexDirection || 'column';
    }

    if (!scrollable) {
      return {
        overflowY: 'hidden',
        overflowX: 'hidden',
        ...transitionObj,
      }
    }

    return {
      ...transitionObj,
      overflowY: scrollMode === 'vertical' ? 'scroll' : 'hidden !important',
      overflowX: scrollMode === 'horizontal' ? 'scroll' : 'hidden !important',

      [MediaQuery.mobileOnly]: {
        WebkitOverflowScrolling: 'touch',
        padding: getThemePadding(theme, 15, showMobileMenu),
      },
      [MediaQuery.tableDesktop]: {
        msOverflowStyle: 'none',
        overflowY: '-moz-scrollbars-none',
        overflowX: '-moz-scrollbars-none',
      },
    };
  }
);

export const PageLayout = ({
  children, 
  scrollable = true,
  scrollMode = 'vertical',
  theme = PageLayoutTheme.DEFAULT,
  asFlex = false,
  flexDirection = 'column',
  showMobileMenu = true 
}: Props) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    setIsShown(false);
    setTimeout(() => setIsShown(true), 50);
  }, []);

  const applyStyles = (selectedTheme: PageLayoutTheme) => {
    return {
      height: '100rvh',
    };
  }

  return (
    <Div100vh style={applyStyles(theme)}>
      <Container
        isShown={isShown}
        scrollable={scrollable}
        scrollMode={scrollMode}
        theme={theme as never}
        asFlex={asFlex}
        flexDirection={flexDirection}
        showMobileMenu={showMobileMenu}
      >
        {children}
      </Container>
    </Div100vh>
  )
}