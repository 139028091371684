import React from 'react';
import { ProTrialModal } from './ProTrialModal';

export const ThingsLimitModal = () => (
  <ProTrialModal
    title="You have reached limit of maximum things to do"
    icon="playlist_add_check"
    freeLimits="20 things to do"
    proLimits="1000 things to do"
  />
);
