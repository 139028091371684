import React, { useContext, useState, useEffect } from 'react';
import { DailyPlan, Thing } from '../../Api/Dto/CommonDto';
import glamorous from 'glamorous';
import { ThingStatus } from '../../Api/Dto/ThingDto';
import { hexToRgba, Color, Font } from '../../Common/Styles';
import { uuidToColor } from '../../Common/Helpers';
import { UserContext } from '../../Common/Contexts/UserContext';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  plan: DailyPlan<Date>;
}

interface ThingElementProps {
  backgroundColor: string;
  isVisible: boolean;
}

interface ContainerProps {
  hasThingsToDo: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  ({ hasThingsToDo }) => ({
    textAlign: hasThingsToDo ? 'left' : 'center',
  }),
);

const Header = glamorous.span({
  fontFamily: Font.condensed,
  fontSize: '14px',
  color: Color.dark,
});

const Icon = glamorous.i({
  color: Color.orange,
  fontSize: '24px',
});

const ThingsContainer = glamorous.div({
  marginTop: '10px',
})

const ThingElement = glamorous.div<ThingElementProps>(
  {
    color: Color.darkGreyBlue,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    fontFamily: Font.condensed,
    padding: '3px 5px',
    marginBottom: '3px',
    borderRadius: '4px',
    width: '100%',
  },
  ({ backgroundColor, isVisible }) => ({
    backgroundColor,
    display: isVisible ? 'block' : 'none',
  }),
);

export const MobileDayPlanActive = ({ plan }: Props) => {
  const { t } = useI18n();
  const { user } = useContext(UserContext);

  const [thingsToDo, setThingsToDo] = useState<Thing<Date>[]>([]);

  useEffect(() => {
    setThingsToDo(plan.things.filter(thing => thing.status === ThingStatus.TO_DO));
  }, [plan]);

  return (
    <Container hasThingsToDo={thingsToDo.length > 0}>
      {thingsToDo.length > 0
        ? (
          <>
            <Header>{thingsToDo.length} {t('things to do')}</Header>
            <ThingsContainer>
              {thingsToDo.map(thing => (
                <ThingElement
                  key={thing.id}
                  backgroundColor={hexToRgba(uuidToColor(thing.projectId || user.id), 0.5)}
                  isVisible={thing.status === ThingStatus.TO_DO}
                >
                  {thing.title}
              </ThingElement>
              ))}
            </ThingsContainer>
          </>
        )
        : (
          <>
            <Icon className="material-icons">notification_important</Icon>
            <Header>{t('No things to do')}</Header>
          </>
        )
      }
    </Container>
  );
}
