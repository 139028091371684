import React from 'react';
import glamorous from 'glamorous';
import { Color } from '../../Common/Styles';
import { Small } from '../../Common/Typography';
import useI18n from '../../Common/Hooks/useI18n';

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '100%',
});

const Icon = glamorous.i({
  color: Color.orange,
  margin: '0 auto',
  fontSize: '24px',
});

export const GridDayPlanActiveNoThings = () => {
  const { t } = useI18n();

  return (
  <Container>
    <Icon className="material-icons">notification_important</Icon>
    <Small>{t('No things to do')}</Small>
  </Container>
  );
}
