import React, { useContext } from 'react';
import { ResponsiveList } from '../Common/Hoc/ResponsiveList';
import { ThingElement } from './common/ThingElement';
import { CalendarThingsContext } from './Contexts/CalendarThingsContext';
import { PlanThingsContext } from './Contexts/PlanThingsContext';
import { AddNewThing } from './common/AddNewThing';
import { Thing } from '../Api/Dto/CommonDto';

export const CalendarThings = () => {
  const { calendarThings, calendarThingsActions } = useContext(CalendarThingsContext);
  const { planThingsActions } = useContext(PlanThingsContext);

  const afterCreateThing = (thing: Thing<Date>) => calendarThingsActions.add(thing);

  return (
    <>
      <ResponsiveList>
        {calendarThings.map(thing => <ThingElement
          key={thing.id}
          thing={thing}
          onClick={() => planThingsActions.add(thing)}
          isHidden={planThingsActions.has(thing)}
        />)}
      </ResponsiveList>
      <AddNewThing afterSave={afterCreateThing} />
    </>
  );
}
