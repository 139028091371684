import { useEffect, MutableRefObject } from 'react';

export function useOnClickOutside<T = any>(ref: MutableRefObject<T>, handler: Function) {
  useEffect(
    () => {
      const listener = (event: Event) => {
        // @ts-ignore
        ref.current && !ref.current.contains(event.target) && handler();
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  );
}
