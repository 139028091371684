import React, { useReducer, useState, useEffect } from 'react';
import { CalendarContext, SetCalendarContext } from './Contexts/CalendarContext';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import { MonthlyPlansReducer, MonthlyPlansAction } from './Reducers/MonthlyPlansReducer';
import { Ymd, YmdStrict } from './interfaces';
import { MonthJS, MonthRaw } from '../Common/Interface';
import { getCurrentDayOrFirstOne } from './common';
import dayjs from 'dayjs';
import { AppStatus } from '../Common/AppStatus';
import { MonthlyPlansContext, SetMonthlyPlansContext } from './Contexts/MonthlyPlansContext';
import { CalendarDesktop } from './CalendarDesktop';
import { toMonthJS, toMonthRaw } from './helpers';
import { DailyPlanClient } from '../Api';
import MediaQuery from 'react-responsive';
import { MediaQuerySize } from '../Common/Styles';
import { CalendarMobile } from './CalendarMobile';

interface MatchParams {
  year?: string;
  month?: string;
  day?: string;
}

const _CalendarController = ({ history, match }: RouteComponentProps<MatchParams>) => {
  const [calendarStatus, setCalendarStatus] = useAppStatus();

  const [monthlyPlans, dispatch] = useReducer(MonthlyPlansReducer, {});

  const [currentDate, setCurrentDate] = useState<Ymd>({ year: 0, month: 0, day: 0 });
  const [today, setToday] = useState<YmdStrict>({
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() as MonthJS,
    day: (new Date()).getDate(),
  });

  const makeYmdFromParams = ({ year, month, day }: MatchParams): Ymd | false => {
    const parsedYear = year !== undefined ? Number(year) : (new Date()).getFullYear();
    const parsedMonth = month !== undefined ? Number(month) - 1 : (new Date()).getMonth();
    const parsedDay = day !== undefined ? Number(day) : getCurrentDayOrFirstOne(parsedYear, parsedMonth);
  
    if (parsedYear < 2019 || (parsedYear === 2019 && parsedMonth < 9)) {
      return false;
    }
  
    if (parsedMonth > 11 || parsedMonth < 0 || parsedDay < 1) {
      return false;
    }
  
    if (dayjs(new Date(parsedYear, parsedMonth, 1)).daysInMonth() < parsedDay) {
      return false;
    }
  
    return {
      year: parsedYear,
      month: parsedMonth as MonthJS,
      day: parsedDay,
    };
  };

  const isCurrentDate = (date: Ymd): boolean => currentDate.year === date.year && currentDate.month === date.month;

  const fetchPlans = (year: number, month: MonthRaw) => {
    setCalendarStatus(AppStatus.LOADING);

    currentDate.year !== 0
      && isCurrentDate({ year, month: toMonthJS(month) }) 
      && DailyPlanClient
          .getGrouppedList(year, month)
          .then(({ data }) => {
            if (isCurrentDate({ year, month: toMonthJS(month) })) {
              dispatch({ type: MonthlyPlansAction.SET_ALL, values: data });
              setCalendarStatus(AppStatus.READY);
            }
          })
    };

  useEffect(() => {
    const newYmd = makeYmdFromParams(match.params);

    if (newYmd === false) {
      setCalendarStatus(AppStatus.FAILED);
    } else {
      setCurrentDate(newYmd);
    }

    const currentTime = new Date();

    if (today.day !== currentTime.getDate()) {
      setToday({
        year: currentTime.getFullYear(),
        month: currentTime.getMonth() as MonthJS,
        day: currentTime.getDate(),
      });
    }
  }, [match.path, match.url]);

  useEffect(() => {
    fetchPlans(currentDate.year, toMonthRaw(currentDate.month))
  }, [currentDate.year, currentDate.month]);

  useEffect(() => {
    if (currentDate.year === 0 || currentDate.month === 0) {
      return;
    }

    setCalendarStatus(AppStatus.LOADING);
  }, [currentDate.year, currentDate.month]);

  return (
    <CalendarContext.Provider value={SetCalendarContext(history, currentDate, today, [calendarStatus, setCalendarStatus])}>
      <MonthlyPlansContext.Provider value={SetMonthlyPlansContext(monthlyPlans, dispatch)}>
        <MediaQuery maxWidth={MediaQuerySize.maxMobileSize}>
          <CalendarMobile isDayView={match.params.day !== undefined} />
        </MediaQuery>
        <MediaQuery minWidth={MediaQuerySize.minTabletSize}>
          <CalendarDesktop />
        </MediaQuery>
      </MonthlyPlansContext.Provider>
    </CalendarContext.Provider>
  )
};

export const CalendarController = withRouter(_CalendarController);
