import { AppStatus } from '../../Common/AppStatus';
import { createContext } from 'react';

interface ThingsStatusContextProps {
  thingsStatus: AppStatus;
}

export const SetThingsStatusContext = (thingsStatus: AppStatus): ThingsStatusContextProps => ({
  thingsStatus,
});

export const ThingsStatusContext = createContext<ThingsStatusContextProps>({} as unknown as ThingsStatusContextProps);
