import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { Color, Font } from '../Common/Styles';
import { PlanThingsContext } from './Contexts/PlanThingsContext';
import { CalendarThings } from './CalendarThings';
import useI18n from '../Common/Hooks/useI18n';

const ThingsPlanned = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  color: Color.dark66,
  fontFamily: Font.condensed,
  fontSize: '14px',
  padding: '0 10px 10px 10px',

  '& > span:last-child': {
    color: Color.appleGreen,
  }
});

export const CalendarMobileThings = () => {
  const { t } = useI18n();
  const { planThings } = useContext(PlanThingsContext);

  return (
    <>
      <ThingsPlanned>
        <span>{planThings.length} {t('things planned')}</span>
        <span>{t('Tap on a thing to add to the plan')}</span>
      </ThingsPlanned>
      <CalendarThings />
    </>
  )
};
