import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Login } from '../../Auth/Login';
import { ForgotPassword } from '../../Auth/ForgotPassword';
import { SignUp } from '../../Auth/SignUp';
import { VerifyAccount } from '../../Auth/VerifyAccount';
import { ResendActivation } from '../../Auth/ResendActivation';
import { Hello } from '../../Auth/Hello';
import { MainApp } from '../../Common/Hoc/MainApp';
import { Support } from '../../Support';
import { Things } from '../../Things';
import { Calendar } from '../../Calendar';
import { SmartRating } from '../../SmartRating';
import { YourDay } from '../../YourDay';
import { MainMenu } from '../../MainMenu';
import { Profile } from '../../Profile';
import { Logout } from '../../Logout';
import { SetNewPassword } from '../../Auth/SetNewPassword';
import { WhatsNew } from '../../WhatsNew';

export const Routing = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/verify/:token" component={VerifyAccount} />
      <Route exact path="/reset-password/:token" component={SetNewPassword} />
      <Route exact path="/resend-activation" component={ResendActivation} />
      <Route exact path="/hello" component={Hello} />

      <MainApp>
        <Route exact path="/" component={YourDay} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/main-menu" component={MainMenu} />
        <Route exact path="/logout" component={Logout} />
        <Route path="/things" component={Things} />
        <Route path="/calendar" component={Calendar} />
        <Route path="/smart-rating" component={SmartRating} />
        <Route path="/profile" component={Profile} />
        <Route path="/whats-new" component={WhatsNew} />
      </MainApp>
    </Switch>
  </BrowserRouter>
);
