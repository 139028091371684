import { ApiRequest } from './ApiRequest';
import { RefreshTokenHandler, AccessTokenHandler, OrganizationTokenHandler, GuestTokenHandler } from './AuthTokenHandler';
import { accessToken, guest, refreshToken, organizationToken } from './Tokens';
import { OrganizationApiClient } from './Clients/OrganizationApiClient';
import { AuthApiClient } from './Clients/AuthApiClient';
import { AuthTokenApiClient } from './Clients/AuthTokenApiClient';
import { AccessTokenApiClient } from './AccessTokenApiClient';
import { ProjectApiClient } from './Clients/ProjectApiClient';
import { ThingApiClient } from './Clients/ThingApiClient';
import { DailyPlanApiClient } from './Clients/DailyPlanApiClient';
import { AlgorithmApiClient } from './Clients/AlgorithmApiClient';
import { UserApiClient } from './Clients/UserApiClient';
import { UserProjectApiClient } from './Clients/UserProjectApiClient';
import { SupportApiClient } from './Clients/SupportApiClient';
import { DrawerApiClient } from './Clients/DrawerApiClient';
import { SubscriptionApiClient } from './Clients/SubscriptionApiClient';

function makeClient<T>(baseUrl: string, apiClientClass: typeof AccessTokenApiClient): T { 
  return new apiClientClass(new ApiRequest(baseUrl, accessToken, AccessTokenHandler)) as unknown as T;
}

export const AuthClient = new AuthApiClient(
  new ApiRequest('auth', guest, GuestTokenHandler),
  new ApiRequest('auth', refreshToken, RefreshTokenHandler),
  new ApiRequest('auth', accessToken, AccessTokenHandler),
);

export const AuthTokenClient = new AuthTokenApiClient(
  new ApiRequest('auth/token', guest, GuestTokenHandler),
  new ApiRequest('auth/token', organizationToken, OrganizationTokenHandler),
  new ApiRequest('auth/token', refreshToken, RefreshTokenHandler),
  new ApiRequest('auth/token', accessToken, AccessTokenHandler),
);

export const OrganizationClient = new OrganizationApiClient(
  new ApiRequest('organization', refreshToken, RefreshTokenHandler),
  new ApiRequest('organization', organizationToken, OrganizationTokenHandler),
  new ApiRequest('organization', accessToken, AccessTokenHandler),
);

export const AlgorithmClient = makeClient<AlgorithmApiClient>('algorithm', AlgorithmApiClient);
export const DailyPlanClient = makeClient<DailyPlanApiClient>('daily-plan', DailyPlanApiClient);
export const SupportClient = makeClient<SupportApiClient>('feedback', SupportApiClient);
export const ProjectClient = makeClient<ProjectApiClient>('project', ProjectApiClient);
export const ThingClient = makeClient<ThingApiClient>('task', ThingApiClient);
export const UserClient = makeClient<UserApiClient>('user', UserApiClient);
export const UserProjectClient = makeClient<UserProjectApiClient>('user/project', UserProjectApiClient);
export const DrawerClient = makeClient<DrawerApiClient>('drawer', DrawerApiClient);
export const SubscriptionClient = makeClient<SubscriptionApiClient>('subscription', SubscriptionApiClient);
