import React, { useReducer } from 'react';
import { PlanThingsContext, SetPlanThingsContext } from '../Contexts/PlanThingsContext';
import { PlanThingsReducer } from '../Reducers/PlanThingsReducer';

interface Props {
  children: any;
}

export const PlanThingsHoc = ({ children }: Props) => {
  const [planThings, dispatch] = useReducer(PlanThingsReducer, []);

  return (
    <PlanThingsContext.Provider value={SetPlanThingsContext(planThings, dispatch)}>
      {children}
    </PlanThingsContext.Provider>
  )
};
