import React from 'react';
import { AuthContainer } from '../../Common/AuthContainer';
import { H1 } from '../Common/AuthTypography';
import { Icon, P } from '../Common/AuthStyles';
import useI18n from '../../Common/Hooks/useI18n';

export const SetNewPasswordLoading = () => {
  const { t } = useI18n();

  return (
    <AuthContainer>
      <H1><Icon>av_timer</Icon></H1>
      <P>{t('We are verifying the token')}</P>
      <P>{t('Please wait')}...</P>
    </AuthContainer>
  );
}
