import en from './en';
import pl from './pl';

type TranslationReturnType = string | { [key: string]: string }

interface Translations {
  en: { [key: string]: TranslationReturnType };
  pl: { [key: string]: TranslationReturnType };
}

export const allowedLanguages = ['en', 'pl'];

export default {
  en,
  pl,
} as Translations;
