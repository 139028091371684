import { createContext, Dispatch } from 'react';
import { Drawer } from '../../Api/Dto/CommonDto';
import { DrawerReducerAction, DrawerAction } from '../Reducers/DrawerReducer';
import { uuidToColor } from '../Helpers';
import dayjs from 'dayjs';
import { Uuid } from '../../Api/Interface';
import { TranslateFunction } from './I18nContext';

interface DrawerActionsProps {
  add: (drawer: Drawer<Date>) => void;
  update: (drawer: Drawer<Date>) => void;
  remove: (drawer: Drawer<Date>) => void;
  getCodename: (drawer: Drawer<Date>) => string;
  getCodenameById: (id: Uuid) => string | null;
  getCodenameByName: (name: string) => string;
  getColor: (drawer: Drawer<Date>) => string;
  parseCreatedAt: (drawer: Drawer<Date>, t: TranslateFunction) => string;
}

interface DrawerContextProps {
  drawers: Drawer<Date>[];
  drawerActions: DrawerActionsProps;
}

const parseDrawerCodename = (name: string): string => {
  const titleArray = name.split(' ');

  return (
    titleArray.length > 2
    ? `${titleArray[0][0]}${titleArray[1][0]}${titleArray[2][0] || ''}`
      : titleArray.map(title => title[0]).join('')
    ).toUpperCase();
}

const drawerActions = (dispatch: Dispatch<DrawerReducerAction>, drawers: Drawer<Date>[]): DrawerActionsProps => ({
  add: (drawer: Drawer<Date>): void => dispatch({ type: DrawerAction.ADD, value: drawer }),
  update: (drawer) => dispatch({ type: DrawerAction.UPDATE, value: drawer }),
  remove: (drawer: Drawer<Date>): void => dispatch({ type: DrawerAction.REMOVE, value: drawer }),
  getCodename: (drawer: Drawer<Date>): string => {
    return parseDrawerCodename(drawer.name);
  },
  getCodenameById: (id) => {
    const drawer = drawers.find(drawer => drawer.id === id);

    if (drawer === undefined) {
      return null;
    }

    return parseDrawerCodename(drawer.name);
  },
  getCodenameByName: (name) => parseDrawerCodename(name),
  getColor: (drawer: Drawer<Date>): string => uuidToColor(drawer.id),
  parseCreatedAt: (drawer: Drawer<Date>, t: TranslateFunction): string => {
    const days = dayjs().diff(dayjs(drawer.createdAt), 'day');

    const text = days > 0
      ? `${days} ${t((days === 1) ? 'day ago' : 'days ago' )}`
      : t('today');

    return `${t('Created')} ${text}`;
  },
});

export const SetDrawerContext = (drawers: Drawer<Date>[], dispatch: Dispatch<DrawerReducerAction>) => ({
  drawers,
  drawerActions: drawerActions(dispatch, drawers),
});

export const DrawerContext = createContext<DrawerContextProps>({} as unknown as DrawerContextProps);