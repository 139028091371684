import { ApiRequest } from '../ApiRequest';
import { RefreshToken } from '../Dto/AuthDto';
import { MessageResponse } from '../Dto/CommonDto';
import { AxiosPromise } from 'axios';
import { IsoDate } from '../Interface';
import { transformEntity } from '../Helpers';

interface SignUpProps {
  firstName: string; 
  email: string;
  password: string;
  termsConditionsAccepted: boolean;
}

export class AuthApiClient {
  constructor(
    private readonly guestApiRequest: ApiRequest,
    private readonly refreshTokenApiRequest: ApiRequest,
    private readonly accessTokenApiRequest: ApiRequest,
  ) {}

  activateAccount(token: string, firstName: string, lastName: string): Promise<void> {
    return this.guestApiRequest.post<RefreshToken<IsoDate>>('activate', {
      firstName,
      lastName,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(({ data }) => {
        this.refreshTokenApiRequest.tokenContainer.setToken(data.hash);
      });
  }

  changePassword(oldPassword: string, newPassword: string): AxiosPromise<MessageResponse> {
    return this.accessTokenApiRequest.put<MessageResponse>('change-password', {
      oldPassword,
      password: newPassword,
      repeatPassword: newPassword,
    });
  }

  forgotPassword(email: string): AxiosPromise<MessageResponse> {
    return this.guestApiRequest.post<MessageResponse>('forgot-password', { email });
  }

  resendActivationEmail(email: string): AxiosPromise<MessageResponse> {
    return this.guestApiRequest.post<MessageResponse>('resend-activation', { email });
  }

  resetPassword(token: string, password: string): AxiosPromise<MessageResponse> {
    return this.guestApiRequest.post<MessageResponse>('reset-password', {
      password,
      repeatPassword: password,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  signIn(email: string, password: string): Promise<RefreshToken<Date>> {
    return this.guestApiRequest.post<RefreshToken<IsoDate>>('sign-in', {
      email,
      password
    })
      .then(({ data }) => {
        this.refreshTokenApiRequest.tokenContainer.setToken(data.hash);

        return transformEntity<RefreshToken<Date>>(data);
      });
  }

  signUp(data: SignUpProps): Promise<RefreshToken<Date>> {
    return this.guestApiRequest
      .post<RefreshToken<IsoDate>>('sign-up', data)
      .then(({ data }) => {
        this.refreshTokenApiRequest.tokenContainer.setToken(data.hash);

        return transformEntity<RefreshToken<Date>>(data);
      });
  }
}
