import React, { useEffect, useContext, useState } from 'react';
import { DailyPlan, DailyPlanStatus, Thing } from '../../Api/Dto/CommonDto';
import { ThingStatus } from "../../Api/Dto/ThingDto";
import { ResponsiveList } from '../../Common/Hoc/ResponsiveList';
import { ThingClient, DailyPlanClient } from '../../Api';
import { CalendarThingsContext } from '../Contexts/CalendarThingsContext';
import { ProgressButton } from '../../Common/ProgressButton';
import { MonthlyPlansContext } from '../Contexts/MonthlyPlansContext';
import { TemplateContext } from '../../Common/Contexts/TemplateContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AxiosError } from 'axios';
import glamorous from 'glamorous';
import { CalendarOverviewContext } from '../Contexts/CalendarOverviewContext';
import { Button } from '../../Common/Forms/Buttons';
import { Color } from '../../Common/Styles';
import { CompletedPlanThings } from './CompletedPlanThings';
import { PreparedPlanThings } from './PreparedPlanThings';
import { PlanThingsContext } from '../Contexts/PlanThingsContext';
import { Uuid } from '../../Api/Interface';
import { toMonthRaw } from '../helpers';
import { ShowMobileThingsContext } from '../Contexts/ShowMobileThingsContext';
import { ExistingPlanWithoutThing } from './ExistingPlanWithoutThing';
import { AddThingsTutorial } from '../common/AddThingsTutorial';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  plan: DailyPlan<Date>;
}

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  transitionProperty: 'transform, opacity',
  transitionDuration: '.15s',
});

const _ExistingPlan = ({ plan, history }: Props & RouteComponentProps) => {
  const { t } = useI18n();
  const { calendarThingsActions } = useContext(CalendarThingsContext);
  const { planThings, planThingsActions } = useContext(PlanThingsContext);
  const { setShowMobileThings } = useContext(ShowMobileThingsContext);
  const { monthlyPlansActions } = useContext(MonthlyPlansContext);
  const { templateActions } = useContext(TemplateContext);
  const { isExpanded, calendarOverviewActions } = useContext(CalendarOverviewContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);

  const calculateCanUpdate = (): boolean => {
    if (plan.things.length !== planThings.length) {
      return true;
    }

    const originalThingsIds: Uuid[] = plan.things.map(thing => thing.id).sort();
    const newThingsIds: Uuid[] = planThings.map(thing => thing.id).sort();

    return originalThingsIds.join('') === newThingsIds.join('');
  };

  const resetPlanThings = () => {
    planThingsActions.setThings(
      plan.things.map(thing => calendarThingsActions.correctIfOutdatedStatus(thing))
    );
  }

  useEffect(() => {
    !isExpanded && resetPlanThings();
  }, [plan.id]);

  useEffect(() => {
    isExpanded && setCanUpdate(calculateCanUpdate());
  }, [planThings]);

  useEffect(() => {
    if (isExpanded === false) {
      setCanUpdate(false);
    }
  }, [isExpanded]);

  const dispatchStatus = (thing: Thing<Date>, status: ThingStatus) => planThingsActions.update({
    ...thing,
    status,
  });

  const toggleStatus = (thing: Thing<Date>) => {
    const status: ThingStatus = thing.status === ThingStatus.TO_DO ? ThingStatus.DONE : ThingStatus.TO_DO;

    dispatchStatus(thing, status);
    setIsLoading(true);

    ThingClient
      .update(thing, { status })
      .then(data => {
        setIsLoading(false); 

        setTimeout(() => {
          calendarThingsActions.upsert(data);
          monthlyPlansActions.updateThings(plan, plan.things.map(planThing => (
            planThing.id === data.id
              ? data
              : planThing
          )));
        }, 200);
      })
      .catch(() => {
        setIsLoading(false);
        dispatchStatus(thing, thing.status);
      });
  }

  const updatePlan = () => {
    setIsLoading(true);

    monthlyPlansActions.updateThings(plan, planThings);

    DailyPlanClient
      .updateThings(plan, planThings)
      .then(data => {
        monthlyPlansActions.updateThings(plan, data.things);
        calendarOverviewActions.decrease();
        setShowMobileThings !== undefined && setShowMobileThings(false);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  const finishPlan = () => {
    setIsLoading(true);

    DailyPlanClient
    .complete(plan)
    .then(() => {
      templateActions.hide();

      setTimeout(() => {
        history.push(`/smart-rating/${plan.deadline.year}-${toMonthRaw(plan.deadline.month)}-${plan.deadline.day}`);
      }, 500);
    })
    .catch((error: AxiosError) => {
      setIsLoading(false);

      if (error.config === undefined) {
        console.log(error.message);
      }
    })
  }

  return (
    <Container>
      {!isExpanded && planThings.length === 0 && <ExistingPlanWithoutThing />}
      {planThings.length > 0 && (
        <ResponsiveList>
          {plan.status === DailyPlanStatus.COMPLETED
            ? <CompletedPlanThings things={planThings} />
            : <PreparedPlanThings
              things={planThings}
              toggleStatus={toggleStatus}
              isEditMode={isExpanded}
              canRemove={isExpanded && !isLoading}
            />
          }
        </ResponsiveList>
      )}
      {isExpanded && planThings.length === 0 && <AddThingsTutorial />}
      {isExpanded && (
        <Button
          isActive={canUpdate}
          color={Color.appleGreen}
          onClick={() => canUpdate && updatePlan()}
        >
          {t('Update')}
        </Button>
      )}
      {!isExpanded && plan.status !== DailyPlanStatus.COMPLETED && planThings.length > 0 && (
        <ProgressButton
          percentage={monthlyPlansActions.calculatePercentage({ things: planThings } as DailyPlan<Date>)}
          onClick={() => plan.status !== DailyPlanStatus.COMPLETED && finishPlan()}
          styles={{ marginTop: '10px' }}
        >
          {t('Finish plan')}
        </ProgressButton>
      )}
    </Container>
  );
}

export const ExistingPlan = withRouter(_ExistingPlan);
