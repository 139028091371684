import React, { useState, FormEvent } from 'react';
import { AuthContainer } from '../../Common/AuthContainer';
import { AuthForm } from '../Common/AuthForm';
import { Input } from '../../Common/Forms/Input';
import { handleEntityChange } from '../../Common/FormsHelper';
import { Small } from '../Common/AuthTypography';
import { AuthSubmit } from '../Common/AuthSubmit';
import { AuthClient } from '../../Api';
import { handleErrors } from '../../Common/Forms/FormHandler';
import { ConfirmEmail, EmailType } from '../Common/AuthConfirmEmail';
import useI18n from '../../Common/Hooks/useI18n';

interface EntityProps {
  email: string | null;
}

export const ForgotPassword: React.FC = () => {
  const { t } = useI18n();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [entity, setEntity] = useState<EntityProps>({
    email: null,
  });
  const [errors, 
    setErrors] = useState({
    email: [],
  });

  const handleChange = (event: FormEvent) => !isLoading && handleEntityChange(event, setEntity, entity);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading || entity.email === null) {
      return;
    }

    AuthClient.forgotPassword(entity.email)
      .then(() => {
        setIsLoading(false);
        setIsFormSubmitted(true);
      })
      .catch(err => {
        setIsLoading(false);
        setErrors(handleErrors(errors, err));
      })
  }

  if (isFormSubmitted) {
    return <ConfirmEmail email={entity.email || ''} type={EmailType.FORGOT_PASSWORD} />
  }

  return (
    <AuthContainer isLoading={isLoading}>
      <AuthForm method="post" onSubmit={handleSubmit}>
        <Input
          name="email"
          type="email"
          placeholder="Your e-mail address"
          value={entity.email || ''}
          errors={errors.email}
          onChange={handleChange}
        />
        <Small>
          {t('Enter your email address that you used to register')}.<br/>
          {t("We'll send you an email with a link to reset your password")}.
        </Small>
        <AuthSubmit
          buttonText="Reset password"
          alterHref="/login"
          alterText="Back to Log in"
        />
      </AuthForm>
    </AuthContainer>
  );
}