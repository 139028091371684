import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { DayElement } from '../interfaces';
import { Color, Transition } from '../../Common/Styles';
import { CalendarContext } from '../Contexts/CalendarContext';
import { AppStatus } from '../../Common/AppStatus';
import { DailyPlan, DailyPlanStatus } from '../../Api/Dto/CommonDto';
import { GridDayPlan } from './GridDayPlan';
import { Today } from './Today';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  day: DayElement;
  plan: DailyPlan<Date> | undefined;
}

interface ContainerProps {
  isActive: boolean;
  isSelected: boolean;
  isContentVisible: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    flex: 1,
    borderRadius: '2px',
    margin: '1px',
    position: 'relative',
    border: `1px solid ${Color.transparent}`,
    zIndex: 1,
    ...Transition([
      ['border-color', '.3s'],
      ['opacity', '.3'],
    ]),

    ':hover': {
      filter: 'drop-shadow(0 8pt 26pt rgba(0,0,0,.16))',
      zIndex: 2,
    }
  },
  ({ isActive, isSelected, isContentVisible }) => ({
    cursor: isActive ? 'pointer' : 'default',
    backgroundColor: isActive ? Color.white : Color.transparent,
    borderColor: isActive 
    ? isSelected
      ? Color.iris
      : Color.white
    : Color.transparent,

    '& > div': {
      opacity: isContentVisible ? 1 : 0,
    }
  }),
);

const DayNumber = glamorous.span({
  position: 'absolute',
  color: Color.dark74,
  fontSize: '14px',
  top: '10px',
  left: '10px',
});

export const GridDay = ({ day, plan }: Props) => {
  const { t } = useI18n();
  const { calendarActions, currentDate, calendarStatus } = useContext(CalendarContext)

  return (
    <Container
      isActive={day.isActive}
      isSelected={calendarStatus !== AppStatus.LOADING && day.isActive && currentDate.day === day.dayNumber}
      isContentVisible={calendarStatus === AppStatus.READY}
      onClick={() => day.isActive && calendarActions.selectDay(day.dayNumber)}
    >
      <DayNumber>{day.dayNumber}</DayNumber>
      { plan !== undefined 
        && plan.status !== DailyPlanStatus.DELETED 
        && day.isActive 
        && <GridDayPlan plan={plan} />
      }
      {day.isActive && calendarActions.isToday({ ...currentDate, day: day.dayNumber }) && <Today>{t('today')}</Today>}
    </Container>
  )
};
