import React from 'react';
import { ThingContainer } from '../../Common/Thing/ThingContainer';
import { ThingTitle } from '../../Common/Thing/ThingTitle';
import { Thing } from '../../Api/Dto/CommonDto';
import { ThingAvatar } from '../../Common/Thing/ThingAvatar';
import { ThingTheme } from '../../Common/Thing/common';
import { ThingCheckbox } from '../../Common/Thing/ThingCheckbox';
import { ThingStatus } from '../../Api/Dto/ThingDto';
import { ThingCompletedStatusIcon } from '../../Common/Thing/ThingCompletedStatusIcon';

interface Props {
  thing: Thing<Date>;
  theme: ThingTheme;
  onCheck: () => void;
  isPlanCompleted: boolean;
}

export const ThingElement = ({ thing, theme, onCheck, isPlanCompleted }: Props) => (
  <ThingContainer theme={theme}>
    {isPlanCompleted && <ThingAvatar align="left" thing={thing} />}
    {!isPlanCompleted && <ThingCheckbox isChecked={thing.status === ThingStatus.DONE || thing.status === ThingStatus.CLOSED} onClick={onCheck} />}
    <ThingTitle title={thing.title} />
    {!isPlanCompleted && <ThingAvatar align="right" thing={thing} />}
    {isPlanCompleted && <ThingCompletedStatusIcon align="right" status={thing.status} />}
  </ThingContainer>
);
