import glamorous from 'glamorous';
import { Color, Font } from '../../Common/Styles';

export const Today = glamorous.div({
  position: 'absolute',
  top: '4px',
  right: '4px',
  borderRadius: '2px',
  backgroundColor: Color.iris,
  padding: '2px',
  fontFamily: Font.condensed,
  color: Color.white,
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: 1.29,
});
