import React from 'react';
import glamorous from 'glamorous';
import { Color, Font, Unselectable, MediaQuery } from '../../Common/Styles';
import { Link } from 'react-router-dom';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  buttonText?: string;
  alterText?: string;
  alterHref?: string;
  style?: object;
}

const Container = glamorous.div(
  {
    marginTop: '25px',
  }
)

const AlterUrl = glamorous(Link)(
  {
    float: 'right',
    color: Color.white,
    textDecoration: 'underline',
    fontFamily: Font.normal,
    fontSize: '16px',
    fontWeight: 500,
    padding: '11px',

    ':hover': {
      textDecoration: 'none',
    },

    [MediaQuery.tinyMobile]: {
      // @TODO: In mobile view, text looks really weird. Need to be fixed.
      width: '100%',
    }
  }
);

const SubmitButton = glamorous.button(
  {
    float: 'right',
    padding: '11px 30px',
    borderRadius: '4px',
    border: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    cursor: 'pointer',
    backgroundColor: Color.iris,
    color: Color.white,
    transitionProperty: 'background-color, color',
    transitionDuration: '.3s',
    fontFamily: Font.normal,
    ...Unselectable,

    ':hover': {
      backgroundColor: Color.white,
      color: Color.iris,
    },

    [MediaQuery.tinyMobile]: {
      width: '100%',
    }
  }
);

export const AuthSubmit = ({
  buttonText = 'Submit',
  alterText,
  alterHref,
}: Props) => {
  const { t } = useI18n();

  return (
    <Container>
      <SubmitButton type="submit">{t(buttonText)}</SubmitButton>
      { (alterText && alterHref) && <AlterUrl to={alterHref}>{t(alterText)}</AlterUrl> }
    </Container>
  );
}
