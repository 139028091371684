import React from 'react';
import glamorous from 'glamorous';
import { DotLoader } from '../Common/Loaders/DotLoader';
import { Color } from '../Common/Styles';
import useI18n from '../Common/Hooks/useI18n';

const Text = glamorous.span({
  color: Color.dark54,
  fontSize: '14px',
  width: '100%',
});

export const SmartRatingLoading = () => {
  const { t } = useI18n();
  
  return (
    <>
      <DotLoader />
      <Text>{t("Hold on... We're calculating your plan")}</Text>
    </>
  );
}
