import React, { useContext, useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { CalendarContext } from '../Contexts/CalendarContext';
import { MonthlyPlansContext } from '../Contexts/MonthlyPlansContext';
import { DayElement } from '../interfaces';
import { prepareGridFromDate } from '../Helpers/CalendarDaysHelper';
import { ResponsiveList } from '../../Common/Hoc/ResponsiveList';
import { CalendarHeader } from '../CalendarHeader';
import { MobileDay } from './MobileDay';
import { Font, Color } from '../../Common/Styles';
import useI18n from '../../Common/Hooks/useI18n';

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const Week = glamorous.div({
  padding: '5px 15px',
});

const WeekNumber = glamorous.span({
  display: 'block',
  fontFamily: Font.condensed,
  fontSize: '14px',
  color: Color.darkGreyBlue,
  marginBottom: '10px',
  textTransform: 'lowercase',
});

export const ListCalendar = () => {
  const { t } = useI18n();
  const { currentDate } = useContext(CalendarContext);
  const { monthlyPlans } = useContext(MonthlyPlansContext);

  const [grid, setGrid] = useState<DayElement[][] | undefined>(undefined);

  useEffect(() => {
    setGrid(prepareGridFromDate(currentDate));
  }, [currentDate.year, currentDate.month]);

  return (
    <Container>
      <CalendarHeader asMobile />
      <ResponsiveList>
        {grid !== undefined && grid.map((week, weekIndex) => (
          <Week key={weekIndex}>
            <WeekNumber>{t('Week')} #{weekIndex + 1}</WeekNumber>
            {week.filter(day => day.isActive === true).map((day, dayIndex) => (
              <MobileDay key={dayIndex} day={day} plan={monthlyPlans[day.dayNumber]} />
            ))}
          </Week>
        ))}
      </ResponsiveList>
    </Container>
  )
};
