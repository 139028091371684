import React, { useContext, useState } from 'react';
import glamorous from 'glamorous';
import { MediaQuery, Color } from '../../Styles';
import { MenuItemProps } from '../../../Navigation/Interfaces';
import { MobileMenuItem } from './MobileMenuItem';
import { ToggleContext } from '../../Contexts/ToggleContext';
import { UserContext } from '../../Contexts/UserContext';
import { Config } from '../../../Config';
import useI18n from '../../Hooks/useI18n';

interface NavigationMenu extends MenuItemProps {
  onToggle?: () => void;
}

const Container = glamorous.div({
  width: '100%',
  display: 'flex',
  height: '45px',
  backgroundColor: Color.darkGreyBlue,
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: 0,
  left: 0,

  [MediaQuery.tableDesktop]: {
    display: 'none',
  },
});

export const MobileMenu = () => {
  const { t } = useI18n();
  const { toggleActions } = useContext(ToggleContext);
  const { lastSeenVersion } = useContext(UserContext);

  const [navigationMenu] = useState<NavigationMenu[]>([
    {
      name: 'Your day',
      icon: 'class',
      url: '/',
      isExact: true,
    },
    {
      name: 'Things',
      icon: 'playlist_add_check',
      url: '/things',
      isExact: true,
      onToggle: toggleActions.toggleThings,
    },
    {
      name: 'Calendar',
      icon: 'today',
      url: '/calendar',
      isExact: false,
    },
    {
      name: 'Support',
      icon: 'question_answer',
      url: '/support',
      isExact: true,
    },
  ]);

  return (
    <Container>
      {navigationMenu.map((menuItem, key) => <MobileMenuItem
        key={key}
        icon={menuItem.icon}
        name={t(menuItem.name)}
        url={menuItem.url}
        isExact={menuItem.isExact}
      />)}
      {lastSeenVersion !== Config.CURRENT_VERSION
        ? (
          <MobileMenuItem
            icon="rss_feed"
            name={t("What's new?")}
            url="/whats-new"
            isHighlighted
            isExact={true}
          />
        )
        : (
          <MobileMenuItem
            icon="menu"
            name={t('Main Menu')}
            url="/main-menu"
            isExact={true}
          />
        )
      }
    </Container>
  )
};

