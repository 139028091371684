import { Ymd, YmdStrict } from '../interfaces';
import { createContext, Dispatch } from 'react';
import dayjs from 'dayjs';
import { History } from 'history';
import { AppStatus } from '../../Common/AppStatus';
import { toMonthRaw } from '../helpers';

interface CalendarActionsProps {
  setNextMonth: () => void;
  setPreviousMonth: () => void;
  setStatus: (status: AppStatus) => void;
  selectDay: (day: number) => void;
  isToday: (date: YmdStrict) => boolean;
  isCurrentDate: (date: Ymd) => boolean;
  getCurrentDay: () => number;
  getCurrentDate: () => YmdStrict;
}

interface CalendarContextProps {
  today: YmdStrict;
  currentDate: Ymd;
  calendarStatus: AppStatus;
  calendarActions: CalendarActionsProps;
}

const calendarActions = (
  history: History<any>,
  today: YmdStrict,
  currentDate: Ymd,
  setCalendarStatus: Dispatch<AppStatus>,
): CalendarActionsProps => ({
  setNextMonth: () => {
    const { year, month } = currentDate;

    const newDate = dayjs(new Date(year, month, 1)).add(1, 'month').toDate();

    history.push(`/calendar/${newDate.getFullYear()}/${toMonthRaw(newDate.getMonth())}`);
  },
  setPreviousMonth: () => {
    const { year, month } = currentDate;

    if (year < 2020 && month < 9) {
      return;
    }

    const newDate = dayjs(new Date(year, month, 1)).subtract(1, 'month').toDate();

    history.push(`/calendar/${newDate.getFullYear()}/${toMonthRaw(newDate.getMonth())}`);
  },
  setStatus: (status) => setCalendarStatus(status),
  selectDay: (day) => {
    const { year, month } = currentDate;

    const daysInMonth = dayjs(new Date(year, month, 1)).daysInMonth();

    if (day > 0 && day <= daysInMonth) {
      history.push(`/calendar/${year}/${toMonthRaw(month)}/${day}`);
    }
  },
  isToday: ({ year, month, day }) => (
    day === today.day
    && month === today.month
    && year === today.year
  ),
  isCurrentDate: (date) => currentDate.year === date.year && currentDate.month === date.month,
  getCurrentDay: () => Number(currentDate.day) || 1,
  getCurrentDate: () => ({ ...currentDate, day: currentDate.day || 1 }),
});

export const SetCalendarContext = (
  history: History<any>,
  currentDate: Ymd,
  today: YmdStrict,
  [calendarStatus, setCalendarStatus]: [AppStatus, Dispatch<AppStatus>],
): CalendarContextProps => ({
  today,
  currentDate,
  calendarStatus,
  calendarActions: calendarActions(history, today, currentDate, setCalendarStatus),
});

export const CalendarContext = createContext<CalendarContextProps>({} as unknown as CalendarContextProps);
