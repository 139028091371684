import React, { useState, useEffect, useContext } from 'react';
import glamorous from 'glamorous';
import { CalendarContext } from '../Contexts/CalendarContext';
import { DayElement } from '../interfaces';
import { GridDay } from './GridDay';
import { CALENDAR_DAYS } from '../common';
import { Color, Font, Unselectable } from '../../Common/Styles';
import { AppStatus } from '../../Common/AppStatus';
import { MonthlyPlansContext } from '../Contexts/MonthlyPlansContext';
import { prepareGridFromDate } from '../Helpers/CalendarDaysHelper';
import useI18n from '../../Common/Hooks/useI18n';

interface ContainerProps {
  isLoading: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 8px 8px',
    transition: 'opacity, .3s',
  },
  ({ isLoading }) => ({
    opacity: isLoading ? 0.3 : 1,
  }),
);

const Header = glamorous.div({
  display: 'flex',
});

const HeaderElement = glamorous.div({
  flex: 1,
  padding: '8px 0',
  color: Color.dark,
  fontSize: '14px',
  fontFamily: Font.condensed,
  ...Unselectable,
})

const Week = glamorous.div({
  display: 'flex',
  flex: 1,
  position: 'relative',
});

export const GridCalendar = () => {
  const { t } = useI18n();
  const { currentDate, calendarStatus } = useContext(CalendarContext);
  const { monthlyPlans } = useContext(MonthlyPlansContext);
  const [grid, setGrid] = useState<DayElement[][] | undefined>(undefined);

  useEffect(() => {
    setGrid(prepareGridFromDate(currentDate));
  }, [currentDate]);

  return (
    <Container isLoading={calendarStatus === AppStatus.LOADING}>
      <Header>
        {CALENDAR_DAYS.map((day, index) => <HeaderElement key={index}>{t(day.full)}</HeaderElement>)}
      </Header>
      {grid !== undefined && grid.map((week, weekIndex) => (
        <Week key={weekIndex}>
          {week.map((day, dayIndex) => (
            <GridDay key={dayIndex} day={day} plan={monthlyPlans[day.dayNumber]} />
          ))}
        </Week>
      ))}
    </Container>
  )
};
