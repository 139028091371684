import React, { ReactNode, useEffect, useState, useReducer } from 'react';
import { UserProjectClient } from '../../Api';
import { User } from '../../Api/Dto/CommonDto';
import { DrawerReducer, DrawerAction } from '../Reducers/DrawerReducer';
import { UserContext, SetUserContext } from '../Contexts/UserContext';
import { DrawerContext, SetDrawerContext } from '../Contexts/DrawerContext';
import { TemplateContext, SetTemplateContext, ModalTemplate } from '../Contexts/TemplateContext';
import { AxiosError } from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DotLoader } from '../Loaders/DotLoader';
import glamorous from 'glamorous';
import { getUserLastSeenVersion, updateUserLastSeenVersion } from '../LocalStorage';

interface Props {
  children: ReactNode;
}

const NotReadyContainer = glamorous.div({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const _MainApp = ({ children, history }: Props & RouteComponentProps) => {
  const [drawers, dispatch] = useReducer(DrawerReducer, []);

  const [isAppReady, setIsAppReady] = useState<boolean>(false);
  const [user, setUser] = useState<User<Date>>(undefined as unknown as User<Date>);
  const [lastSeenVersion, setLastSeenVersion] = useState<string>('');
  const [modal, setModal] = useState<ModalTemplate>(ModalTemplate.HIDDEN);

  const [isTemplateHidden, setIsTemplateHidden] = useState<boolean>(false);

  const fetchUserAndProjects = () => UserProjectClient
    .getUserAndProjects()
    .then((data) => {
      setUser(data.user);
      dispatch({ type: DrawerAction.SET, values: data.projects });
    })
    .catch((error: AxiosError) => {
      error.response
        && error.response.status === 403
        && history.push('/login');
    });

  const initApp = async () => {
    await fetchUserAndProjects();
  };

  const parseVersion = (userEntity: User<Date>): string => (
    getUserLastSeenVersion(userEntity) || updateUserLastSeenVersion(userEntity)
  );

  useEffect(() => {
    initApp();
  }, []);

  useEffect(() => {
    user !== undefined 
    && lastSeenVersion.length === 0 
    && setLastSeenVersion(parseVersion(user));

    user !== undefined && lastSeenVersion.length !== 0 && setIsAppReady(true);
  }, [user, lastSeenVersion]);

  if (!isAppReady) {
    return (
      <NotReadyContainer>
        <DotLoader />
      </NotReadyContainer>
    );
  }

  return (
    <UserContext.Provider value={SetUserContext([user, setUser], [lastSeenVersion, setLastSeenVersion])}>
      <DrawerContext.Provider value={SetDrawerContext(drawers, dispatch)}>
        <TemplateContext.Provider value={SetTemplateContext(
          [isTemplateHidden, setIsTemplateHidden],
          [modal, setModal],
        )}>
          {children}
        </TemplateContext.Provider>
      </DrawerContext.Provider>
    </UserContext.Provider>
  );
}

export const MainApp = withRouter(_MainApp);