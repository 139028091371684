import glamorous from 'glamorous';
import { MediaQuery } from '../../Common/Styles';

export const AuthForm = glamorous.form(
  {
    margin: '0 auto',

    [MediaQuery.tableDesktop]: {
      maxWidth: '350px',
    },
  }
);
