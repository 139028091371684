import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { Color, MediaQuery } from '../../Common/Styles';
import { UserContext } from '../../Common/Contexts/UserContext';
import { H3, Small } from '../../Common/Typography';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  isVisible: boolean;
}

const Container = glamorous.div<Props>(
  {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    borderLeft: `1px solid ${Color.silver}`,
    padding: '15px 30px',
  },
  ({ isVisible }) => ({
    [MediaQuery.mobileOnly]: {
      display: isVisible ? 'block' : 'none',
      width: '100%',
    },
  }),
);

const Description = glamorous(Small)({
  marginTop: '25px',
  maxWidth: '300px',
  lineHeight: 1,
  fontSize: '16px',
});

export const PlanOverviewHints = ({ isVisible }: Props) => {
  const { t } = useI18n();
  const { user } = useContext(UserContext);

  return (
    <Container isVisible={isVisible}>
      <H3>{t('No hints yet')}</H3>
      <Description>
        {user.firstName},<br />
        {t("during your day, you'll see here some useful hints that will help you be more effective.")}
      </Description>
    </Container>
  )
};
