import React, { ReactNode, forwardRef, Ref } from 'react';
import glamorous from 'glamorous';
import { ScrollbarHidden } from '../Styles';

interface Props {
  children: ReactNode;
  styles?: any;
  asFlex?: boolean;
}

interface OverflowContainerProps {
  asFlex: boolean;
}

const Container = glamorous.div({
  flex: '1',
  display: 'flex',
  minHeight: 0,
  textAlign: 'left',
});

const OverflowContainer = glamorous.div<OverflowContainerProps>(
  {
    overflowY: 'scroll',
    overflowX: 'hidden',
    transform: 'translate3d(0, 0, 0)',
    width: '100%',
    ...ScrollbarHidden,
  },
  ({ asFlex }) => {
    return asFlex
      ? { flex: 1 }
      : {}
  }
);

const OverflowContent = glamorous.div({
  height: '1px',
});

const _ResponsiveList = ({ children, styles = {}, asFlex = false, }: Props, ref: Ref<HTMLDivElement | null>) => (
  <Container>
    <OverflowContainer asFlex={asFlex} css={styles} innerRef={ref as unknown as undefined}>
      <OverflowContent>
        {children}
      </OverflowContent>
    </OverflowContainer>
  </Container>
);

export const ResponsiveList = forwardRef(_ResponsiveList);
