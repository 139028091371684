import React from 'react';
import glamorous from 'glamorous';
import { ThingTheme } from './common';
import { Color } from '../Styles';

interface Props {
  theme?: ThingTheme;
  title: string;
}

interface ContainerProps {
  theme: ThingTheme;
}

const Container = glamorous.div<ContainerProps>(
  {
    fontSize: '16px',
    marginTop: '3px',
    lineHeight: '24px',
    letterSpacing: '-0.3px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flex: '1',
  },
  ({ theme }) => ({
    color: theme === ThingTheme.EDIT_MODE ? Color.dark74 : Color.darkGreyBlue,
    fontWeight: theme === ThingTheme.EDIT_MODE ? 400 : 500,
  }),
);

export const ThingTitle = ({ theme = ThingTheme.DEFAULT, title }: Props) => (
  // @ts-ignore
  <Container theme={theme}>{title}</Container>
)