import React, { useRef, useEffect, useState, FormEvent } from 'react';
import { Thing } from '../Api/Dto/CommonDto';
import glamorous, { Button } from 'glamorous';
import { Color, Font } from '../Common/Styles';
import useI18n from '../Common/Hooks/useI18n';

interface Props {
  title: string;
  setTitle: (event: FormEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

const Container = glamorous.form({
  padding: '11px 13px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

const Icon = glamorous.i({
  color: Color.dark44,
  fontSize: '16px',
  border: `1px solid ${Color.silver}`,
  borderRadius: '50%',
  padding: '8px',
  marginRight: '10px',
});

const TitleInput = glamorous.input({
  flex: 1,
  border: 'none',
  backgroundColor: Color.transparent,
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: Font.normal,
  borderBottom: `1px dotted ${Color.dark44}`,
  color: Color.dark74,
  margin: 0,
  paddingBottom: '3px',
  transform: `translate3d(0, 1px, 0)`,
});

const HiddenSubmit = glamorous(Button)({
  visibility: 'hidden',
  position: 'absolute',
});

export const ThingEditForm = ({ title, setTitle, onSubmit }: Props) => {
  const { t } = useI18n();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref !== null && ref.current !== null && ref.current.focus();
  }, [ref]);

  return (
    <Container onSubmit={event => {
      event.preventDefault();
      onSubmit();
    }}>
      <Icon className="material-icons">edit</Icon>
      <TitleInput innerRef={ref} value={title} onChange={setTitle} />
      <HiddenSubmit type="submit">{t('Submit')}</HiddenSubmit>
    </Container>
  )
};
