import React, { useContext } from 'react';
import { Uuid } from '../../Api/Interface';
import { AvatarSize, AvatarTemplate } from '../Interface';
import { UserContext } from '../Contexts/UserContext';
import { DrawerContext } from '../Contexts/DrawerContext';
import { Avatar } from '../Avatar';
import { uuidToColor } from '../Helpers';
import { UserDrawerID } from '../../Things/common';

interface Props {
  drawerId: Uuid;
  size?: AvatarSize;
  sideMargin?: number;
  isActive?: boolean;
  onClick: () => void;
}

export const DrawerAvatar = ({
  drawerId,
  size = AvatarSize.TINY,
  sideMargin = 0,
  isActive = true,
  onClick,
}: Props) => {
  const { user, userActions } = useContext(UserContext);
  const { drawerActions } = useContext(DrawerContext);

  return (
    <Avatar
      css={{ margin: `0 ${sideMargin}px` }}
      size={size}
      color={uuidToColor(drawerId === UserDrawerID ? user.id : drawerId)}
      text={drawerId === UserDrawerID ? userActions.getInitals() : (drawerActions.getCodenameById(drawerId) || '')}
      template={isActive ? AvatarTemplate.DEFAULT : AvatarTemplate.INACTIVE}
      canClick
      onClick={onClick}
    />
  );
};
