import React, { FormEvent } from 'react';
import glamorous from 'glamorous';
import { Color, Placeholder, Unselectable, Font, MediaQuery } from '../Styles';
import useI18n from '../Hooks/useI18n';

interface Props {
  value: string | null;
  placeholder?: string;
  buttonIcon?: string | null;
  color?: string | null;
  buttonText?: string;
  canSubmit?: boolean;
  hideOnMobile?: boolean;
  css?: any;
  onSubmit: (event: FormEvent) => void;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
}

interface SubmitButtonProps {
  canSubmit: boolean;
}

interface FormProps {
  hideOnMobile: boolean;
}

interface IconProps {
  color: string;
  canSubmit: boolean;
}

const Form = glamorous.form<FormProps>(
  {
    background: Color.white,
    padding: '11px 16px',
    display: 'flex',
    borderRadius: '4px',
  },
  ({ hideOnMobile }) => ({
    [MediaQuery.mobileOnly]: {
      display: hideOnMobile ? 'none' : 'flex',
    },
  }),
);

const Input = glamorous.input({
  background: 'transparent',
  border: 'none',
  color: Color.darkGreyBlue,
  fontSize: '14px',
  letterSpacing: '-.3px',
  outline: 'none',
  ...Placeholder({
    color: Color.dark44,
  }),
  flex: '1 1 auto',
});

const SubmitButton = glamorous.button<SubmitButtonProps>(
  {
    fontSize: '14px',
    fontFamily: Font.normal,
    padding: 0,
    textTransform: 'uppercase',
    fontWeight: 600,
    marginLeft: '16px',
    flex: '0 0 auto',
    transition: 'color, .3s',
    ...Unselectable,
    border: 'none',
    background: 'transparent',
  },
  ({ canSubmit }) => ({
    cursor: canSubmit ? 'pointer' : 'default',
    color: canSubmit ? Color.iris : Color.silver,
  })
);

const Icon = glamorous.i<IconProps>(
  {
    fontSize: '21px',
    flex: '0 0 auto',
    transition: 'color, .3s',
    ...Unselectable,
  },
  ({ color, canSubmit }) => ({
    cursor: canSubmit ? 'pointer' : 'default',
    color: canSubmit ? color : Color.silver,
  }),
);

export const TextSubmit = ({
  value,
  placeholder = '',
  buttonIcon = null,
  color = null,
  buttonText = '',
  onSubmit,
  onChange,
  canSubmit = true,
  hideOnMobile = false,
  css = {},
}: Props) => {
  const { t } = useI18n();
  
  return (
    <Form method="post" hideOnMobile={hideOnMobile} css={css} onSubmit={(event) => {
      event.preventDefault();
      canSubmit && onSubmit(event);
    }}>
      <Input
        type="text"
        onChange={onChange}
        value={value || ''}
        placeholder={t(placeholder)}
      />
      <SubmitButton type="submit" canSubmit={canSubmit}>
        {buttonIcon !== null && (
          <Icon
            className="material-icons"
            canSubmit={canSubmit}
            onClick={() => canSubmit && onSubmit({ preventDefault: () => {} } as FormEvent)}
            color={color || Color.iris}
          >
            {buttonIcon}
          </Icon>
        )}
        {t(buttonText)}
      </SubmitButton>
    </Form>
  );
}
