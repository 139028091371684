import React, { FormEvent } from 'react';
import { Thing } from '../Api/Dto/CommonDto';
import { ThingContainer } from '../Common/Thing/ThingContainer';
import { ThingTitle } from '../Common/Thing/ThingTitle';
import { ThingNumber } from '../Common/Thing/ThingNumber';
import { ThingTheme } from '../Common/Thing/common';
import { ThingEditForm } from './ThingEditForm';

interface Props {
  thing: Thing<Date>;
  isSelected: boolean;
  isEditing: boolean;
  editThingTitle: string;
  onChangeTitle: (event: FormEvent<HTMLInputElement>) => void;
  onSubmitUpdateTitle: () => void;
  onClick: () => void;
}

export const ThingElement = ({ thing, isSelected, isEditing, editThingTitle, onChangeTitle, onSubmitUpdateTitle, onClick }: Props) => (
  <ThingContainer 
    theme={isSelected 
      ? isEditing
        ? ThingTheme.EDIT_MODE 
        : ThingTheme.DEFAULT_SELECTED 
      : ThingTheme.DEFAULT
    }
    onClick={() => { !isEditing && onClick() }}
  >
    { thing.number !== null && !isEditing &&  <ThingNumber number={thing.number || 0} /> }
    {!isEditing && <ThingTitle title={thing.title} />}
    {isEditing && <ThingEditForm title={editThingTitle} onSubmit={onSubmitUpdateTitle} setTitle={onChangeTitle} />}
  </ThingContainer>
);
