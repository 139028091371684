import React, { useContext, useEffect, useState } from 'react';
import { DailyPlan } from '../../Api/Dto/CommonDto';
import { ThingStatus } from "../../Api/Dto/ThingDto";
import glamorous from 'glamorous';
import { hexToRgba, Font, Color, ScrollbarHidden } from '../../Common/Styles';
import { UserContext } from '../../Common/Contexts/UserContext';
import { uuidToColor } from '../../Common/Helpers';
import { GridDayPlanActiveNoThings } from './GridDayPlanActiveNoThings';

interface Props {
  plan: DailyPlan<Date>;
}

interface ThingElementProps {
  backgroundColor: string,
  isVisible: boolean,
}

const Container = glamorous.div({
  width: '100%',
  height: '100%',
  position: 'relative',
});

const ListContainer = glamorous.div({
  position: 'absolute',
  width: 'calc(100% - 20px)',
  height: 'calc(100% - 40px)',
  marginLeft: '10px',
  top: '30px',
  left: 0,
  overflowY: 'scroll',
  ...ScrollbarHidden,
});

const ThingElement = glamorous.div<ThingElementProps>(
  {
    color: Color.darkGreyBlue,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    fontFamily: Font.condensed,
    padding: '3px 5px',
    marginBottom: '3px',
    borderRadius: '4px',
  },
  ({ backgroundColor, isVisible }) => ({
    backgroundColor,
    display: isVisible ? 'block' : 'none',
  }),
);

export const GridDayPlanActive = ({ plan }: Props) => {
  const { user } = useContext(UserContext);

  const [allThingsDone, setAllThingsDone] = useState<boolean>(false);

  useEffect(() => {
    setAllThingsDone(
      plan.things.filter(thing => thing.status === ThingStatus.TO_DO).length === 0
    );
  }, [plan]);

  return (
    <Container>
      <ListContainer>
        {!allThingsDone && plan.things.map(thing => (
          <ThingElement 
            key={thing.id}
            backgroundColor={hexToRgba(uuidToColor(thing.projectId || user.id), 0.5)}
            isVisible={thing.status === ThingStatus.TO_DO}
          >
            {thing.title}
          </ThingElement>
        ))}
      </ListContainer>
      {allThingsDone && <GridDayPlanActiveNoThings />}
    </Container>
  )
};
