import React from 'react';
import glamorous from 'glamorous';
import { keyframes } from 'glamor';
import { Color } from '../Styles';

const LOADER_SPEED = 0.45;

const Container = glamorous.div({
  // display: 'inline-block', // TODO: Need to verify is it needed
  position: 'relative',
  width: '64px',
  height: '64px',
  margin: 'auto',

  '& > div': {
    position: 'absolute',
    top: '27px',
    width: '11px',
    height: '11px',
    borderRadius: '50%',
    background: Color.cloudyBlue,
    animationTimingFunction: 'linear',
  }
});

const ellipsis1 = keyframes({
  '0%': { transform: 'scale(0)' },
  '100%': { transform: 'scale(1)' },
});

const ellipsis2 = keyframes({
  '0%': { transform: 'translate(0, 0)' },
  '100%': { transform: 'translate(19px, 0)' },
});

const ellipsis3 = keyframes({
  '0%': { transform: 'scale(1)' },
  '100%': { transform: 'scale(0)' },
});

const Child1 = glamorous.div({
  left: '6px',
  animation: `${ellipsis1} ${LOADER_SPEED}s infinite`,
});

const Child2 = glamorous.div({
  left: '6px',
  animation: `${ellipsis2} ${LOADER_SPEED}s infinite`,
});

const Child3 = glamorous.div({
  left: '26px',
  animation: `${ellipsis2} ${LOADER_SPEED}s infinite`,
});

const Child4 = glamorous.div({
  left: '45px',
  animation: `${ellipsis3} ${LOADER_SPEED}s infinite`,
});

export const DotLoader = () => (
  <Container>
    <Child1 />
    <Child2 />
    <Child3 />
    <Child4 />
  </Container>
);