import React from 'react';
import { ListCalendar } from './common/ListCalendar';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';
import { PageLayoutTheme } from '../Common/Interface';
import { PageLayout } from '../Common/PageLayout';
import { CalendarMobileOverview } from './CalendarMobileOverview';
import { BlankTheme } from '../Common/Themes/BlankTheme';

interface Props {
  isDayView: boolean;
}

export const CalendarMobile = ({ isDayView }: Props) => {
  return isDayView
    ? (
      <BlankTheme>
        <PageLayout theme={PageLayoutTheme.NO_PADDING} scrollable={false} showMobileMenu={false}>
          <CalendarMobileOverview />
        </PageLayout>
      </BlankTheme>
    )
    : (
      <DefaultTheme>
        <PageLayout theme={PageLayoutTheme.NO_PADDING} scrollable={false}>
          <ListCalendar />
        </PageLayout>
      </DefaultTheme>
    )
};
