import glamorous from 'glamorous';
import { Color, Font } from '../Styles';

interface ThingHintTextProps {
  align: 'left' | 'right';
}

export const ThingHintText = glamorous.small<ThingHintTextProps>(
  {
    color: Color.dark74,
    fontFamily: Font.condensed,
    fontSize: '12px',
  },
  ({ align }) => ({
    margin: align === 'left' ? '0 10px 0 0' : '0 0 0 10px',
  }),
);
