import React, { useContext, useState, useEffect } from 'react';
import { Drawer as DrawerEntity, Thing } from '../Api/Dto/CommonDto';
import glamorous from 'glamorous';
import { DrawerTitle } from './DrawerTitle';
import { DrawerTopTools } from './DrawerTopTools';
import { ThingsCounter } from './ThingsCounter';
import { ThingsAppMode } from './interfaces';
import { ScrollbarHidden, MediaQuery, Transition } from '../Common/Styles';
import { ThingsContext } from './Contexts/ThingsContext';
import { ThingElement } from './ThingElement';
import { ThingsList } from '../Common/Thing/ThingsList';
import { SelectedThingsContext, SetSelectedThingsContext } from './Contexts/SelectedThingsContext';
import { Uuid } from '../Api/Interface';
import { ToggleContext, ToggleThingsState } from '../Common/Contexts/ToggleContext';
import { ThingsStatusContext } from './Contexts/ThingsStatusContext';
import { AppStatus } from '../Common/AppStatus';
import { DotLoader } from '../Common/Loaders/DotLoader';
import { ThingClient } from '../Api';
import { useAppStatus } from '../Common/Hooks/useAppStatus';

interface Props {
  drawer: DrawerEntity<Date>;
  omitMargin?: boolean;
  toolsAlignment?: 'top' | 'bottom';
  placeForDrawerList?: boolean;
};

interface ContainerProps {
  omitMargin: boolean;
  placeForDrawerList: boolean;
  isLoading: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    ...ScrollbarHidden,
    ...Transition(['opacity', .3]),

    [MediaQuery.tableDesktop]: {
      width: '280px',
      minWidth: '280px',
      marginRight: '30px',
    }
  },
  ({ omitMargin, placeForDrawerList, isLoading }) => ({
    opacity: isLoading ? 0.3 : 1,
    marginRight: omitMargin ? '15px' : '30px',
    height: placeForDrawerList ? 'calc(100% - 40px)' : '100%',
    width: '100%',
  }),
);

export const Drawer = ({ drawer, omitMargin = false, toolsAlignment = 'top', placeForDrawerList = false }: Props) => {
  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);

  const { things, thingsActions } = useContext(ThingsContext);
  const { toggleThingsState } = useContext(ToggleContext);
  const { thingsStatus } = useContext(ThingsStatusContext);

  const [selectedThings, setSelectedThings] = useState<Uuid[]>([]);
  const [editThingId, setEditThingId] = useState<string | null>(null);
  const [editThingTitle, setEditThingTitle] = useState<string>('');

  useEffect(() => {
    setSelectedThings([]);
  }, [drawer.id]);

  useEffect(() => {
    if (editThingId !== null && selectedThings.length !== 1) {
      setEditThingId(null);
      setEditThingTitle('');
    }
  }, [selectedThings]);

  const toggleSelection = (thingId: string) => {
    selectedThings.find(selectedThingId => selectedThingId === thingId) !== undefined
      ? setSelectedThings(selectedThings.filter(selectedThingId => selectedThingId !== thingId))
      : setSelectedThings([...selectedThings, thingId]);
  }

  const turnOnEditMode = () => {
    if (selectedThings.length === 1) {
      const thingToUpdate = things[drawer.id].find(thing => thing.id === selectedThings[0]);

      if (thingToUpdate === undefined) {
        return;
      }

      setEditThingId(thingToUpdate.id);
      setEditThingTitle(thingToUpdate.title);
    }
  }

  const turnOffEditMode = () => {
    setEditThingId(null);
    setSelectedThings([]);
  }

  const updateThing = () => {
    const thingToUpdate = things[drawer.id].find(thing => thing.id === String(editThingId));

    if (thingToUpdate === undefined) {
      return;
    }

    setAppStatus(AppStatus.LOADING);

    ThingClient
      .update(thingToUpdate, { title: editThingTitle })
      .then(data => {
        thingsActions.update(drawer, data);
        turnOffEditMode();
        setAppStatus(AppStatus.READY);
      })
      .catch(() => setAppStatus(AppStatus.READY));
  }

  return (
    <Container omitMargin={omitMargin} placeForDrawerList={placeForDrawerList} isLoading={appStatus === AppStatus.LOADING}>
      <SelectedThingsContext.Provider value={SetSelectedThingsContext([selectedThings, setSelectedThings])}>
        <DrawerTitle drawer={drawer} />
        {toolsAlignment === 'top' && <DrawerTopTools 
          align={toolsAlignment}
          drawer={drawer}
          showTools={selectedThings.length > 0}
          isSingleSelection={selectedThings.length === 1}
          isEditMode={editThingId !== null}
          turnOnEditMode={turnOnEditMode}
          turnOffEditMode={turnOffEditMode}
          onSubmitUpdate={() => updateThing()}
        />}
        {thingsStatus !== AppStatus.LOADING && (
          <>
            <ThingsCounter 
              thingsAppMode={selectedThings.length > 0 ? ThingsAppMode.EDIT : ThingsAppMode.VIEW}
              thingsSelectedQnt={selectedThings.length}
              drawer={drawer}
            />
            <ThingsList>
              {(things[drawer.id] || []).map(thing => <ThingElement 
                key={thing.id}
                thing={thing}
                isSelected={selectedThings.find(selectedThingId => selectedThingId === thing.id) !== undefined}
                isEditing={thing.id === String(editThingId)}
                onClick={() => {toggleSelection(thing.id)}}
                editThingTitle={editThingTitle}
                onChangeTitle={(event) => setEditThingTitle(event.currentTarget.value)}
                onSubmitUpdateTitle={() => updateThing()}
              />)}
            </ThingsList>
          </>
        )}
        {thingsStatus === AppStatus.LOADING && (
          <DotLoader />
        )}
        {toolsAlignment === 'bottom' && toggleThingsState === ToggleThingsState.ADD_NEW_THING && <DrawerTopTools
          align={toolsAlignment}
          drawer={drawer}
          showTools={selectedThings.length > 0}
          isSingleSelection={selectedThings.length === 1}
          isEditMode={editThingId !== null}
          turnOnEditMode={turnOnEditMode}
          turnOffEditMode={turnOffEditMode}
          onSubmitUpdate={() => updateThing()}
        />}
      </SelectedThingsContext.Provider>
    </Container>
  )
}