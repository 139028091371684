import React, { ReactNode } from 'react';
import glamorous from 'glamorous';
import { Color, BoxShadow } from '../Common/Styles';
import { Link } from 'react-router-dom';
import useI18n from '../Common/Hooks/useI18n';

interface Props {
  icon?: string;
  text: string;
  color: string;
  url: string;
  avatar?: ReactNode | null;
}

interface ContainerProps {
  color: string;
}

const Container = glamorous(Link)<ContainerProps>(
  {
    width: '100%',
    boxShadow: BoxShadow.veryLight,
    backgroundColor: Color.white,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    padding: '10px',
    borderRadius: '4px',
    textDecoration: 'none',
  },
  ({ color }) => ({
    '& > i': {
      color,
    }
  }),
);

const Icon = glamorous.i({
  fontSize: '21px',
});

const Text = glamorous.span({
  flex: 1,
  color: Color.darkGreyBlue,
  marginLeft: '10px',
  fontSize: '16px',
  fontWeight: 500,
});

export const MainMenuItem = ({ icon, text, color, url, avatar = null }: Props) => {
  const { t } = useI18n();

  return (
    <Container to={url} color={color}>
      {avatar === null && <Icon className="material-icons">{icon}</Icon>}
      {avatar}
      <Text>{t(text)}</Text>
    </Container>
  );
}
