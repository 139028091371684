import React from 'react';
import glamorous from 'glamorous';
import { AvatarSize, AvatarTemplate } from './Interface';
import { Color, Font, Unselectable, Transition } from './Styles';

interface Props {
  color: string;
  text?: string;
  size?: AvatarSize;
  css?: any;
  template?: AvatarTemplate;
  canClick?: boolean;
  onClick?: () => void;
}

interface TextProps {
  size: number;
  color: string;
}

interface ElementProps {
  size: number;
  backgroundColor: string;
  template: AvatarTemplate;
  canClick: boolean;
}

const calculateFontSize = (size: number): string => {
  if (size <= AvatarSize.MICRO) {
    return `${12}px`;
  }

  if (size <= AvatarSize.TINY) {
    return `${14}px`;
  }

  if (size <= AvatarSize.DEFAULT) {
    return `${16}px`;
  }

  return `${24}px`;
}

const Element = glamorous.div<ElementProps>(
  {
    borderRadius: '50%',
    ...Transition([
      ['opacity', .3],
      ['transform', .15],
      ['background-color', .3],
      ['border-color', .3],
    ])
  },
  ({ size, backgroundColor, template, canClick }) => ({
    backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: `${size}px`,
    height: `${size}px`,
    fontSize: calculateFontSize(size),
    cursor: canClick ? 'pointer' : 'normal',
    opacity: template === AvatarTemplate.DEFAULT ? 1 : 0.7,
    transform: `scale(${template === AvatarTemplate.DEFAULT ? 1 : 0.8})`,
    border: `1px solid ${backgroundColor === Color.transparent ? Color.dark44 : Color.transparent}`,
  }),
);

const Text = glamorous.span<TextProps>(
  {
    fontFamily: Font.condensed,
    textAlign: 'center',
    width: '100%',
    display: 'block',
    ...Transition(['color', .3]),
    ...Unselectable,
  },
  ({ size, color }) => ({
    fontWeight: size >= AvatarSize.BIG ? 400 : 500,
    color,
  })
);

export const Avatar = ({ 
  size = AvatarSize.DEFAULT, 
  color, 
  text = '',
  template = AvatarTemplate.DEFAULT,
  css = {},
  canClick = false,
  onClick = () => {},
}: Props) => (
  <Element
    className="avatar"
    backgroundColor={color}
    size={size}
    onClick={onClick}
    css={css}
    template={template}
    canClick={canClick}
  >
    { text.length > 0 && <Text color={color === Color.transparent ? Color.dark74 : Color.white} size={size}>{text}</Text> }
  </Element>
);
