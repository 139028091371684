import React from 'react';
import { ThingStatus } from "../../Api/Dto/ThingDto";
import { ReactComponent as SvgDone } from '../../assets/svg/plan-status/done.svg'
import { ReactComponent as SvgFailed } from '../../assets/svg/plan-status/failed.svg'
import glamorous from 'glamorous';
import { Color } from '../Styles';

interface Props {
  status: ThingStatus
  align?: 'left' | 'right';
}

interface ContainerProps {
  align: 'left' | 'right';
  color: string;
}

const Container = glamorous.div<ContainerProps>(
  {},
  ({ align, color }) => ({
    margin: align === 'left' ? '0 10px 0 0' : '0 0 0 10px',

    '& > svg path': {
      fill: color,
    },
  }),
)

export const ThingCompletedStatusIcon = ({ status, align = 'right' }: Props) => {
  const getIcon = () => {
    switch (status) {
      case ThingStatus.DONE:
      case ThingStatus.CLOSED:
        return <SvgDone />;
      case ThingStatus.TO_DO:
      default:
        return <SvgFailed />;
    }
  }

  return (
    <Container align={align} color={(status === ThingStatus.DONE || status === ThingStatus.CLOSED) ? Color.appleGreen : Color.cherryRed}>
      {getIcon()}
    </Container>
  )
};
