import React, { FormEvent } from 'react';
import glamorous from 'glamorous';
import { Color, Unselectable } from '../Styles';

interface Props {
  isChecked: boolean;
  onClick: (event: FormEvent) => void;
}

interface CSSProps {
  isChecked: boolean;
}

const Container = glamorous.div<CSSProps>(
  {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    marginRight: '12px',
    boxShadow: '0 0 1px #8d8d9c, 0 0 3px hsla(240,7%,58%,.5)',
    ...Unselectable,
    transition: 'background-color .3s ease-in-out',
  },
  ({ isChecked }) => ({
    backgroundColor: isChecked ? Color.appleGreen : Color.white,

    '&:hover > span': {
      color: isChecked ? Color.white : Color.cloudyBlue,
    },
  })
);

const CheckboxIcon = glamorous.span<CSSProps>({
  color: Color.white,
});

export const ThingCheckbox = ({ isChecked, onClick }: Props) => (
  <Container isChecked={isChecked} onClick={onClick}>
    <CheckboxIcon isChecked={isChecked} className="material-icons">done</CheckboxIcon>
  </Container>
);
