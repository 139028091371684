import React from 'react';
import glamorous from 'glamorous';
import { FormEvent } from 'react';
import { BoxShadow, Color, Unselectable, Size, hexToRgba, Transition, Font } from '../Styles';
import useI18n from '../Hooks/useI18n';

interface Props {
  text?: string;
  isActive?: boolean;
  color?: string;
  outlined?: boolean;
  tick?: boolean;
  onClick?: (event: FormEvent) => any;
}

interface ButtonProps {
  isActive: boolean;
  color: string;
  size?: Size;
  outlined?: boolean;
  tick?: boolean;
}

const applyButtonSize = (size: Size) => {
  switch (size) {
    case Size.small:
      return {
        fontSize: '14px',
        padding: '5px 15px',
      };
    case Size.medium:
    default:
      return {
        fontSize: '16px',
        padding: '11px 30px',
      };
  }
}

export const Button = glamorous.button<ButtonProps>(
  {
    boxShadow: BoxShadow.lightTransparent,
    borderRadius: '4px',
    border: 'none',
    fontFamily: Font.normal,
    ...Unselectable,
    ...Transition([
      ['background-color', '.15s'],
      ['color', '.15s'],
      ['box-shadow', '.3s'],
      ['transform', '.15s'],
    ]),
  },
  ({ isActive, color, size = Size.medium, outlined = false, tick = false }) => ({
    ':hover': {
      boxShadow: isActive
        ? outlined
          ? BoxShadow.none
          : BoxShadow.light
        : BoxShadow.none,
      backgroundColor: outlined 
        ? Color.transparent
        : isActive
          ? hexToRgba(color, 0.80)
          : Color.disabled,
      color: outlined 
        ? isActive
          ? hexToRgba(color, 0.70)
          : Color.disabled
        : Color.white,
    },

    ':active': {
      transform: isActive ? 'scale(0.95)' : 'scale(1)',
    },

    fontWeight: tick ? 400 : 600,
    cursor: isActive ? 'pointer' : 'normal',
    color: outlined 
      ? isActive 
        ? color
        : Color.disabled
      : Color.white,
    backgroundColor: outlined 
      ? Color.transparent
      : isActive
        ? color
        : Color.disabled,
    ...applyButtonSize(size),
  }),
);

export const SubmitButton = ({ 
  text = 'Submit',
  isActive = true,
  color = Color.iris,
  outlined = false,
  tick = false,
  onClick = () => {}
}: Props) => {
  const { t } = useI18n();

  return (
    <Button
      onClick={(event: FormEvent) => isActive ? onClick(event) : {}}
      color={color}
      isActive={isActive}
      disabled={!isActive}
      outlined={outlined}
      tick={tick}
    >
      {t(text)}
    </Button>
  );
}