import glamorous from 'glamorous';
import { Color, Font } from './Styles';

export const Card = glamorous.div({
  backgroundColor: Color.white,
  padding: '15px',
  marginBottom: '30px',
  borderRadius: '4px',
});

export const CardTitle = glamorous.h3({
  color: Color.twilight,
  fontSize: '24px',
  letterSpacing: '-0.5px',
  fontWeight: 400,
  padding: 0,
  margin: '5px 0 15px',
});

export const CardSmall = glamorous.small({
  display: 'block',
  fontSize: '14px',
  fontFamily: Font.condensed,
  letterSpacing: '-.3px',
  fontWeight: 500,
  margin: '15px 0',
  color: Color.dark66,
});
