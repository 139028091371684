import React from 'react';
import { Drawer as DrawerEntity } from '../Api/Dto/CommonDto';
import glamorous from 'glamorous';
import { ScrollbarHidden } from '../Common/Styles';
import { Drawer } from './Drawer';
import { CreateDrawer } from './Common/CreateDrawer';

interface Props {
  drawers: DrawerEntity<Date>[];
}

const OverflowContainer = glamorous.div({
  display: 'flex',
  overflowX: 'scroll',
  overflowY: 'hidden',
  height: '100%',
  ...ScrollbarHidden,
});

export const ThingsDesktop = ({ drawers }: Props) => (
  <OverflowContainer>
    {drawers.map(drawer => <Drawer key={drawer.id} drawer={drawer} />)}
    <CreateDrawer />
  </OverflowContainer>
);
