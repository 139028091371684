import React from 'react';
import glamorous from 'glamorous';
import { Color } from '../Styles';

interface Props {
  errors: string[];
}

const InputErrorsContainer = glamorous.ul({
  paddingLeft: '20px',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 400,
});

const InputErrorsElement = glamorous.li({
  color: Color.cherryRed,
  textAlign: 'left',
  fontWeight: 600,
  fontSize: '12px',
});

export const FormErrors = ({ errors = [] }: Props) => (
  <InputErrorsContainer>
    {errors.map((error, key) => <InputErrorsElement key={key}>{error}</InputErrorsElement>)}
  </InputErrorsContainer>
);