import React, { useContext, useEffect } from 'react';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';
import { PageLayout } from '../Common/PageLayout';
import { V110 } from './versions/V1-1-0';
import { H1 } from '../Common/Typography';
import { UserContext } from '../Common/Contexts/UserContext';
import { Config } from '../Config';
import useI18n from '../Common/Hooks/useI18n';

export const WhatsNew = () => {
  const { t } = useI18n();
  const { lastSeenVersion, userActions, user } = useContext(UserContext);

  useEffect(() => {
    lastSeenVersion !== Config.CURRENT_VERSION && userActions.updateLastSeenVersion(user);
  }, []);

  return (
    <DefaultTheme>
      <PageLayout>
        <H1>{t("What's new?")}</H1>
        <V110 />
      </PageLayout>
    </DefaultTheme>
  )
};
