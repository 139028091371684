import { createContext, Dispatch, SetStateAction } from 'react';

type ThingsState = [ToggleThingsState, Dispatch<SetStateAction<ToggleThingsState>>];

export enum ToggleThingsState {
  ADD_NEW_THING = 'ADD_NEW_THING',
  SELECT_DRAWER = 'SELECT_DRAWER',
}

interface ToggleActionProps {
  toggleThings: () => void;
}

interface ToggleContextProps {
  toggleThingsState: ToggleThingsState;
  toggleActions: ToggleActionProps;
}

const toggleActions = (
  [thingsState, setThingsState]: ThingsState,
): ToggleActionProps => ({
  toggleThings: () => setThingsState(thingsState === ToggleThingsState.ADD_NEW_THING
    ? ToggleThingsState.SELECT_DRAWER
    : ToggleThingsState.ADD_NEW_THING
  ),
});

export const SetToggleContext = (thingsState: ThingsState): ToggleContextProps => ({
  toggleThingsState: thingsState[0],
  toggleActions: toggleActions(thingsState),
});

export const ToggleContext = createContext<ToggleContextProps>({} as unknown as ToggleContextProps);
