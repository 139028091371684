import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { ModalContent, ModalTitle, ModalFooter } from './styles';
import { UserContext } from '../Contexts/UserContext';
import { Color } from '../Styles';
import { ButtonsGroup } from '../Forms/ButtonsGroup';
import { Button } from '../Forms/Buttons';
import { TemplateContext, ModalTemplate } from '../Contexts/TemplateContext';
import useI18n from '../Hooks/useI18n';

const Content = glamorous(ModalContent)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  color: Color.dark,
});

const Icon = glamorous.i({
  margin: '15px auto',
  fontSize: '64px',
  color: Color.appleGreen,
});

const Welcome = glamorous.p({
  fontSize: '16px',
  fontWeight: 500,
  margin: '15px',
});

const Introduction = glamorous.p({
  fontSize: '14px',
  padding: '15px 15px 30px 15px',
  margin: '0 0 15px 0',
  borderBottom: `1px solid ${Color.silver}`,
});

const SubscriptionDetails = glamorous.div({
  padding: '15px',
});

const SubscriptionTitle = glamorous.p({
  fontSize: '14px',
  margin: '0 0 5px 0',
});

const SubscriptionEstimate = glamorous.p({
  fontSize: '14px',
  margin: 0,
  fontWeight: 500,
});

export const ProTrialSuccessModal = () => {
  const { t } = useI18n();
  const { user } = useContext(UserContext);
  const { templateActions } = useContext(TemplateContext);

  return (
    <>
      <ModalTitle>{t('Welcome to the Tauplan PRO')}</ModalTitle>
      <Content>
        <Icon className="material-icons">done</Icon>
        <Welcome>{t('Glad to have you onboard')}, {user.firstName}!</Welcome>
        <Introduction>
          {t('With Tauplan PRO you can do as much as you want. We work on other features as well.')}
        </Introduction>
        <SubscriptionDetails>
          <SubscriptionTitle>{t('Your trial subscription is active until')}</SubscriptionTitle>
          <SubscriptionEstimate>1 {t('July', 'month-of')} 2020</SubscriptionEstimate>
        </SubscriptionDetails>
      </Content>
      <ModalFooter>
        <ButtonsGroup align="center">
          <Button
            isActive={true}
            color={Color.appleGreen}
            onClick={() => templateActions.setModal(ModalTemplate.HIDDEN)}
          >
            {t("Let's Tau!")}
          </Button>
        </ButtonsGroup>
      </ModalFooter>
    </>
  );
}
