import { createContext, Dispatch, SetStateAction } from 'react';

type IsTemplateHiddenState = [boolean, Dispatch<SetStateAction<boolean>>];
type ModalTemplateState = [ModalTemplate, Dispatch<SetStateAction<ModalTemplate>>];

export enum ModalTemplate {
  HIDDEN = 'HIDDEN',
  THINGS_EXCEEDED = 'THINGS_EXCEEDED',
  DRAWERS_EXCEEDED = 'DRAWERS_EXCEEDED',
  WELCOME_PRO = 'WELCOME_PRO',
}

interface TemplateActionsProps {
  hide: () => void;
  show: () => void;
  setModal: (template: ModalTemplate) => void;
}

interface TemplateContextProps {
  isTemplateHidden: boolean;
  modalTemplate: ModalTemplate;
  templateActions: TemplateActionsProps;
}

const templateActions = (
  [isTemplateHidden, setIsTemplateHidden]: IsTemplateHiddenState,
  [modal, setModal]: ModalTemplateState,
): TemplateActionsProps => ({
  hide: () => isTemplateHidden === false && setIsTemplateHidden(true),
  show: () => isTemplateHidden === true && setIsTemplateHidden(false),
  setModal: (template) => modal !== template && setModal(template),
});

export const SetTemplateContext = (
  isTemplateHiddenState: IsTemplateHiddenState,
  modalTemplateState: ModalTemplateState,
): TemplateContextProps => ({
  modalTemplate: modalTemplateState[0],
  isTemplateHidden: isTemplateHiddenState[0],
  templateActions: templateActions(isTemplateHiddenState, modalTemplateState),
});

export const TemplateContext = createContext<TemplateContextProps>({} as unknown as TemplateContextProps);
