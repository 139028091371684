import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { MessageResponse } from '../Dto/CommonDto';
import { AxiosPromise } from 'axios';

export class SupportApiClient extends AccessTokenApiClient {
  create(message: string): AxiosPromise<MessageResponse> {
    return this.apiRequest.post<MessageResponse>('', {
      type: 'general', // TODO: Just for now as we don't need more specified contact
      message 
    });
  }
}
