import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { UserContext } from '../../Common/Contexts/UserContext';
import { Link } from 'react-router-dom';
import { Color, BoxShadow, Transition, MediaQuery } from '../../Common/Styles';
import { toMonthRaw } from '../../Calendar/helpers';
import dayjs from 'dayjs';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  isTomorrow: boolean;
}

const Container = glamorous.div({
  display: 'flex',
  margin: '0 auto',
  maxWidth: '500px',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',

  [MediaQuery.mobileOnly]: {
    flex: 1,
  }
});

const Icon = glamorous.span({
  color: Color.cherryRed,
  fontSize: '68px',
  margin: '15px 0',
});

const Text = glamorous.span({
  color: Color.dark,
  fontSize: '18px',
  margin: '0 0 10px 0',
  lineHeight: 1,

  [MediaQuery.mobileOnly]: {
    fontSize: '16px',
  }
});

const GoToCalendar = glamorous(Link)({
  margin: '30px auto 15px',
  textAlign: 'center',
  fontWeight: 500,
  fontSize: '16px',
  padding: '11px 28px',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: Color.iris,
  color: Color.white,
  textDecoration: 'none',
  boxShadow: BoxShadow.light,
  ...Transition([
    ['color', .3],
    ['background-color', .3],
    ['opacity', .3],
    ['transform', .15],
  ]),

  ':hover': {
    opacity: 0.7,
  },
  
  ':active': {
    transform: `scale(0.97)`,
  },
});

export const TodayPlanEmpty = ({ isTomorrow }: Props) => {
  const { t } = useI18n();
  const { user } = useContext(UserContext);

  const getCurrentDate = (): string => {
    const currentDate: Date = isTomorrow
      ? dayjs().add(1, 'day').toDate()
      : new Date()

    return `${currentDate.getFullYear()}/${toMonthRaw(currentDate.getMonth())}/${currentDate.getDate()}`;
  } 

  return (
    <Container>
      <Icon className="material-icons">today</Icon>
      <Text>{user.firstName}, {t("you don't have any plans")}.</Text>
      <Text>{t("It's a great time to plan something and make things done")}.</Text>
      <GoToCalendar to={`/calendar/${getCurrentDate()}`}>{t('Go to Calendar')}</GoToCalendar>
    </Container>
  )
};
