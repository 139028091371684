import React, { useContext, useEffect, useState } from 'react';
import glamorous from 'glamorous';
import { CalendarOverviewHeader } from './CalendarOverview/CalendarOverviewHeader';
import { CalendarThingsHoc } from './Hoc/CalendarThingsHoc';
import { CalendarMobileOverviewContent } from './CalendarMobileOverviewContent';
import { Link } from 'react-router-dom';
import { CalendarContext } from './Contexts/CalendarContext';
import { toMonthRaw } from './helpers';
import { Color } from '../Common/Styles';
import { CalendarOverviewContext, SetCalendarOverviewContext } from './Contexts/CalendarOverviewContext';
import { CalendarMobileNavigation } from './CalendarMobileNavigation';
import { AppStatus } from '../Common/AppStatus';
import { MonthlyPlansContext } from './Contexts/MonthlyPlansContext';
import { ShowMobileThingsContext, SetShowMobileThingsContext } from './Contexts/ShowMobileThingsContext';

const Container = glamorous.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const CloseButton = glamorous(Link)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  color: Color.white,
  textDecoration: 'none',


  '& > i': {
  },
});

export const CalendarMobileOverview = () => {
  const { currentDate, calendarStatus } = useContext(CalendarContext);
  const { monthlyPlans } = useContext(MonthlyPlansContext);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showMobileThings, setShowMobileThings] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, []);

  return (
    <Container>
      <CalendarOverviewContext.Provider value={SetCalendarOverviewContext([isExpanded, setIsExpanded])}>
        <CalendarThingsHoc>
          <ShowMobileThingsContext.Provider value={SetShowMobileThingsContext([showMobileThings, setShowMobileThings])}>
            <CalendarOverviewHeader />
            <CalendarMobileOverviewContent />
            <CloseButton to={`/calendar/${currentDate.year}/${toMonthRaw(currentDate.month)}`}>
              <i className="material-icons">close</i>
            </CloseButton>
            {calendarStatus !== AppStatus.LOADING && <CalendarMobileNavigation plan={monthlyPlans[currentDate.day || 0]} />}
          </ShowMobileThingsContext.Provider>
        </CalendarThingsHoc>
      </CalendarOverviewContext.Provider>
    </Container> 
  )
};
