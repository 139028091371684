import glamorous from 'glamorous';
import { Color, Font } from '../../Common/Styles';

export const H1 = glamorous.h1(
  {
    margin: '24px 0 30px 0',
    color: Color.white,
    fontFamily: Font.normal,
    fontSize: '56px',
    fontWeight: 400,
    letterSpacing: '-.02em',
    padding: 0,
    lineHeight: 1.2,
    textAlign: 'center',
  }
);

export const Small = glamorous.small(
  {
    fontFamily: Font.condensed,
    fontWeight: 400,
    fontSize: '16px',
    color: Color.silver,
    lineHeight: '24px',
    opacity: 0.9,
  }
);
