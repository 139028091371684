import React, { useState, useEffect, useContext } from 'react';
import glamorous from 'glamorous';
import { isReadyProps } from '../interface';
import { DynamicButton } from '../../Common/DynamicButton';
import { Color } from '../../Common/Styles';
import { SmartRatingTransitionDuration, SmartRatingTransitionDelay } from '../constant';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TemplateContext } from '../../Common/Contexts/TemplateContext';

interface Props {
  hideView: () => void;
}

const Container = glamorous.div<isReadyProps>(
  {
    margin: '30px auto',
    transitionProperty: 'opacity, transform',
    transitionDuration: `${SmartRatingTransitionDuration.BackButton}ms`,
    transitionDelay: `${SmartRatingTransitionDelay.BackButton}ms`,
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
    transform: `translate3d(0, ${isReady ? '0' : '30px'}, 0)`,
  }),
);

const _GoHomeButton = ({ hideView, history }: Props & RouteComponentProps) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const { templateActions } = useContext(TemplateContext)

  useEffect(() => {
    setIsReady(true);
  }, []);

  const redirectToHome = () => {
    hideView();
    templateActions.hide();

    setTimeout(() => history.push('/'), 500);
  }

  return (
    <Container isReady={isReady}>
      <DynamicButton
        text="Plan your next day"
        color={Color.dark}
        onClick={redirectToHome}
        fullWidth={false}
      />
    </Container>
  )
};

export const GoHomeButton = withRouter(_GoHomeButton);
