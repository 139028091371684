import React, { useContext, useState, useEffect, FormEvent } from 'react';
import { DrawerContext } from '../Common/Contexts/DrawerContext';
import glamorous from 'glamorous';
import { Drawer } from '../Api/Dto/CommonDto';
import { Avatar } from '../Common/Avatar';
import { AvatarSize } from '../Common/Interface';
import { Color, Font } from '../Common/Styles';
import { UserDrawerID } from './common';
import { UserContext } from '../Common/Contexts/UserContext';
import { TextSubmit } from '../Common/Forms/TextSubmit';
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import { AppStatus } from '../Common/AppStatus';
import { ProjectClient } from '../Api';
import useI18n from '../Common/Hooks/useI18n';

interface Props {
  drawer: Drawer<Date>;
}

const Container = glamorous.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

const DrawerAvatar = glamorous.div({

});

const DrawerText = glamorous.div({
  padding: '10px 0 10px 10px',
});

const Title = glamorous.span({
  display: 'block',
  letterSpacing: '-0.3px',
  color: Color.darkGreyBlue,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 1.15,
  textTransform: 'capitalize',
});

const Description = glamorous.span({
  display: 'block',
  letterSpacing: '-0.3px',
  fontFamily: Font.condensed,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 1.29,
  color: Color.dark74
});

const UpdateIcon = glamorous.span({
  cursor: 'pointer',
  position: 'absolute',
  fontSize: '24px',
  top: 0,
  right: 0,
  color: Color.dark66,
});

const EditDrawerForm = glamorous.div({
  flex: 1,
});

export const DrawerTitle = ({ drawer }: Props) => {
  const { t } = useI18n();
  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);

  const { drawerActions } = useContext(DrawerContext);
  const { userActions } = useContext(UserContext);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [newDrawerName, setNewDrawerName] = useState<string>(drawer.name);

  useEffect(() => {
    isEditMode === true && setIsEditMode(false);
    setNewDrawerName(drawer.name);
  }, [drawer.id]);

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();

    setAppStatus(AppStatus.LOADING);

    ProjectClient
      .update(drawer, newDrawerName)
      .then(data => {
        drawerActions.update(data);
        setNewDrawerName(data.name);
        setIsEditMode(false);
        setAppStatus(AppStatus.READY);
      })
      .catch(() => {
        setIsEditMode(false);
        setAppStatus(AppStatus.READY);
      });
  }

  const cancel = () => {
    setNewDrawerName(drawer.name);
    setIsEditMode(false);
  }

  return (
    <Container>
      <DrawerAvatar>
        <Avatar
          size={AvatarSize.BIG}
          color={isEditMode 
            ? Color.transparent
            : drawer.id === UserDrawerID 
              ? userActions.getColor()
              : drawerActions.getColor(drawer)
          }
          text={drawerActions.getCodenameByName(newDrawerName)}
        />
      </DrawerAvatar>
      {!isEditMode && (
        <DrawerText>
          <Title>{drawer.name}</Title>
          <Description>{drawerActions.parseCreatedAt(drawer, t)}</Description>
        </DrawerText>
      )}
      {isEditMode && (
        <EditDrawerForm>
          <Description css={{ paddingLeft: '12px' }}>{t("Edit a drawer's name")}</Description>
          <TextSubmit
            value={newDrawerName}
            onSubmit={handleOnSubmit}
            canSubmit={appStatus !== AppStatus.LOADING}
            buttonIcon="done"
            color={Color.appleGreen}
            onChange={({ currentTarget }) => setNewDrawerName(currentTarget.value)}
            css={{
              backgroundColor: Color.transparent,
              borderRadius: 0,
              padding: '5px 0 5px 5px',
              margin: '0 2px 0 5px',
              flex: 1,

              '& > input': {
                borderBottom: `1px solid ${Color.dark44}`,
                letterSpacing: '-0.3px',
                color: Color.darkGreyBlue,
                fontFamily: Font.normal,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: 1.15,
                textTransform: 'capitalize',
              }
            }}
          />
        </EditDrawerForm>
      )}
      {drawer.id !== UserDrawerID && (
        <UpdateIcon onClick={() => isEditMode ? cancel() : setIsEditMode(true)} className="material-icons">
          {isEditMode ? 'close' : 'edit'}
        </UpdateIcon>
      )}
    </Container>
  );
}