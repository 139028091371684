import React, { useState, FormEvent } from 'react';
import glamorous from 'glamorous';
import { Input, InputIcon } from '../../Common/Forms/Input';
import { Color } from '../../Common/Styles';
import { FormErrors } from '../../Common/Forms/FormErrors';
import useI18n from '../../Common/Hooks/useI18n';

interface ShowIconProps {
  shouldShowPassword: boolean;
  hasErrors: boolean;
}

interface Props {
  value: string;
  placeholder?: string;
  onChange: (event: FormEvent) => void;
  errors: string[];
  showErrors?: boolean;
};

const ShowIcon = glamorous(InputIcon)<ShowIconProps>(
  {
    cursor: 'pointer',
    left: 'auto',
    right: '10px',
  },
  ({ shouldShowPassword, hasErrors }) => ({
    color: hasErrors
      ? Color.cherryRed
      : shouldShowPassword ? Color.darkGreyBlue : Color.dark74,
  })
);

export const AuthInputPassword = ({
  value,
  placeholder = 'Your password',
  onChange,
  errors = [],
  showErrors = false,
}: Props) => {
  const { t } = useI18n();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      <Input
        type={showPassword ? 'text' : 'password'}
        placeholder={t(placeholder)}
        name={'password'}
        value={value || ''}
        onChange={onChange}
        icon="vpn_key"
      >
        <ShowIcon
          className="material-icons"
          onClick={toggleShowPassword}
          shouldShowPassword={showPassword}
          hasErrors={errors.length > 0}
        >
          remove_red_eye
        </ShowIcon>
      </Input>
      {showErrors && <FormErrors errors={errors} />}
    </div>
  );
};