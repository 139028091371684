import React from 'react';
import { PageLayout } from '../Common/PageLayout';
import { PageLayoutTheme } from '../Common/Interface';
import { H1 } from '../Common/Typography';
import { ProfilePersonalDetails } from './ProfilePersonalDetails';
import { ProfileChangePassword } from './ProfileChangePassword';
import useI18n from '../Common/Hooks/useI18n';
import ProfileChangeLanguage from './ProfileChangeLanguage';

export const ProfileIndex = () => {
  const { t } = useI18n();

  return (
    <PageLayout theme={PageLayoutTheme.NO_BOTTOM_PADDING} scrollable={true}>
      <H1>{t('Your profile')}</H1>
      <ProfilePersonalDetails />
      <ProfileChangeLanguage />
      <ProfileChangePassword />
    </PageLayout>
  );
}
