const AnimMultiplier = 1.25;

export enum SmartRatingTransitionDelay {
  CircularProgressBar = Math.round(0 * AnimMultiplier),
  CircularProgressBarText = Math.round(1100 * AnimMultiplier),
  CircularProgressBarPath = Math.round(750 * AnimMultiplier),
  HintsContainer = Math.round(0 * AnimMultiplier),
  HintProgressBar = Math.round(500 * AnimMultiplier),
  HintProgressBarText = Math.round(750 * AnimMultiplier),
  Title = Math.round(1100 * AnimMultiplier),
  GoodBadContainer = Math.round(1500 * AnimMultiplier),
  GoodBadHint = Math.round(200 * AnimMultiplier),
  GoodBadHintHighlight = Math.round(1000 * AnimMultiplier),
  BackButton = Math.round(3000 * AnimMultiplier),
}

export enum SmartRatingTransitionDuration {
  CircularProgressBar = Math.round(500 * AnimMultiplier),
  CircularProgressBarText = Math.round(1250 * AnimMultiplier),
  HintProgressBarText = Math.round(500 * AnimMultiplier),
  HintsContainer = Math.round(150 * AnimMultiplier),
  Title = Math.round(1250 * AnimMultiplier),
  GoodBadContainer = Math.round(500 * AnimMultiplier),
  GoodBadHint = Math.round(500 * AnimMultiplier),
  BackButton = Math.round(1250 * AnimMultiplier),
}