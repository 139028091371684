import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { Color, Transition, MediaQuery } from '../../Common/Styles';
import { isReadyProps } from '../interface';
import { SmartRatingTransitionDuration, SmartRatingTransitionDelay } from '../constant';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  name: string;
  value: number;
  delay: number;
}

interface PathProps extends isReadyProps {
  percentage: number;
}

const Container = glamorous.div<isReadyProps>(
  {
    width: '100%',
    maxWidth: '150px',

    transitionProperty: 'opacity, transform',
    transitionDuration: `${SmartRatingTransitionDuration.HintsContainer}ms`,
    transitionDelay: `${SmartRatingTransitionDelay.HintsContainer}ms`,

    [MediaQuery.mobileTablet]: {
      maxWidth: '100%',
      marginBottom: '15px',
    },
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
    transform: `translate3d(0, ${isReady ? '0' : '30px'}, 0)`,
  }),
)

const TextContainer = glamorous.div<isReadyProps>(
  {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
  })
);

const TextName = glamorous.span({
  color: Color.dark74,
  fontSize: '14px',
});

const TextValue = glamorous.span<isReadyProps>(
  {
    color: Color.dark,
    fontWeight: 500,

    transitionProperty: 'opacity, transform',
    transitionDuration: `${SmartRatingTransitionDuration.HintProgressBarText}ms`,
    transitionDelay: `${SmartRatingTransitionDelay.HintProgressBarText}ms`,
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
    transform: `translate3d(${isReady ? '0' : '15px'}, 0, 0)`,
  })
);

const ProgressBarContainer = glamorous.div({
  position: 'relative',
  width: '100%',
  height: '5px',

  '& > span': {
    position: 'absolute',
    backgroundColor: Color.dark,
    left: 0,
  }
});

const Trail = glamorous.span({
  height: '1px',
  width: '100%',
  top: '2px',
  opacity: .3,
});

const Path = glamorous.span<PathProps>(
  {
    top: 0,
    height: '100%',
    ...Transition(
      ['width', '.5s']
    ),
  },
  ({ percentage, isReady }) => ({
    opacity: isReady ? 1 : 0,
    width: `${percentage}%`,
  }),
);

export const RatingFactor = ({ name, value, delay }: Props) => {
  const { t } = useI18n();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsReady(true), delay);

    return (() => {
      timeout && clearTimeout(timeout);
    });
  }, []);

  return (
    <Container isReady={isReady}>
      <TextContainer isReady={isReady}>
        <TextName>{t(name)}</TextName>
        <TextValue isReady={isReady}>{value}</TextValue>
      </TextContainer>
      <ProgressBarContainer>
        <Trail />
        <Path isReady={isReady} percentage={isReady ? value : 0} />
      </ProgressBarContainer>
    </Container>
  )
};
