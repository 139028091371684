import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { Color, Unselectable } from '../Common/Styles';
import { CalendarOverviewContext } from './Contexts/CalendarOverviewContext';
import useI18n from '../Common/Hooks/useI18n';

const Container = glamorous.div<{ isExpanded: boolean }>(
  {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '55px',
    color: Color.white,
    cursor: 'pointer',
    ...Unselectable,
  },
  ({ isExpanded }) => ({
    left: isExpanded ? '628px' : '298px',
  })
);

const Text = glamorous.span({
  fontWeight: 400,
  fontSize: '12px',
  textTransform: 'lowercase',
})

const Icon = glamorous.i({
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '20px',
});

export const CalendarOverviewEditCloseButton = () => {
  const { t } = useI18n();
  const { isExpanded, calendarOverviewActions } = useContext(CalendarOverviewContext);

  return (
    <Container onClick={calendarOverviewActions.toggleExpand} isExpanded={isExpanded}>
      <Icon className="material-icons">{isExpanded ? 'close' : 'edit'}</Icon>
      <Text>{isExpanded ? t('Close') : t('Edit')}</Text>
    </Container>
  );
};
