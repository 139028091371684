import React, { FormEvent, useState, useContext } from 'react';
import glamorous from 'glamorous';
import { Card, CardTitle } from '../Common/Card';
import { Input } from '../Common/Forms/Input';
import { ButtonsGroup } from '../Common/Forms/ButtonsGroup';
import { SubmitButton } from '../Common/Forms/Buttons';
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import { AppStatus } from '../Common/AppStatus';
import { UserContext } from '../Common/Contexts/UserContext';
import { handleEntityChange } from '../Common/FormsHelper';
import { UserClient } from '../Api';
import { handleErrors } from '../Common/Forms/FormHandler';
import { FormInfoBox } from '../Common/Forms/Styles';
import { Color } from '../Common/Styles';
import useI18n from '../Common/Hooks/useI18n';

const MainCard = glamorous(Card)({
  maxWidth: '620px',
});

interface EntityProps<T> {
  firstName: T;
  lastName: T;
}

export const ProfilePersonalDetails = () => {
  const { t } = useI18n();
  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);

  const { user, userActions } = useContext(UserContext);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [entity, setEntity] = useState<EntityProps<string>>({
    firstName: user.firstName,
    lastName: user.lastName,
  });
  const [errors, setErrors] = useState<EntityProps<string[]>>({
    firstName: [],
    lastName: [],
  });

  const clearErrors = () => setErrors({
    firstName: [],
    lastName: [],
  });

  const handleOnChange = (event: FormEvent) => handleEntityChange(event, setEntity, entity);

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();

    setAppStatus(AppStatus.LOADING);
    setShowSuccess(false);

    UserClient
      .update(entity)
      .then(() => {
        clearErrors();
        setAppStatus(AppStatus.READY);
        setShowSuccess(true);
        userActions.update(entity);
      })
      .catch(err => {
        setAppStatus(AppStatus.READY);
        setErrors(handleErrors(errors, err));
      });
  }

  return (
    <MainCard>
      <FormInfoBox backgroundColor={Color.appleGreen} isVisible={showSuccess}>
        {t('Personal data changed successfully')}
      </FormInfoBox>
      <CardTitle>{t('Change personal data')}</CardTitle>
      <form method="post" onSubmit={(event) => appStatus !== AppStatus.LOADING && handleOnSubmit(event)}>
        <Input
          label="First name"
          name="firstName"
          onChange={handleOnChange}
          value={entity.firstName}
          errors={errors.firstName}
        />
        <Input
          label="Surname"
          name="lastName"
          onChange={handleOnChange}
          value={entity.lastName}
          errors={errors.lastName}
        />
        <ButtonsGroup align="right">
          <SubmitButton
            text="Update"
            isActive={appStatus !== AppStatus.LOADING}
          />
        </ButtonsGroup>
      </form>
    </MainCard>
  )
};
