import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { SmartRatingErrorProps } from './interface';
import { Color, BoxShadow, Transition } from '../Common/Styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TemplateContext } from '../Common/Contexts/TemplateContext';

interface Props {
  hideView: () => void;
}

const Icon = glamorous.span({
  color: Color.cherryRed,
  fontSize: '68px',
  margin: '15px 0',
});

const Reason = glamorous.div({
  color: Color.dark,
  fontSize: '18px',
  margin: '0 0 15px 0',
});

const ButtonBack = glamorous.span({
  margin: '15px auto',
  textAlign: 'center',
  fontWeight: 500,
  fontSize: '16px',
  padding: '11px 28px',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: Color.iris,
  color: Color.white,
  textDecoration: 'none',
  boxShadow: BoxShadow.light,
  ...Transition([
    ['color', .3],
    ['background-color', .3],
    ['opacity', .3],
    ['transform', .15],
  ]),

  ':hover': {
    opacity: 0.7,
  },
  
  ':active': {
    transform: `scale(0.97)`,
  }
});

const _SmartRatingError = ({ reason, icon, buttonText, buttonUrl, hideView, history }: Props & SmartRatingErrorProps & RouteComponentProps) => {
  const { templateActions } = useContext(TemplateContext);
  
  const redirectToUrl = () => {
    hideView();
    templateActions.hide();

    setTimeout(() => history.push(buttonUrl), 500);
  }

  return (
    <>
      <Icon className="material-icons">{icon}</Icon>
      <Reason>{reason}</Reason>
      <ButtonBack onClick={redirectToUrl}>{buttonText}</ButtonBack>
    </>
  )
};

export const SmartRatingError = withRouter(_SmartRatingError);
