import React, { useState, FormEvent } from 'react';
import { AuthContainer } from '../../Common/AuthContainer';
import { H1 } from '../Common/AuthTypography';
import { Icon, P, A } from '../Common/AuthStyles';
import { Color } from '../../Common/Styles';
import { handleEntityChange } from '../../Common/FormsHelper';
import { AuthInputPassword } from '../Common/AuthInputPassword';
import { AuthForm } from '../Common/AuthForm';
import { AuthSubmit } from '../Common/AuthSubmit';
import { AuthClient } from '../../Api';
import { useAppStatus } from '../../Common/Hooks/useAppStatus';
import { AppStatus } from '../../Common/AppStatus';
import { handleErrors } from '../../Common/Forms/FormHandler';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  token: string;
}

interface EntityProps<T> {
  password: T;
  repeatPassword?: T;
}

export const SetNewPasswordSuccess = ({ token }: Props) => {
  const { t } = useI18n();
  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);
  
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);
  const [entity, setEntity] = useState<EntityProps<string>>({
    password: ''
  });
  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (event: FormEvent) => handleEntityChange(event, setEntity, entity);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setAppStatus(AppStatus.LOADING);

    AuthClient
      .resetPassword(token, entity.password)
      .then(() => setIsPasswordChanged(true))
      .catch(err => {
        setAppStatus(AppStatus.READY);

        const newErrors: string[] = [];

        Object.values(handleErrors({ password: [], repeatPassword: [] }, err)).forEach((value) => {
          newErrors.push(...value as string[]);
        });

        setErrors(newErrors);
      });
  }

  return (
    <AuthContainer>
    <H1>
      {!isPasswordChanged && <Icon css={{ color: Color.orange }}>vpn_key</Icon>}
      {isPasswordChanged && <Icon css={{ color: Color.appleGreen}}>verified_user</Icon>}
    </H1>
    <P>
      {isPasswordChanged
        ? t('Password changed. You can log in with your new credentials.')
        : t("Set up your password and you're ready to go!")
      }
    </P>
    {!isPasswordChanged && (
      <AuthForm method="post" onSubmit={(event) => appStatus !== AppStatus.LOADING && handleSubmit(event)}>
      <AuthInputPassword
        value={entity.password}
        errors={errors}
        onChange={handleChange}
        showErrors={true}
      />
      <AuthSubmit
        buttonText="Reset password"
        alterHref="/login"
        alterText="Back to Log in"
      />
    </AuthForm>
    )}
    {isPasswordChanged && <A to="/login">Log in</A>}
  </AuthContainer>
  )
};
