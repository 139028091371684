import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { Color, Font } from '../../Common/Styles';
import { isReadyProps } from '../interface';
import { SmartRatingTransitionDuration, SmartRatingTransitionDelay } from '../constant';
import useI18n from '../../Common/Hooks/useI18n';

interface Props extends AlignProps {
  title: string;
  icon: string;
  hints: string[];
  highlightColor: string;
}

interface AlignProps {
  align: 'left' | 'right';
}

interface HintProps extends isReadyProps {
  highlightColor: string;
  isHighlighted: boolean;
  align: 'left' | 'right';
  delay: number;
}

interface AlignAndReadyProps extends isReadyProps {
  align: 'left' | 'right';
}

const Container = glamorous.div<AlignAndReadyProps>(
  {
    flex: 1,
    transitionProperty: 'opacity, transform',
    transitionDuration: `${SmartRatingTransitionDuration.GoodBadContainer}ms`,
    transitionDelay: `${SmartRatingTransitionDelay.GoodBadContainer}ms`,
  },
  ({ align, isReady }) => ({
    textAlign: align,
    opacity: isReady ? 1 : 0,
    transform: align === 'left'
      ? `translate3d(${isReady ? '0' : '-15px'}, 0, 0)`
      : `translate3d(${isReady ? '0' : '15px'}, 0, 0)`,
  }),
);

const TitleContainer = glamorous.div<AlignAndReadyProps>(
  {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
  },
  ({ align, isReady }) => ({
    justifyContent: align === 'left' ? 'flex-start' : 'flex-end',
    opacity: isReady ? 1 : 0,
  }),
);

const TitleText = glamorous.div({
  color: Color.dark,
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'uppercase',
});

const TitleIcon = glamorous.i<AlignProps>(
  {
    color: Color.dark74,
    fontSize: '16px',
  },
  ({ align }) => ({
    margin: align === 'left' ? '0 10px 0 0' : '0 0 0 10px',
  })
);

const HintsContainer = glamorous.ul({
  padding: 0,
});

const Hint = glamorous.li<HintProps>(
  {
    listStyle: 'none',
    fontSize: '14px',
    fontFamily: Font.condensed,
    marginBottom: '10px',
    transitionProperty: 'opacity, color, transform',
    transitionDuration: `${SmartRatingTransitionDuration.GoodBadHint}ms`,
  },
  ({ highlightColor, isHighlighted, isReady, align, delay }) => ({
    color: isHighlighted ? highlightColor : Color.dark74,
    opacity: isReady ? 1 : 0,
    transform: align === 'left'
      ? `translate3d(${isReady ? '0' : '-15px'}, 0, 0)`
      : `translate3d(${isReady ? '0' : '15px'}, 0, 0)`,
    transitionDelay: `${SmartRatingTransitionDelay.GoodBadContainer + delay}ms`
  }),
);

export const RatingHint = ({ title, icon, hints, highlightColor, align }: Props) => {
  const { t } = useI18n();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
  <Container isReady={isReady} align={align}>
    <TitleContainer align={align} isReady={isReady}>
      { align === 'left' && <TitleIcon align={align} className="material-icons">{icon}</TitleIcon> }
      <TitleText>{t(title)}</TitleText>
      { align === 'right' && <TitleIcon align={align} className="material-icons">{icon}</TitleIcon> }
    </TitleContainer>
    <HintsContainer>
      {hints.map((hint, key) => (
        <Hint
          key={key}
          isHighlighted={key === 0}
          highlightColor={highlightColor}
          isReady={isReady}
          align={align}
          delay={key * SmartRatingTransitionDelay.GoodBadHint + SmartRatingTransitionDelay.GoodBadHint}
        >
          {hint}
        </Hint>
      ))}
    </HintsContainer>
  </Container>
  )
};
