import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { Thing } from '../Dto/CommonDto';
import { ThingStatus } from "../Dto/ThingDto";
import { IsoDate } from '../Interface';
import { transformEntity } from '../Helpers';

export class DrawerApiClient extends AccessTokenApiClient {
  getThingsForDefault(): Promise<Thing<Date>[]> {
    return this.apiRequest
      .get<Thing<IsoDate>[]>('', {
        params: {
          filter: {
            withProject: false,
            status: ThingStatus.TO_DO,
          }
        }
      })
      .then(({ data }) => data.map(thing => transformEntity<Thing<Date>>(thing)));
  }
}