import React from 'react';
import { ThingSeparator } from './ThingSeparator';
import glamorous from 'glamorous';
import { ThingStatus } from '../../Api/Dto/ThingDto';
import { DailyPlanStatus } from '../../Api/Dto/CommonDto';
import useI18n from '../Hooks/useI18n';

interface Props {
  status: ThingStatus;
  quantity: number;
  planStatus?: DailyPlanStatus;
  outlined?: boolean;
  omitMargin?: boolean;
}

const ThingsListSeparator = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const statusToIcon = (status: ThingStatus, planStatus: DailyPlanStatus): string => {
  switch (status) {
    case ThingStatus.TO_DO:
        return planStatus === DailyPlanStatus.COMPLETED ? 'close' : 'schedule';
    case ThingStatus.CLOSED:
    case ThingStatus.DONE:
      return 'done';
    default:
      return 'help_outline';
  }
}

const statusToText = (status: ThingStatus, planStatus: DailyPlanStatus): string => {
  switch (status) {
    case ThingStatus.TO_DO:
      return planStatus === DailyPlanStatus.COMPLETED ? 'failed' : 'to do';
    case ThingStatus.CLOSED:
    case ThingStatus.DONE:
      return 'done';
    default:
      return '(other)';
  }
}

export const ThingStatusSeparator = ({ status, quantity, planStatus = DailyPlanStatus.ACTIVE, outlined = true, omitMargin = false }: Props) => {
  const { t } = useI18n();
  
  return (
    <ThingSeparator outlined={outlined} omitMargin={omitMargin} status={status} planStatus={planStatus}>
      <ThingsListSeparator>
        <i className="material-icons">{statusToIcon(status, planStatus)}</i>
        <span>{quantity} {t('things')} {t(statusToText(status, planStatus))}</span>
      </ThingsListSeparator>
    </ThingSeparator>
  );
}
