import React, { useState, useEffect } from 'react';
import { AuthContainer } from '../../Common/AuthContainer';
import { OrganizationClient } from '../../Api';
import { UserOrganization, GenericDate } from '../../Api/Dto/CommonDto';
import { IsoDate } from '../../Api/Interface';
import { HelloGreetings } from './HelloGreetings';

enum WhatToShow {
  GREETINGS = 'greetings',
  INTRODUCTION = 'introduction',
  WAITING = 'waiting',
}

export const Hello = () => {
  const [whatToShow, setWhatToShow] = useState<WhatToShow>(WhatToShow.WAITING);
  const [firstName, setFirstName] = useState<string>('');

  const parseFirstName = ({ user }: UserOrganization<GenericDate>): string => (
    user ? user.firstName : ''
  );

  const createOrganization = (name: string = '') => OrganizationClient
    .create(name)
    .then(() => {
      setWhatToShow(WhatToShow.INTRODUCTION);
    })

  const setMountState = (organizations: UserOrganization<IsoDate>[]) => {
    if (organizations.length > 0) {
      setWhatToShow(WhatToShow.GREETINGS);
      setFirstName(parseFirstName(organizations[0]));
    } else {
      createOrganization('');
    }
  }

  const fetchOrganizations = () => OrganizationClient
    .getList()
    .then(organizations => setMountState(organizations || []));

  useEffect(() => {
    fetchOrganizations();
  }, []);

  switch(whatToShow) {
    case WhatToShow.INTRODUCTION:
    case WhatToShow.GREETINGS:
      return <HelloGreetings firstName={firstName} />
    default:
      return ( <AuthContainer> </AuthContainer> );
  }
}
