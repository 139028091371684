import React, { useState, FormEvent } from 'react';
import { handleEntityChange } from '../../Common/FormsHelper';
import { AuthClient } from '../../Api';
import { handleErrors } from '../../Common/Forms/FormHandler';
import { Redirect } from 'react-router-dom';
import { AuthContainer } from '../../Common/AuthContainer';
import { H1 } from '../Common/AuthTypography';
import { AuthForm } from '../Common/AuthForm';
import { AuthInput } from '../Common/AuhInput';
import { AuthSubmit } from '../Common/AuthSubmit';

interface Props {
  token: string;
  firstName: string;
  lastName: string;
}

interface Entity {
  firstName: string;
  lastName: string;
}

export const VerifyAccountSetUser = ({ firstName, lastName, token }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [entity, setEntity] = useState<Entity>({
    firstName,
    lastName,
  });
  const [errors, setErrors] = useState({
    firstName: [],
    lastName: [],
  });

  const handleChange = (event: FormEvent) => !isLoading && handleEntityChange(event, setEntity, entity);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    AuthClient
      .activateAccount(
        token,
        entity.firstName,
        entity.lastName,
      )
      .then(() => {
        localStorage.removeItem('organization_token');
        localStorage.removeItem('organization_id');
        localStorage.removeItem('access_token');

        setIsFormSubmitted(true);
      })
      .catch(err => {
        console.log(err, 'activate account last catch');

        setIsLoading(false);
        setErrors(handleErrors(errors, err));
      });
  }

  if (isFormSubmitted) {
    return <Redirect to="/" />
  }

  return (
    <AuthContainer isLoading={isLoading}>
      <H1>Activate account</H1>
      <AuthForm method="post" onSubmit={handleSubmit}>
      <AuthInput
          name="firstName"
          type="text"
          placeholder="Your first name"
          icon="filter_1"
          value={entity.firstName}
          errors={errors.firstName}
          onChange={handleChange}
        />
        <AuthInput
          name="lastName"
          type="text"
          placeholder="Your surname"
          icon="filter_2"
          value={entity.lastName}
          errors={errors.lastName}
          onChange={handleChange}
        />
        <AuthSubmit
          buttonText="Activate and continue"
        />
      </AuthForm>
    </AuthContainer>
  )
}