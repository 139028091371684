import React from 'react';
import glamorous from 'glamorous';
import { Color, Unselectable } from '../Styles';

interface Props {
  number: number;
}

const Container = glamorous.div({
  fontSize: '14px',
  fontWeight: 400,
  boxShadow: 'rgb(117, 117, 117) 0px 0px 1pt',
  backgroundColor: Color.white,
  color: 'rgb(158, 158, 158)',
  marginRight: '13px',
  textAlign: 'center',
  width: '32px',
  height: '32px',
  lineHeight: '32px',
  borderRadius: '50%',
  flex: '0 0 auto',
  ...Unselectable,
});

export const ThingNumber = ({ number }: Props) => (
  <Container>{number}</Container>
);