import React from 'react';
import glamorous from 'glamorous';
import { Font, Color } from '../../Common/Styles';
import useI18n from '../../Common/Hooks/useI18n';

const Hint = glamorous.div({
  flex: 1,
  fontFamily: Font.condensed,
  fontSize: '14px',
  color: Color.dark66,
  textAlign: 'center',
  paddingTop: '10px',
});

export const MobileDayNoPlan = () => {
  const { t } = useI18n();

  return (
    <Hint>{t('Tap to create a plan')}</Hint>
  );
}
