import React, { useState, useEffect, useContext } from 'react';
import { PageLayout } from '../Common/PageLayout';
import { PageLayoutTheme } from '../Common/Interface';
import glamorous from 'glamorous';
import { CalendarOverview } from './CalendarOverview';
import { CalendarHeader } from './CalendarHeader';
import { GridCalendar } from './common/GridCalendar';
import { CalendarContentHoc } from './Hoc/CalendarContentHoc';
import { CalendarOverviewContext, SetCalendarOverviewContext } from './Contexts/CalendarOverviewContext';
import { ZIndexLevel, Color } from '../Common/Styles';
import { TemplateContext } from '../Common/Contexts/TemplateContext';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';


interface BackdropProps {
  isActive: boolean;
}

const Container = glamorous.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Backdrop = glamorous.div<BackdropProps>(
  {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  cursor: 'pointer',
  zIndex: ZIndexLevel.calendarBackdrop,
  backgroundColor: Color.twilight,
  transitionProperty: 'opacity,visibility',
  transitionDuration: '.3s',

  },
  ({ isActive }) => ({
    opacity: isActive ? 0.4 : 0,
    visibility: isActive ? 'visible' : 'hidden',
  }),
);

export const CalendarDesktop = () => {
  const { isTemplateHidden, templateActions } = useContext(TemplateContext);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    isTemplateHidden && templateActions.show();
  }, []);

  return (
    <DefaultTheme>
      <PageLayout theme={PageLayoutTheme.NO_PADDING} scrollable={false}>
        <Container>
          <CalendarOverviewContext.Provider value={SetCalendarOverviewContext([isExpanded, setIsExpanded])}>
            <CalendarContentHoc>
              <CalendarHeader />
              <GridCalendar />
            </CalendarContentHoc>
            <CalendarOverview />
            <Backdrop onClick={() => setIsExpanded(false)} isActive={isExpanded} />
          </CalendarOverviewContext.Provider>
        </Container>
      </PageLayout>
    </DefaultTheme>
  );
}
