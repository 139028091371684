import { Uuid, IsoDate } from '../Interface';
import { YmdStrict } from '../../Calendar/interfaces';

export type GenericDate = IsoDate | Date;

export interface Entity<T extends GenericDate> {
  id: Uuid;
  createdAt: T;
  updatedAt: T | null;
  [key: string]: any;
}

export interface FilterDto {
  createdAtFrom?: Date;
  createdAtTo?: Date;
}

export interface SearchDto<T = any> {
  paginate?: {
    start?: number;
    limit?: number;
  };
  sort?: {
    order?: 'ASC' | 'DESC';
    column?: string;
  };
  filter?: T;
}

export interface MessageResponse {
  message: string;
}

export enum UserStatus {
  ACTIVATION_REQUIRED = 'activation_required',
  ACTIVE = 'active',
  BANNED = 'banned',
  DELETED = 'deleted',
}

export enum UserOrganizationStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  SUSPENDED = 'suspended',
}

export enum UserOrganizationRole {
  OWNER = 'owner',
  EMPLOYEE = 'employee',
}

export interface User<T extends GenericDate> extends Entity<T> {
  oauth: {};
  email: string;
  firstName: string;
  lastName: string;
  status: UserStatus;
}

export interface Organization<T extends GenericDate> extends Entity<T> {
  name: string | null;
}

export interface UserOrganization<T extends GenericDate> extends Entity<T> {
  user?: User<T>;
  userId: Uuid;
  organization?: Organization<T>;
  organizationId: Uuid;
  status: UserOrganizationStatus;
  invitedBy: Uuid;
  role: UserOrganizationRole;
}

export enum DrawerStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export interface Drawer<T extends GenericDate> extends Entity<T> {
  name: string;
  description?: string;
  organization?: Organization<T>;
  organizationId: string;
  status: DrawerStatus;
  tasksSummary?: {
    done: number,
    to_do: number;
  },
}

export enum ThingStatus {
  TO_DO = 'to_do',
  DELETED = 'deleted',
  DONE = 'done',
  CLOSED = 'closed',
}

export interface Thing<T extends GenericDate> extends Entity<T> {
  assigneeId: Uuid | null;
  checklist: null;
  createdById: Uuid;
  deadline: string | null;
  description: string | null;
  estimation: null;
  labels: string[];
  number: number | null;
  organizationId: Uuid;
  projectId: Uuid | null;
  status: ThingStatus;
  title: string;
}

export interface GrouppedThings<T extends GenericDate> {
  [key:string]: Thing<T>[];
}

export enum DailyPlanStatus {
  DRAFT = 'draft',
  PREPARED = 'prepared',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  DELETED = 'deleted',
}

export interface DailyPlanDto extends Entity<IsoDate> {
  status: DailyPlanStatus;
  deadline: string;
  tasks: Thing<IsoDate>[];
  rating: number | null;
}

export interface DailyPlan<T extends GenericDate> extends Entity<T> {
  status: DailyPlanStatus;
  deadline: YmdStrict;
  things: Thing<T>[];
  rating: number | null;
}

export interface GrouppedDailyPlans<T extends GenericDate> {
  [key:string]: DailyPlan<T>;
}
