import { Thing, Drawer, GenericDate, GrouppedThings } from '../../Api/Dto/CommonDto';
import { UpdateThingProps } from '../common';
import { Reducer } from 'react';
import { UnallowedForSetAllActionError } from '../Errors/UnallowedForSetAllActionError';
import { Uuid } from '../../Api/Interface';
import { hasItem } from '../../Common/ArrayHelper';

export type ThingsReducerAction =
| { type: ThingsAction.ADD, value: { drawer: Drawer<GenericDate>, thing: Thing<Date> } }
| { type: ThingsAction.ADD_MANY, value: { drawer: Drawer<GenericDate>, things: Thing<Date>[] } }
| { type: ThingsAction.REMOVE, value: { drawer: Drawer<GenericDate>, thing: Thing<Date> } }
| { type: ThingsAction.REMOVE_MANY, value: { drawer: Drawer<GenericDate>, things: Thing<Date>[] } }
| { type: ThingsAction.SET, value: { drawer: Drawer<GenericDate>, things: Thing<Date>[] } }
| { type: ThingsAction.SET_ALL, values: GrouppedThings<Date> }
| { type: ThingsAction.UPDATE, value: { drawer: Drawer<GenericDate>, thing: Thing<Date> } }

export enum ThingsAction {
  ADD = 'ADD',
  ADD_MANY = 'ADD_MANY',
  REMOVE = 'REMOVE',
  REMOVE_MANY = 'REMOVE_MANY',
  SET = 'SET',
  SET_ALL = 'SET_ALL',
  UPDATE = 'UPDATE',
}

export const ThingsReducer: Reducer<GrouppedThings<Date>, ThingsReducerAction> = (state, action) => {
  const getCurrentDrawer = (): Thing<Date>[] => {
    if (action.type === ThingsAction.SET_ALL) {
      throw new UnallowedForSetAllActionError();
    }

    return state[action.value.drawer.id] || [];
  }

  const isThingInDrawer = (thing: Thing<Date> | string): boolean => {
    if (action.type === ThingsAction.SET_ALL) {
      throw new UnallowedForSetAllActionError();
    }

    return state[action.value.drawer.id]
    && state[action.value.drawer.id]
      .find(drawerThing => drawerThing.id === (typeof thing === 'string' ? thing : thing.id)) !== undefined
  };

  const applyThingsToDrawer = (things: Thing<Date>[]): GrouppedThings<Date> => {
    if (action.type === ThingsAction.SET_ALL) {
      throw new UnallowedForSetAllActionError();
    }

    return {
      ...state,
      [action.value.drawer.id]: things,
    }
  };

  switch (action.type) {
    case ThingsAction.ADD:
      return isThingInDrawer(action.value.thing)
        ? state
        : applyThingsToDrawer([action.value.thing, ...getCurrentDrawer()]);
    case ThingsAction.ADD_MANY:
      const thingsToAdd = action.value.things.filter(thing => !isThingInDrawer(thing));

      return thingsToAdd.length > 0
        ? applyThingsToDrawer([...thingsToAdd, ...getCurrentDrawer()])
        : state;
    case ThingsAction.REMOVE:
      return applyThingsToDrawer(getCurrentDrawer().filter(thing => thing.id !== action.value.thing.id));
    case ThingsAction.REMOVE_MANY:
      const thingsIdsToRemove: Uuid[] = action.value.things.map(thing => thing.id);

      return applyThingsToDrawer(getCurrentDrawer().filter(thing => !hasItem<Uuid>(thingsIdsToRemove, thing.id)));
    case ThingsAction.SET:
      return applyThingsToDrawer(action.value.things);
    case ThingsAction.SET_ALL:
      return {
        ...state,
        ...action.values
      };
    case ThingsAction.UPDATE:
      return isThingInDrawer(action.value.thing)
        ? applyThingsToDrawer(getCurrentDrawer().map(thing => thing.id === action.value.thing.id
            ? {
              ...thing,
              ...action.value.thing,
            }
            : thing
          ))
        : state;
    default:
      throw new Error('ThingsReducer: Undefined action');
  }
}