import React, { ReactNode } from 'react';
import backgroundImage from '../assets/images/background/auth-bg.png';
import glamorous from 'glamorous';
import { Color, Font, MediaQuery, ZIndexLevel } from './Styles';
import { AuthLogo } from '../Auth/Common/AuthLogo';

interface Props {
  children: ReactNode;
  isLoading?: boolean;
}

interface AuthContentProps {
  isLoading: boolean;
}

const Container = glamorous.div(
  {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: ZIndexLevel.level1,
    overflow: 'hidden',
    fontFamily: Font.normal,

    [MediaQuery.mobileOnly]: {
      backgroundColor: Color.lightIris,
      backgroundImage: `url("${backgroundImage}")`,
    }
  }
);

const AuthBackground = glamorous.div(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: Color.lightIris,
    overflow: 'hidden',
    zIndex: -ZIndexLevel.level1,

    [MediaQuery.mobileOnly]: {
      display: 'none',
    }
  }
);

const AuthBackgroundImage = glamorous.img(
  {
    width: '100%',
    minWidth: '1280px',
    minHeight: '800px',
  }
);

const AuthContent = glamorous.div<AuthContentProps>(
  {
    width: '100%',
    textAlign: 'center',
    color: Color.white,
    transition: 'opacity, .3s',
  },
  ({ isLoading }) => ({
    opacity: isLoading ? 0.5 : 1,
  }),
);

const AuthFormContent = glamorous.div(
  {
    [MediaQuery.mobileOnly]: {
      padding: '0 15px',
    }
  }
);

export const AuthContainer = ({ children, isLoading = false }: Props) => {
  return (
    <Container>
      <AuthBackground>
        <AuthBackgroundImage src={backgroundImage} />
      </AuthBackground>
      <AuthContent isLoading={isLoading}>
        <AuthLogo />
        {/* AnimComponentMount */}
        <AuthFormContent>
          {children}
        </AuthFormContent>
        {/* /AnimComponentMount */}
      </AuthContent>
    </Container>
  );
}