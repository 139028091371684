import React, { useReducer } from 'react';
import { CalendarThingsContext, SetCalendarThingsContext } from '../Contexts/CalendarThingsContext';
import { CalendarThingsReducer } from '../Reducers/CalendarThingsReducer';

interface Props {
  children: any
}

export const CalendarThingsHoc = ({ children }: Props) => {
  const [things, dispatch] = useReducer(CalendarThingsReducer, []);

  return (
    <CalendarThingsContext.Provider value={SetCalendarThingsContext(things, dispatch)}>
      {children}
    </CalendarThingsContext.Provider>
  )
};
