import { TokenContainer } from './TokenContainer';
import axios, { AxiosError, AxiosRequestConfig, AxiosPromise } from 'axios';
import { Config } from './../Config';
import { stringify } from 'qs';

const { API_URL } = Config;

export class ApiRequest {
  constructor(
    private readonly prefixUrl: string,
    public readonly tokenContainer: TokenContainer,
    private readonly unauthorizationHandler: Function = (error: any) => {},
  ) {
    this.handleUnauthorizedResponse = this.handleUnauthorizedResponse.bind(this);
  }

  get<T = any>(url: string, options: AxiosRequestConfig = {}): AxiosPromise<T> {
    return axios
      .get<T>(this.parseUrl(url), this.parseOptions(options))
      .catch(this.handleUnauthorizedResponse);
  }

  post<T = any>(url: string, data: any, options: AxiosRequestConfig = {}): AxiosPromise<T> {
    return axios
      .post<T>(this.parseUrl(url), data, this.parseOptions(options))
      .catch(this.handleUnauthorizedResponse);
  }

  put<T = any>(url: string, data: any, options: AxiosRequestConfig = {}): AxiosPromise<T> {
    return axios
      .put<T>(this.parseUrl(url), data, this.parseOptions(options))
      .catch(this.handleUnauthorizedResponse);
  }

  patch<T = any>(url: string, data: any, options: AxiosRequestConfig = {}): AxiosPromise<T> {
    return axios
      .patch(this.parseUrl(url), data, this.parseOptions(options))
      .catch(this.handleUnauthorizedResponse);
  }

  delete(url: string, options: AxiosRequestConfig = {}): AxiosPromise {
    return axios
      .delete(this.parseUrl(url), this.parseOptions(options))
      .catch(this.handleUnauthorizedResponse);
  }

  head(url: string, options: AxiosRequestConfig = {}): AxiosPromise {
    return axios
      .head(this.parseUrl(url), this.parseOptions(options))
      .catch(this.handleUnauthorizedResponse);
  }

  private handleUnauthorizedResponse(error: AxiosError) {
    if (error.response && (
        error.response.status === 401
        || error.response.status === 403
      )
    ) {
      return this.unauthorizationHandler(error, this.tokenContainer);
    }

    throw error;
  }

  private parseUrl(url: string): string {
    return `${API_URL}/${this.prefixUrl}/${url}`;
  }

  private parseOptions(options: AxiosRequestConfig = {}): AxiosRequestConfig {
    const token = this.tokenContainer.getToken();

    if (token) {
      options.headers = options.headers || {};

      options.headers.Authorization = `Bearer ${token}`;
    options.headers['X-UTC-Offset'] = ((new Date()).getTimezoneOffset() / 60);
    }

    if (options.params) {
      options.paramsSerializer = params => stringify(params, { arrayFormat: 'brackets' });
    }

    return options;
  }
}