export enum ThingTheme {
  DEFAULT = 'DEFAULT',
  DEFAULT_SELECTED = 'DEFAULT_SELECTED',
  EDIT_MODE = 'EDIT_MODE',
  PREPARED_PLAN = 'PREPARED_PLAN',
  PREPARED_PLAN_EDIT_MODE_READONLY = 'PREPARED_PLAN_EDIT_MODE_READONLY',
  COMPLETED_PLAN = 'COMPLETED_PLAN',
  COMPLETED_PLAN_NO_BORDER = 'COMPLETED_PLAN_NO_BORDER',
  HIDDEN = 'HIDDEN',
}
