import glamorous from 'glamorous';
import { ReactNode } from 'react';

type Align = 'left' | 'right' | 'center';

interface ButtonsGroup {
  align?: Align;
  children?: ReactNode;
  flexDirection?: 'row' | 'column';
}

const setJustifyContent = (setAlign: Align): string => {
  switch (setAlign) {
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'left':
    default:
      return 'flex-start';
  }
}

export const ButtonsGroup = glamorous.div<ButtonsGroup>(
  {
    display: 'flex'
  },
  ({ align = 'left', flexDirection }) => ({
    justifyContent: setJustifyContent(align),
    flexDirection,
  })
)