import React, { useEffect, useState, useContext } from 'react';
import { DailyPlan, ThingStatus, Thing, DailyPlanStatus } from '../../Api/Dto/CommonDto';
import glamorous from 'glamorous';
import { CircularProgressBar } from '../../Common/CircularProgressBar';
import { Color, Size, hexToRgba, Font, Unselectable, MediaQuery } from '../../Common/Styles';
import { ResponsiveList } from '../../Common/Hoc/ResponsiveList';
import { ThingElement } from './ThingElement';
import { PlanOverviewHints } from './PlanOverviewHints';
import { ThingStatusSeparator } from '../../Common/Thing/ThingStatusSeparator';
import { ThingTheme } from '../../Common/Thing/common';
import { ProgressButton } from '../../Common/ProgressButton';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { toMonthRaw } from '../../Calendar/helpers';
import { AxiosError } from 'axios';
import { AppStatus } from '../../Common/AppStatus';
import { DailyPlanClient } from '../../Api';
import { TemplateContext } from '../../Common/Contexts/TemplateContext';
import { Small } from '../../Common/Typography';
import { UserContext } from '../../Common/Contexts/UserContext';
import { Button } from '../../Common/Forms/Buttons';
import dayjs from 'dayjs';
import { ButtonsGroup } from '../../Common/Forms/ButtonsGroup';
import { MobileSubNavigation, MobileNavButton } from '../../Common/MobileSubNavigation';
import { TodayPlanEmpty } from './TodayPlanEmpty';
import { DotLoader } from '../../Common/Loaders/DotLoader';
import { TypeOfDay } from '../constants';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  plan: DailyPlan<Date> | null;
  appStatus: AppStatus;
  typeOfDay: TypeOfDay;
  toggleThingStatus: (thing: Thing<Date>) => void;
  togglePlan: () => void;
}

interface IsVisibleProps {
  isVisible: boolean;
}

enum SelectedView {
  OVERVIEW = 'Overview',
  DETAILS = 'Plan details',
  HINTS = 'Hints',
}

const Container = glamorous.div({
  display: 'flex',
  width: '100%',
  maxWidth: '1240px',
  justifyContent: 'flex-start',
  position: 'relative',

  [MediaQuery.mobileOnly]: {
    flexDirection: 'column',
  },
});

const Overview = glamorous.div<IsVisibleProps>(
  {
    width: '280px',
    textAlign: 'center',
    padding: '15px',
  },
  ({ isVisible }) => ({
    [MediaQuery.mobileOnly]: {
      display: isVisible ? 'flex' : 'none',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
    },
  }),
);

const PlanProgress = glamorous.div({
  position: 'relative',
});

const ProgressPercentage = glamorous.span({
  position: 'absolute',
  top: '70px',
  left: 0,
  width: '100%',
  textAlign: 'center',
  fontSize: '42px',
  fontWeight: 500,
  lineHeight: 1,
  color: Color.dark74,
});

const ProgressThingsSummary = glamorous.span({
  position: 'absolute',
  width: '100%',
  top: '115px',
  left: 0,
  fontSize: '12px',
  fontWeight: 500,
  fontFamily: Font.condensed,
  color: Color.dark74,
})

const ThingsListContainer = glamorous.div<IsVisibleProps>(
  {
    display: 'flex',
    flex: 1,
    maxWidth: '500px',
    height: '100%',
    flexDirection: 'column',
    marginRight: '15px',
  },
  ({ isVisible }) => ({
    [MediaQuery.mobileOnly]: {
      display: isVisible ? 'block' : 'none',
      height: '100%',

      '& > div': {
        height: '100%',
      }
    },
  }),
);

const EditButton = glamorous(Link)({
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  backgroundColor: hexToRgba(Color.iris, 0.3),
  borderRadius: '4px',
  padding: '12px',
  fontSize: '16px',
  fontWeight: 500,
  cursor: 'pointer',
  color: Color.twilight,
  ...Unselectable,

  '& > i': {
    fontSize: '16px',
    marginRight: '5px',
  }
});

const Description = glamorous(Small)({
  display: 'block',
  margin: '15px 0',
  lineHeight: 1,
});

const MobileNavigation = glamorous(MobileSubNavigation)({
    [MediaQuery.tableDesktop]: {
      display: 'none',
    },
});

const MobileNavigationTitle = glamorous.span({
  color: Color.dark66,
  fontWeight: 500,
  fontSize: '14px',
  textTransform: 'uppercase',
});

const _PlanOverview = ({ plan, toggleThingStatus, typeOfDay, history, appStatus }: Props & RouteComponentProps) => {
  const { t } = useI18n();
  const { templateActions } = useContext(TemplateContext);
  const { user } = useContext(UserContext);

  const [selectedView, setSelectedView] = useState<SelectedView>(SelectedView.OVERVIEW);
  const [percentage, setPercentage] = useState<number>(0);
  const [thingsToDo, setThingsToDo] = useState<Thing<Date>[]>([]);
  const [thingsDone, setThingsDone] = useState<Thing<Date>[]>([]);

  useEffect(() => {
    setThingsToDo(
      plan === null
        ? []
        : plan.things.filter(thing => thing.status === ThingStatus.TO_DO)
    );
    setThingsDone(
      plan === null
        ? []
        : plan.things.filter(thing => thing.status !== ThingStatus.TO_DO)
    )
  }, [plan]);

  useEffect(() => {
    setPercentage(
      Math.round((thingsDone.length / (thingsToDo.length + thingsDone.length) * 100))
    );
  }, [thingsToDo, thingsDone]);

  const parseCalendarUrl = () => plan !== null
    ? `/calendar/${plan.deadline.year}/${toMonthRaw(plan.deadline.month)}/${plan.deadline.day || 1}`
    : '/calendar';

  const finishPlan = () => {
    if (plan === null) {
      return;
    }

    DailyPlanClient
      .complete(plan)
      .then(() => {
        templateActions.hide();
  
        setTimeout(() => {
          history.push(`/smart-rating/${plan.deadline.year}-${toMonthRaw(plan.deadline.month)}-${plan.deadline.day}`);
        }, 500);
      })
      .catch((error: AxiosError) => {
        if (error.config === undefined) {
          console.log(error.message);
        }
      })
  }

  const redirectToCalendarTomorrow = () => {
    const tomorrowDate = dayjs().add(1, 'day').toDate();

    history.push(`/calendar/${tomorrowDate.getFullYear()}/${toMonthRaw(tomorrowDate.getMonth())}/${tomorrowDate.getDate()}`);
  }

  const selectNextView = () => {
    switch (selectedView) {
      case SelectedView.OVERVIEW:
        return setSelectedView(SelectedView.DETAILS);
      case SelectedView.DETAILS:
        return setSelectedView(SelectedView.HINTS);
      case SelectedView.HINTS:
        return setSelectedView(SelectedView.OVERVIEW);
    }
  };

  const selectPrevView = () => {
    switch (selectedView) {
      case SelectedView.OVERVIEW:
        return setSelectedView(SelectedView.HINTS);
      case SelectedView.DETAILS:
        return setSelectedView(SelectedView.OVERVIEW);
      case SelectedView.HINTS:
        return setSelectedView(SelectedView.DETAILS);
    }
  };

  return (
    <Container>
      {plan !== null && (
        <>
          <Overview isVisible={selectedView === SelectedView.OVERVIEW}>
            <PlanProgress>
              <CircularProgressBar
                pathColor={Color.appleGreen}
                trailColor={hexToRgba(Color.dark, .3)}
                size={Size.huge}
                percentage={percentage || 0}
              />
              <ProgressPercentage>{percentage || 0}%</ProgressPercentage>
              <ProgressThingsSummary>{thingsDone.length}/{thingsDone.length + thingsToDo.length} {t('things done')}</ProgressThingsSummary>
            </PlanProgress>
            {(plan.status === DailyPlanStatus.ACTIVE || plan.status === DailyPlanStatus.DRAFT || plan.status === DailyPlanStatus.PREPARED) && (
              <ButtonsGroup flexDirection="column">
                <EditButton to={parseCalendarUrl()}>
                  <i className="material-icons">edit</i> {t('Edit plan')}
                </EditButton>
                <ProgressButton
                  percentage={percentage}
                  isActive={appStatus !== AppStatus.LOADING}
                  onClick={finishPlan}
                >
                  {t('Finish plan')}
                </ProgressButton>
              </ButtonsGroup>
            )}
            {plan.status === DailyPlanStatus.COMPLETED && (
              <>
                <Description>{user.firstName}, {t("you already finished this plan. It's the best time to plan tomorrow right now.")}</Description>
                <Button
                  color={Color.appleGreen}
                  isActive={true}
                  onClick={redirectToCalendarTomorrow}
                >
                  {t("Let's plan for tomorrow")}
                </Button>
              </>
            )}
          </Overview>
          <ThingsListContainer isVisible={selectedView === SelectedView.DETAILS}>
            <ResponsiveList styles={{ flex: 1 }}>
              <ThingStatusSeparator
                outlined={false}
                planStatus={plan.status}
                status={ThingStatus.TO_DO}
                quantity={thingsToDo.length}
              />
              {thingsToDo.map(thing => <ThingElement 
                key={thing.id}
                thing={thing}
                theme={plan.status === DailyPlanStatus.COMPLETED ? ThingTheme.COMPLETED_PLAN : ThingTheme.DEFAULT}
                isPlanCompleted={plan.status === DailyPlanStatus.COMPLETED}
                onCheck={() => toggleThingStatus(thing)}
              />)}
              <ThingStatusSeparator
                outlined={false}
                planStatus={DailyPlanStatus.ACTIVE}
                status={ThingStatus.DONE}
                quantity={thingsDone.length}
              />
              {thingsDone.map(thing => <ThingElement
                key={thing.id}
                thing={thing}
                theme={ThingTheme.COMPLETED_PLAN}
                isPlanCompleted={plan.status === DailyPlanStatus.COMPLETED}
                onCheck={() => toggleThingStatus(thing)}
              />)}
            </ResponsiveList>
          </ThingsListContainer>
          <PlanOverviewHints isVisible={selectedView === SelectedView.HINTS} />
        </>
      )}
      {plan === null && appStatus === AppStatus.LOADING && <DotLoader /> }
      {plan === null && appStatus !== AppStatus.LOADING && <TodayPlanEmpty isTomorrow={typeOfDay === TypeOfDay.TOMORROW} />}
      {(plan !== null && appStatus !== AppStatus.LOADING) && (
        <MobileNavigation>
        <MobileNavButton onClick={selectPrevView} className="material-icons">chevron_left</MobileNavButton>
        <MobileNavigationTitle>{t(selectedView)}</MobileNavigationTitle>
        <MobileNavButton onClick={selectNextView} className="material-icons">chevron_right</MobileNavButton>
      </MobileNavigation>
      )}
    </Container>
  )
};

export const PlanOverview = withRouter(_PlanOverview);
