import React, { useState, useEffect } from 'react';
import { Drawer as DrawerEntity } from '../Api/Dto/CommonDto';
import { Drawer } from './Drawer';
import { DrawerList } from './DrawerList';
import { Uuid } from '../Api/Interface';
import { CreateDrawer } from './Common/CreateDrawer';

interface Props {
  drawers: DrawerEntity<Date>[]
}

export const ThingsMobile = ({ drawers }: Props) => {
  const [selectedDrawer, setSelectedDrawer] = useState<DrawerEntity<Date> | null>(drawers[0]);

  const selectDrawer = (drawerId: Uuid | null) => setSelectedDrawer(drawerId !== null
    ? drawers.find(drawer => drawer.id === drawerId) || drawers[0]
    : null
  );

  const moveToCreatedDrawer = (createdDrawer: DrawerEntity<Date>) => setSelectedDrawer(createdDrawer);

  useEffect(() => {
    if (selectedDrawer === null) {
      return;
    }

    const foundDrawer = drawers.find(drawer => selectedDrawer.id === drawer.id);

    foundDrawer !== undefined && foundDrawer.name !== selectedDrawer.name && setSelectedDrawer(foundDrawer);
  }, [drawers]);

  return (
    <>
      {selectedDrawer !== null && <Drawer
        drawer={selectedDrawer}
        omitMargin={true}
        toolsAlignment="bottom"
        placeForDrawerList
      />}
      {selectedDrawer === null && <CreateDrawer afterCreate={moveToCreatedDrawer} />}
      <DrawerList drawers={drawers} activeDrawerId={selectedDrawer !== null ? selectedDrawer.id : null} selectDrawer={selectDrawer} />
    </>
  )
};
