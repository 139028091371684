import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { zerofill } from '../../Common/Helpers';
import { Color } from '../../Common/Styles';
import { isReadyProps } from '../interface';
import { SmartRatingTransitionDuration, SmartRatingTransitionDelay } from '../constant';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  rating: number;
}

const titles = [
  'Maybe tomorrow?', // 0-9
  'Focus. You can do it!', // 10-19
  'You can do better!', // 20-29
  'Just keep going!', // 30-39
  'Just one more step!', // 40-49
  'Well done!', // 50-59
  'Really good!', // 60-69
  'You\'re doing great!', // 70-79
  'Brilliant!', // 80-89
  'Almost perfect!', // 90-99
  'Outstanding!', // 100
];

const Container = glamorous.span<isReadyProps>(
  {
    fontSize: '18px',
    fontWeight: 500,
    color: Color.dark,
    marginBottom: '15px',

    transitionProperty: 'opacity, transform',
    transitionDuration: `${SmartRatingTransitionDuration.Title}ms`,
    transitionDelay: `${SmartRatingTransitionDelay.Title}ms`,
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
    transform: `translate3d(0,${isReady ? '0' : '-30px'},0)`,
  }),
);

export const RatingTitle = ({ rating }: Props) => {
  const { t } = useI18n();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <Container isReady={isReady}>
      {t(titles[Number(zerofill(rating, 2)[0])], 'rating')}
    </Container>
  );
};
