import React, { useContext, useState, useEffect } from 'react';
import { CalendarContext } from './Contexts/CalendarContext';
import dayjs from 'dayjs';
import { DailyPlan, DailyPlanStatus } from '../Api/Dto/CommonDto';
import { hasItem } from '../Common/ArrayHelper';
import { CalendarOverviewContext } from './Contexts/CalendarOverviewContext';
import { ShowMobileThingsContext } from './Contexts/ShowMobileThingsContext';
import { MobileSubNavigation, MobileNavButton, MobileNavLink } from '../Common/MobileSubNavigation';

interface Props {
  plan: DailyPlan<Date> | undefined;
}

export const CalendarMobileNavigation = ({ plan }: Props) => {
  const { currentDate } = useContext(CalendarContext);
  const { isExpanded, calendarOverviewActions } = useContext(CalendarOverviewContext);
  const { showMobileThings, setShowMobileThings } = useContext(ShowMobileThingsContext);

  const [prevDayLink, setPrevDayLink] = useState<string>('#');
  const [nextDayLink, setNextDayLink] = useState<string>('#');

  useEffect(() => {
    const date = dayjs(new Date(currentDate.year, currentDate.month, currentDate.day || 1));

    setPrevDayLink(`/calendar/${date.subtract(1, 'day').format('YYYY/M/D')}`);
    setNextDayLink(`/calendar/${date.add(1, 'day').format('YYYY/M/D')}`);
  }, [currentDate]);

  useEffect(() => {
    showMobileThings === true && setShowMobileThings(false);
  }, [isExpanded]);

  return (
    <MobileSubNavigation isExpanded={isExpanded}>
      {isExpanded
        ? (
          <>
            {showMobileThings === true && (
              <>
                <MobileNavButton onClick={() => setShowMobileThings(false)} className="material-icons">undo</MobileNavButton>
              </>
            )}
            {showMobileThings === false && (
              <>
                <MobileNavButton onClick={() => setShowMobileThings(true)} className="material-icons">add</MobileNavButton>
                <MobileNavButton onClick={() => calendarOverviewActions.decrease()} className="material-icons">close</MobileNavButton>
              </>
            )}
          </>
        )
        : (
          <>
            <MobileNavLink to={prevDayLink} className="material-icons">chevron_left</MobileNavLink>
            {plan !== undefined && hasItem<DailyPlanStatus>([DailyPlanStatus.ACTIVE, DailyPlanStatus.DRAFT, DailyPlanStatus.PREPARED], plan.status) && (
              <MobileNavButton onClick={() => calendarOverviewActions.expand()} className="material-icons">edit</MobileNavButton>
            )}
            <MobileNavLink to={nextDayLink} className="material-icons">chevron_right</MobileNavLink>
          </>
        )}
      
    </MobileSubNavigation>

  )
};
