import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { CALENDAR_OVERVIEW_WIDTH, CALENDAR_TRANSFORM_LENGTH } from './common';
import { Color, ZIndexLevel, MediaQuery } from '../Common/Styles';
import { CalendarOverviewContent } from './CalendarOverviewContent';
import { CalendarOverviewContext } from './Contexts/CalendarOverviewContext';
import { CalendarOverviewEditCloseButton } from './CalendarOverviewEditCloseButton';
import { CalendarOverviewHeader } from './CalendarOverview/CalendarOverviewHeader';
import { CalendarThingsHoc } from './Hoc/CalendarThingsHoc';

interface ContainerProps {
  isExpanded: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    [MediaQuery.tableDesktop]: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: CALENDAR_OVERVIEW_WIDTH,
      zIndex: ZIndexLevel.calendarOverview,
      backgroundColor: Color.paleGrey,
      transition: 'transform, .3s',
    },
    [MediaQuery.mobileOnly]: {
      width: '100%',
      height: '100%',
    },
    
    display: 'flex',
    flexDirection: 'column',
    
  },
  ({ isExpanded }) => ({
    transform: `translate3d(${isExpanded ? '0' : CALENDAR_TRANSFORM_LENGTH}, 0, 0)`,
  }),
);

export const CalendarOverview = () => {
  const { isExpanded } = useContext(CalendarOverviewContext);
  
  return (
    <Container isExpanded={isExpanded}>
      <CalendarThingsHoc>
        <CalendarOverviewHeader />
        <CalendarOverviewEditCloseButton />
        <CalendarOverviewContent />
      </CalendarThingsHoc>
    </Container>
  );
}