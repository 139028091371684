import { GoodBadGenerator, Priority } from './GoodBadGenerator';

export class AdvicesGenerator extends GoodBadGenerator {
  public generate(): void {
    this.rateConsistency();
    this.rateEfficiency();
    this.rateBacklog();
    this.rateTimeRemain();
  }

  private rateConsistency() {
    if (this.consistency < 25) {
      this.add(this.t("You need to be more consistent. Plan every day. It's highly important."), Priority.IMPORTANT);
    } else if (this.consistency < 50) {
      this.add(this.t("You're getting more and more consistent in your plans. Try to plan every day to have better results."), Priority.GOOD_TO_KNOW);
    }
  }

  private rateEfficiency() {
    const { tasksDone, tasksQnt } = this.thingsSummary;
    const tasksToDo = tasksQnt - tasksDone;

    if (this.efficiency === 0) {
      this.add(
        tasksDone === 0
          ? tasksQnt > 2
            ? this.replaceQnt(this.t("You haven't finished any thing. Plan {qnt} things tomorrow. It should help you do more."), tasksQnt - 1)
            : this.t("You haven't finished any thing. It's crucial to complete at least one.")
          : tasksToDo > 2
            ? this.replaceQnt(this.t("Less is better. Plan tomorrow no more than {qnt} things and try complete them all."), tasksQnt - 1)
            : this.replaceQnt(this.t("You need to focus. Complete at least {qnt} things tomorrow."), tasksDone + 1),
        Priority.CRITICAL
      );
    }
    else if (this.efficiency < 25) {
      this.add(this.replaceQnt(this.t("Try to complete {qnt} things tomorrow."), tasksDone + 1), Priority.IMPORTANT);
    } else if (this.efficiency < 50) {
      this.add(this.t("You're on good way. Try to complete one more thing tomorrow"), Priority.GOOD_TO_KNOW);
    }
  }

  private rateBacklog() {
    const { tasksQnt } = this.thingsSummary;

    if (this.factors.d > 0.9) {
      return;
    }

    if (this.factors.d < 0.2) {
      this.add(this.t("You should do more things every day. Otherwise you'll have too many on your mind soon."), Priority.IMPORTANT);
    } else if (this.factors.d < 0.4) {
      this.add(this.replaceQnt(this.t("Try to do {qnt} things tomorrow. It will give you better result over the month."), tasksQnt + 1), Priority.IMPORTANT);
    } else if (this.factors.d < 0.7) {
      this.add(this.t("There not enough things you can do in next weeks. Maybe you should create them more in your drawers?"), Priority.GOOD_TO_KNOW);
    } else if (this.factors.d >= 0.7) {
      this.add(
        tasksQnt > 3
          ? this.replaceQnt(this.t("You are really fast! Calm down. You don't need to do more than {qnt} things. Enjoy more your free time!"), tasksQnt - 1)
          : this.t("You should add more things to your drawers. Otherwise your drawers will be soon empty."),
        Priority.IMPORTANT,
      );
    }
  }

  private rateTimeRemain() {
    if (this.factors.c < 0.2) {
      this.add(this.t("You should create your plan at least in the morning. Otherwise you won't have enough time to get things done."), Priority.CRITICAL);
    } else if (this.factors.c < 0.5) {
      this.add(this.t("It's better to plan a day before. Try it."), Priority.IMPORTANT)
    } else if (this.factors.c < 0.95) {
      this.add(this.t("Plan your next day today. Review and finish your plan in the evening. It gives the best results."), Priority.GOOD_TO_KNOW);
    }
  }
}
