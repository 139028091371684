import React, { ReactNode, useContext, useState } from 'react';
import glamorous from 'glamorous';
import { Color, Transition, MediaQuery } from '../Styles';
import { TopBar } from './Default/TopBar';
import { MainMenu } from './Default/MainMenu';
import { TemplateContext } from '../Contexts/TemplateContext';
import { MobileMenu } from './Default/MobileMenu';
import { ToggleContext, SetToggleContext, ToggleThingsState } from '../Contexts/ToggleContext';
import { GlobalModal } from '../GlobalModal';

interface Props {
  children: ReactNode;
}

interface ContainerProps {
  isHidden: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    backgroundColor: Color.paleGrey,
    position: 'absolute',
    width: '100%',
    height: '100%',
    ...Transition([
      ['opacity', .3],
      ['visibility', .3],
    ]),
  },
  ({ isHidden }) => ({
    opacity: isHidden ? 0 : 1,
    visibility: isHidden ? 'hidden' : 'visible',
  }),
);

const Content = glamorous.section({
  height: '100%',
  width: '100%',
  display: 'flex',

  [MediaQuery.mobileOnly]: {
    flexDirection: 'column',
  },
});

const PageContent = glamorous.div({
  flex: '1 1 auto',
  overflowX: 'hidden',
});

export const DefaultTheme = React.memo((({ children }: Props) => {
  const { isTemplateHidden } = useContext(TemplateContext);

  const toggleThingsState = useState<ToggleThingsState>(ToggleThingsState.ADD_NEW_THING);

  return (
    <Container isHidden={isTemplateHidden}>
      <ToggleContext.Provider value={SetToggleContext(toggleThingsState)}>
        <TopBar />
        <Content>
          <MainMenu />
          <PageContent>
            {children}
          </PageContent>
          <MobileMenu />
        </Content>
      </ToggleContext.Provider>
      <GlobalModal />
    </Container>
  );
}))