import { CalendarFormat, CALENDAR_MONTHS } from './common';
import { MonthJS, MonthRaw } from '../Common/Interface';
import { TranslateFunction } from '../Common/Contexts/I18nContext';

const makeUnknownObject = () => ({
  full: '???',
  short: '???'
});

export const renderDate = (
  date: Date,
  dateFormat: CalendarFormat,
  isShort: boolean = false,
  t: TranslateFunction,
  variant: 'month-of' | 'month-alone',
): string => {
  const month = date.getMonth();

  switch (dateFormat) {
    case CalendarFormat.MONTH_YEAR:
      return t(
        isShort
          ? (CALENDAR_MONTHS[month] || makeUnknownObject()).short
          : (CALENDAR_MONTHS[month] || makeUnknownObject()).full
      , variant) + ' ' + date.getFullYear();
    case CalendarFormat.MONTH:
      return t(isShort
        ? (CALENDAR_MONTHS[month] || makeUnknownObject()).short
        : (CALENDAR_MONTHS[month] || makeUnknownObject()).full
      , variant);
    default:
      throw new Error('Calendar::renderDate - incorrect date format');
  }
}

export const toMonthJS = (month: number | MonthRaw): MonthJS => (
  month - 1 as MonthJS
);

export const toMonthRaw = (month: number | MonthJS): MonthRaw => (
  month + 1 as MonthRaw
);
