import React, { useContext, useEffect, useState, useReducer } from 'react';
import { PageLayout } from '../Common/PageLayout';
import { DrawerContext } from '../Common/Contexts/DrawerContext';
import glamorous from 'glamorous';
import { H1 } from '../Common/Typography';
import { PageLayoutTheme } from '../Common/Interface';
import { ThingClient } from '../Api';
import { AxiosError } from 'axios';
import { Drawer as DrawerEntity, DrawerStatus } from '../Api/Dto/CommonDto';
import { ThingStatus } from "../Api/Dto/ThingDto";
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import { AppStatus } from '../Common/AppStatus';
import { UserContext } from '../Common/Contexts/UserContext';
import { UserDrawerID } from './common';
import { ThingsReducer, ThingsAction } from './Reducers/ThingsReducer';
import { ThingsContext, SetThingsContext } from './Contexts/ThingsContext';
import { MediaQuery as MQ, MediaQuerySize } from '../Common/Styles';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';
import MediaQuery from 'react-responsive';
import { ThingsDesktop } from './ThingsDesktop';
import { ThingsMobile } from './ThingsMobile';
import { ThingsStatusContext, SetThingsStatusContext } from './Contexts/ThingsStatusContext';
import { TemplateContext } from '../Common/Contexts/TemplateContext';
import useI18n from '../Common/Hooks/useI18n';

const Container = glamorous.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  height: '100%',

  [MQ.mobileOnly]: {
    paddingRight: '15px',
  }
});

const DrawerContainer = glamorous.div({
  flex: '1',
});

export const Things = () => {
  const { t } = useI18n();
  const { drawers } = useContext(DrawerContext);
  const { user, userActions } = useContext(UserContext);
  const { isTemplateHidden, templateActions } = useContext(TemplateContext);

  const [appStatus, setAppStatus] = useAppStatus();
  const [drawersStatus, setDrawersStatus] = useAppStatus();
  const [userDrawerStatus, setUserDrawerStatus] = useAppStatus();

  const [things, dispatch] = useReducer(ThingsReducer, {});

  const [defaultDrawer] = useState<DrawerEntity<Date>>({
    id: UserDrawerID,
    name: userActions.getFullName(),
    createdAt: user.createdAt,
    updatedAt: user.updatedAt,
    status: DrawerStatus.ACTIVE,
    organizationId: UserDrawerID,
  });

  const fetchData = () => Promise.all([
    ThingClient
      .getList({
        filter: {
          status: ThingStatus.TO_DO,
          withProject: 'false',
        },
      })
      .then(data => {
        dispatch({
          type: ThingsAction.SET,
          value: {
            drawer: defaultDrawer,
            things: data,
          }
        });
        setUserDrawerStatus(AppStatus.READY);
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 404) {
          dispatch({
            type: ThingsAction.SET,
            value: {
              drawer: defaultDrawer,
              things: [],
            }
          });
          setUserDrawerStatus(AppStatus.READY);
        } else {
          setUserDrawerStatus(AppStatus.FAILED);
        }
      }),
    ThingClient
      .getAll()
      .then(data => {
        dispatch({
          type: ThingsAction.SET_ALL,
          values: data,
        });
        setDrawersStatus(AppStatus.READY);
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 404) {
          setDrawersStatus(AppStatus.READY);
        } else {
          setDrawersStatus(AppStatus.FAILED);
        }
      }),
  ]);

  useEffect(() => {
    isTemplateHidden && templateActions.show();

    fetchData();
  }, []);

  useEffect(() => {
    userDrawerStatus === AppStatus.READY
    && drawersStatus === AppStatus.READY
    && setAppStatus(AppStatus.READY);
  }, [userDrawerStatus, drawersStatus]);

  return (
    <DefaultTheme>
      <PageLayout theme={PageLayoutTheme.NO_BOTTOM_RIGHT_PADDING} scrollable={false}>
        <Container>
          <ThingsStatusContext.Provider value={SetThingsStatusContext(appStatus)}>
            <ThingsContext.Provider value={SetThingsContext(things, dispatch)}>
              <H1 showOnMobile={false}>{t('Things')}</H1>
              <DrawerContainer>
                <MediaQuery minWidth={MediaQuerySize.minTabletSize}>
                  <ThingsDesktop drawers={[defaultDrawer, ...drawers]} />
                </MediaQuery>
                <MediaQuery maxWidth={MediaQuerySize.maxMobileSize}>
                  <ThingsMobile drawers={[defaultDrawer, ...drawers]} />
                </MediaQuery>
              </DrawerContainer>
            </ThingsContext.Provider>
          </ThingsStatusContext.Provider>
        </Container>
      </PageLayout>
    </DefaultTheme>
  );
};
