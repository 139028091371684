import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAppStatus } from '../../Common/Hooks/useAppStatus';
import { AppStatus } from '../../Common/AppStatus';
import { SetNewPasswordLoading } from './SetNewPasswordLoading';
import { AuthTokenClient } from '../../Api';
import { SetNewPasswordFailed } from './SetNewPasswordFailed';
import { SetNewPasswordSuccess } from './SetNewPasswordSuccess';

interface MatchProps {
  token?: string;
}

const _SetNewPassword = ({ match }: RouteComponentProps<MatchProps>) => {
  const [appStatus, setAppStatus] = useAppStatus();

  const verifyToken = (token: string) => AuthTokenClient
    .verifyResetPasswordToken(token)
    .then(() => setAppStatus(AppStatus.READY))
    .catch(() => setAppStatus(AppStatus.FAILED));

  useEffect(() => {
    verifyToken(match.params.token || '');
  });

  switch (appStatus) {
    case AppStatus.READY:
      return <SetNewPasswordSuccess token={match.params.token || ''} />
    case AppStatus.FAILED:
      return <SetNewPasswordFailed />
    case AppStatus.LOADING:
    default:
      return <SetNewPasswordLoading />
  }
};

export const SetNewPassword = withRouter(_SetNewPassword);
