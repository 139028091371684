import { createContext, Dispatch } from 'react';

type ExpandState = [boolean, Dispatch<boolean>];

interface CalendarOverviewActionsProps {
  expand: () => void;
  decrease: () => void;
  toggleExpand: () => void;
}

interface CalendarOverviewContextProps {
  isExpanded: boolean
  calendarOverviewActions: CalendarOverviewActionsProps;
}

const calendarOverviewActions = ([isExpanded, setIsExpanded]: ExpandState): CalendarOverviewActionsProps => ({
  expand: () => !isExpanded && setIsExpanded(true),
  decrease: () => isExpanded && setIsExpanded(false),
  toggleExpand: () => setIsExpanded(!isExpanded),
});

export const SetCalendarOverviewContext = (
  expandState: ExpandState,
): CalendarOverviewContextProps => ({
  isExpanded: expandState[0],
  calendarOverviewActions: calendarOverviewActions(expandState),
});

export const CalendarOverviewContext = createContext<CalendarOverviewContextProps>({} as unknown as CalendarOverviewContextProps);