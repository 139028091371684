import React, { useContext } from 'react';
import { CircularProgressBar } from '../../Common/CircularProgressBar';
import { DailyPlan } from '../../Api/Dto/CommonDto';
import { Color, Size } from '../../Common/Styles';
import glamorous from 'glamorous';
import { MonthlyPlansContext } from '../Contexts/MonthlyPlansContext';

interface Props {
  plan: DailyPlan<Date>;
}

const Container = glamorous.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '& > div': {
    margin: 'auto',
  }
});

const Rating = glamorous.span({
  position: 'absolute',
  textAlign: 'center',
  top: 'calc(50% - 11px)',
  width: '100%',
});

export const GridDayPlanCompleted = ({ plan }: Props) => {
  const { monthlyPlansActions } = useContext(MonthlyPlansContext);

  return (
    <Container>
      <Rating>{plan.rating}</Rating>
      <CircularProgressBar
        percentage={monthlyPlansActions.calculatePercentage(plan)}
        size={Size.small}
        pathColor={Color.appleGreen}
        trailColor={Color.cherryRed}
      />
    </Container>
  );
};
