import React, { useState, useEffect } from 'react';
import { Thing, DailyPlanStatus } from '../../Api/Dto/CommonDto';
import { CompletedPlanThingElement } from '../common/CompletedPlanThingElement';
import { ThingStatus } from '../../Api/Dto/ThingDto';
import { ThingStatusSeparator } from '../../Common/Thing/ThingStatusSeparator';

interface Props {
  things: Thing<Date>[];
}

export const CompletedPlanThings = ({ things }: Props) => {
  const [thingsToDo, setThingsToDo] = useState<Thing<Date>[]>([]);
  const [thingsDone, setThingsDone] = useState<Thing<Date>[]>([]);

  useEffect(() => {
    setThingsDone(
      things.filter(thing => thing.status === ThingStatus.DONE || thing.status === ThingStatus.CLOSED)
    );
    setThingsToDo(
      things.filter(thing => thing.status === ThingStatus.TO_DO)
    );
  }, [things]);

  return (
    <>
      {thingsDone.length > 0 && <ThingStatusSeparator
        outlined={false}
        omitMargin={true}
        status={ThingStatus.DONE}
        quantity={thingsDone.length}
      />}
      {thingsDone.map((thing, key) => <CompletedPlanThingElement
          key={thing.id}
          thing={thing}
          isLastElement={key === thingsDone.length - 1}
      />)}
      {thingsToDo.length > 0 && <ThingStatusSeparator
        outlined={false}
        omitMargin={true}
        status={ThingStatus.TO_DO}
        planStatus={DailyPlanStatus.COMPLETED}
        quantity={thingsToDo.length}
      />}
      {thingsToDo.map((thing, key) => <CompletedPlanThingElement
          key={thing.id}
          thing={thing}
          isLastElement={key === thingsToDo.length - 1}
      />)}
    </>
  );
}
