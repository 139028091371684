export default {
  '3 active drawers': '3 aktywne szufladki',
  '200 active drawers': '200 aktywnych szufladek',
  '20 things to do': '20 rzeczy do zrobienia',
  '1000 things to do': '1000 rzeczy do zrobienia',
  '(other)': '(inne)',
  'Activate and continue': 'Aktywuj i kontynuuj',
  'Add': 'Dodaj',
  'Add things': 'Dodaj rzeczy',
  'Add new thing': 'Dodaj nową rzecz',
  'Advices': 'Porady',
  'Back to Log in': 'Przejdź do logowania',
  'Calendar': 'Kalendarz',
  'Cancel': 'Anuluj',
  'Change': 'Zmień',
  'Change language': 'Zmień język',
  'Change password': 'Zmień hasło',
  'Change personal data': 'Zmień swoje dane',
  'Close': 'Zamknij',
  'Consistency': 'Sumienność',
  'Continue': 'Kontynuuj',
  'Create': 'Stwórz',
  'Created': 'Utworzono',
  'Create account': 'Stwórz konto',
  'Create a drawer to group them.': 'Stwórz szufladkę, by je pogrupować',
  'Create a new drawer': 'Stwórz nową szufladkę',
  'Create day plan': 'Stwórz plan dnia',
  'Current password': 'Obecne hasło',
  'day ago': 'dzień temu',
  'days ago': 'dni temu',
  'Day plan': 'Plan dnia',
  'done': 'ukończonych',
  'Done': 'Ukończonych',
  "during your day, you'll see here some useful hints that will help you be more effective.": 'Podczas Twojego dnia, będziesz zauważał co jakiś czas wskazówki, które pomogą Ci zwiększyć Twoją produktywność.',
  'Edit': 'Edytuj',
  "Edit a drawer's name": 'Zmieniaj nazwę szufladki',
  "Edit a thing's title": 'Zmieniaj nazwę rzeczy',
  'Edit plan': 'Edytuj plan',
  'Efficiency': 'Produktywność',
  'Enter your email address that you used to register': 'Wpisz swój adres e-mail użyty podczas rejestracji',
  'Failed': 'Nieudanych',
  'failed': 'nieudanych',
  'Finish plan': 'Zakończ plan',
  'First name': 'Imię',
  'Fixed some minor bugs': 'Poprawione mniejsze błędy',
  'Give us feedback': 'Daj nam feedback',
  'Glad to have you onboard': 'Miło Cię mieć na pokładzie',
  'Good afternoon': 'Dzień dobry',
  'Good evening': 'Dobry wieczór',
  'Good morning': 'Dzień dobry',
  'Go to': 'Przejdź do',
  'Go to Calendar': 'Przejdź do kalendarza',
  'Hints': 'Wskazówki',
  "Hold on... We're calculating your plan": 'Poczekaj... Właśnie obliczamy Twój plan',
  'I agree with': 'Akceptuję',
  'I already have account': 'Mam już konto',
  'I forgot password': 'Zapomniałem hasła',
  'If you have something on mind, or you noticed any issues feel free to share them with us.': 'Jeżeli coś masz na myśli lub zauważyłeś jakiekolwiek problemy, śmiało podziel się z nami tym.',
  "It's a great time to plan something and make things done": 'To najwyższy czas, aby zaplanować coś nowego',
  "It's hard to manage time when you have so many things.": 'Ciężko zarządzać czasem mając tak wiele rzeczy na głowie.',
  'Improved calendar usability': 'Poprawiona obsługa kalendarza',
  'Improved performance': 'Poprawiona wydajność',
  'Just click and go': 'Po prostu kliknij i przejdź dalej',
  'Just write down a message below.': 'Wystarczy, że napiszesz i wyślesz nam wiadomość.',
  "Let's plan for tomorrow": 'Zaplanujmy jutro',
  "Let's Tau!": 'ZaTauPlanujmy!',
  'Log in': 'Zaloguj się',
  'Log out': 'Wyloguj',
  'Main Menu': 'Menu główne',
  'Message': 'Wiadomość',
  'Move': 'Przenieś',
  'New features!': 'Nowe funkcje!',
  'New password': 'Nowe hasło',
  'Nah, go back': 'Nie, może później',
  'No additional data needed': 'Nie będziemy Cię pytać o żadne, dodatkowe dane',
  'No credit card required': 'Nie wymagamy karty płatniczej',
  'No day plan': 'Brak planu dnia',
  'No hints yet': 'Brak wskazówek',
  'No payments': 'Nie wymagamy płatności',
  'No plan found for this day': 'Nie znaleźliśmy żadnego planu dla tego dnia',
  'No things to do': 'Brak rzeczy do zrobienia',
  'No things in the plan': 'Brak rzeczy w planie',
  'No things planned this day': 'Brak rzeczy zaplanowanych na ten dzień',
  'Overview': 'Przegląd',
  'Password changed. You can log in with your new credentials.': 'Hasło zmienione. Możesz już się zalogować nowymi danymi.',
  'Personal data changed successfully': 'Twoje dane zostały zaktualizowane',
  'Plan details': 'Szczegóły planu',
  'Plan it now.': 'Zaplanuj go teraz.',
  'Plan your next day': 'Zaplanuj kolejny dzień',
  'Please update your data in order to activate account.': 'Zaktualizuj swoje dane, byśmy mogli zaktywować Twoje konto.',
  'Please check for an email from us and click on the included link': 'Sprawdź swoją skrzynkę e-mail i kliknij na dołączony przez nas link',
  'Please wait': 'Proszę czekać',
  'Praises': 'Sukcesy',
  'Quickly add things in the plan component': 'Szybko dodawaj rzeczy w komponencie planu',
  'Rating': 'Ocena',
  'Request token again': 'Wyślij nowy token',
  'Reset password': 'Zresetuj hasło',
  'Save plan': 'Zapisz plan',
  'selected': 'zaznaczone',
  'Select where would you like to move things': 'Wybierz, gdzie chcesz przenieść swoje rzeczy',
  'Send': 'Wyślij',
  'Send activation link': 'Wyślij link aktywacyjny',
  'Send another message': 'Wyślij kolejną wiadomość',
  "Set up your password and you're ready to go!": 'Ustaw swoje hasło i możesz od razu zaczynać!',
  'Share your feedback with us': 'Podziel się z nami swoją opinią',
  'Simplified create plan flow': 'Uproszczone tworzenie planów',
  'Surname': 'Nazwisko',
  'Submit': 'Wyślij',
  'Take off those limits and try Tauplan PRO Trial for free until': 'Znieś obecne limity i wypróbuj Tauplan PRO za darmo aż do',
  'Tap on a thing to add to the plan': 'Dotknij rzeczy, by dodać ją do planu',
  'Tap on a thing on the right side to add it here': 'Dotknij rzeczy po prawej stronie aby dorzucić tutaj',
  'Tap to create a plan': 'Dotknij, by stworzyć plan',
  'Tap to remove': 'Dotknij, by usunąć',
  "terms and conditions": 'regulamin',
  'There are no things in this plan': 'Nie masz żadnych rzeczy w tym planie',
  'Today': 'Dziś',
  'today': 'dziś',
  'toggle': 'przełącz',
  'Thank you for sharing your feedback with us. We value every word from you.': 'Dziękujemy za podzielenie się opinią. Cenimy każde, Twoje słowo.',
  'thing': 'rzecz',
  'Things': 'Rzeczy',
  'things': 'rzeczy',
  'things done': 'ukończonych rzeczy',
  'things planned': 'zaplanowanych rzeczy',
  'things ready to start': 'rzeczy gotowych do rozpoczęcia',
  'to activate account': 'by zaktywować swoje konto',
  'to do': 'do zrobienia',
  'to set a new password': 'by ustawić nowe hasło',
  "Today's plan is easier to be noticed": 'Łatwiej dostępny dzisiejszy plan',
  'Update': 'Zaktualizuj',
  'Week': 'Tydzień',
  "We'll reply directly to your e-mail.": 'A my odpowiemy Ci bezpośrednio na Twój adres e-mail',
  "We'll send you an email with a link to activate your account": 'Wyślemy Ci e-mail z linkiem aktywacyjnym',
  "We'll send you an email with a link to reset your password": 'Wyślemy Ci e-mail z linkiem do zresetowania hasła',
  "We've sent you a message to": 'Wysłaliśmy Ci wiadomość na ',
  'We are verifying the token': 'Weryfikujemy Twój token',
  'We are verifying your account': 'Weryfikujemy Twoje konto',
  "We can't calculate unfinished plan": 'Nie możemy obliczyć nieukończonego planu',
  'Welcome to the Tauplan PRO': 'Witaj w Tauplan PRO',
  "What's new?": 'Co nowego?',
  'Work balance': 'Wyważenie pracy',
  'Write a new thing here': 'Wpisz nazwę nowej rzeczy',
  'Write here a name for your drawer': 'Wpisz nazwę dla swojego szufladki',
  'Write here to add new thing': 'Wpisz rzecz, którą chcesz dodać',
  'With Tauplan PRO you can do as much as you want. We work on other features as well.': 'Dzięki Tauplan PRO, możesz tworzyć tak wiele jak tylko chcesz. Pracujemy stale nad nowymi funkcjonalnościami.',
  "Yeah, let's try": 'Jasne, spróbujmy',
  "you already finished this plan. It's the best time to plan tomorrow right now.": 'zakończyłeś już ten plan. To najlepszy czas, aby zaplanować jutrzejszy dzień.',
  'You can request a new token again.': 'Możemy zawsze stworzyć Ci kolejny token',
  'You can try reset your password again.': 'Możesz spróbować zresetować swoje hasło ponownie.',
  "you don't have any plans": 'nie masz żadnych planów',
  "You don't have plan for this day.": 'Nie masz żadnego planu na ten dzień.',
  'You have reached limit of maximum active drawers': 'Przekroczyłeś limit maksymalnej liczby aktywnych szufladek',
  'You have reached limit of maximum things to do': 'Przekroczyłeś limit maksymalnej liczby rzeczy do zrobienia',
  'Your account has been verified.': 'Twoje konto zostało zweryfikowane.',
  'Your current limits': 'Twoje, obecne limity',
  'Your day': 'Twój dzień',
  'your e-mail address': 'twój adres e-mail',
  'Your e-mail address': 'Twój adres e-mail',
  'Your first name': 'Twoje imię',
  'Your plan': 'Twój plan',
  'Your profile': 'Twój profil',
  'Your token is invalid or expired.': 'Twój token jest nieprawidłowy lub już wygasnął.',
  'Your trial subscription is active until': 'Twoja, próbna subskrypcja jest aktywna do',
  'Your surname': 'Twoje nazwisko',

  // Calendar
  'Monday': 'Poniedziałek', 
  'Mon': 'Pon',
  'Tuesday': 'Wtorek', 
  'Tue': 'Wto',
  'Wednesday': 'Środa',
  'Wed': 'Śro',
  'Thursday': 'Czwartek',
  'Thu': 'Czw',
  'Friday': 'Piątek',
  'Fri': 'Pią',
  'Saturday': 'Sobota',
  'Sat': 'Sob',
  'Sunday': 'Niedziela',
  'Sun': 'Nie',

  // Variants
  'month-of': {
    'January': 'Stycznia',
    'Jan': 'Sty',
    'February': 'Lutego',
    'Feb': 'Lut',
    'March': 'Marca',
    'Mar': 'Mar',
    'April': 'Kwietnia',
    'Apr': 'Kwi',
    'May': 'Maja',
    'June': 'Czerwca',
    'Jun': 'Cze',
    'July': 'Lipca',
    'Jul': 'Lip',
    'August': 'Sierpnia',
    'Aug': 'Sie',
    'September': 'Września',
    'Sep': 'Wrz',
    'October': 'Października',
    'Oct': 'Paź',
    'November': 'Listopada',
    'Nov': 'Lis',
    'December': 'Grudnia',
    'Dec': 'Gru',
  },
  'month-alone': {
    'January': 'Styczeń',
    'Jan': 'Sty',
    'February': 'Luty',
    'Feb': 'Lut',
    'March': 'Marzec',
    'Mar': 'Mar',
    'April': 'Kwiecień',
    'Apr': 'Kwi',
    'May': 'Maj',
    'June': 'Czerwiec',
    'Jun': 'Cze',
    'July': 'Lipiec',
    'Jul': 'Lip',
    'August': 'Sierpień',
    'Aug': 'Sie',
    'September': 'Wrzesień',
    'Sep': 'Wrz',
    'October': 'Październik',
    'Oct': 'Paź',
    'November': 'Listopad',
    'Nov': 'Lis',
    'December': 'Grudzień',
    'Dec': 'Gru',
  },
  'rating': {
    'Maybe tomorrow?': 'Może jutro będzie lepiej?',
    'Focus. You can do it!': 'Skup się. Potrafisz lepiej!',
    'You can do better!': 'Potrafisz lepiej!',
    'Just keep going!': 'Działaj dalej!',
    'Just one more step!': 'O krok od sukcesu!',
    'Well done!': 'Dobra robota!',
    'Really good!': 'Świetna robota!',
    'You\'re doing great!': 'Idzie Ci bardzo dobrze!',
    'Brilliant!': 'Znakomicie!',
    'Almost perfect!': 'O krok od perfekcji!',
    'Outstanding!': 'Wybitny wynik!',
  },
  'rating-advices': {
    "You're planning every day consistently. You are awesome!": 'Planujesz regularnie każdy dzień. Jesteś niesamowity!',
    "You're very consistent in planning. Keep going!": 'Jesteś bardzo konsekwentny w planowaniu. Działaj tak dalej!',
    "You know daily plans are very important. Just do them every day.": 'Rozumiesz jak ważne są plany dnia. Rób dokładnie tak samo dalej.',
    "You did outstanding job today! Keep the tempo and your dreams will come true.": 'Wykonałeś niesamowitą pracę dzisiaj! Utrzymuj tempo, a na pewno spełnią się twoje marzenia.',
    "You're very efficient in dealing with your things": 'Jesteś bardzo produktywny w pracy nad swoimi rzeczami',
    "You're on good way with your efficiency.": 'Jesteś na dobrej drodze ze swoją produktywnością',
    "You had perfectly balanced plan. You rock!": 'Idealnie wyważony plan. Rządzisz!',
    "Your plan is balanced very well. Weekly reviews will improve your planning.": 'Twój plan jest bardzo dobrze wyważony. Możesz to robić jeszcze lepiej przeglądając swoją całą pracę raz w tygodniu.',
    "You plan a day before and review your job after it's done. This is what we call a perfection.": 'Planujesz dzień przed, pracę rozliczasz zaraz po jej zakończeniu. To jest to, co nazywamy perfekcją.',
    "You're extraordinary organized. Remember to create plan a day before and review it when a job is done": 'Jesteś wybitnie zorganizowany. Pamiętaj, aby planować dzień przed, a plany zamykać zaraz po ukończeniu pracy.',
    "You need to be more consistent. Plan every day. It's highly important.": 'Musisz popracować nad sumiennością. Planuj każdego dnia. To bardzo istotne.',
    "You're getting more and more consistent in your plans. Try to plan every day to have better results.": 'Stajesz się coraz bardziej sumienny. Staraj się planować każdego dnia by poprawić wyniki.',
    "You haven't finished any thing. Plan {qnt} things tomorrow. It should help you do more.": 'Nie ukończyłeś dziś żadnej rzeczy. Zaplanuj {qnt} rzeczy jutro. Powinno Ci to bardziej pomóc.',
    "You haven't finished any thing. It's crucial to complete at least one.": 'Nie ukończyłeś dziś żadnej rzeczy. To bardzo istotne, by zakończyć choć jedną',
    "Less is better. Plan tomorrow no more than {qnt} things and try complete them all.": 'Mniej znaczy lepiej. Zaplanuj jutro nie więcej niż {qnt} rzeczy i postaraj się ukończyć je wszystkie.',
    "You need to focus. Complete at least {qnt} things tomorrow.": 'Musisz się skoncentrować. Ukończ jutro przynajmniej {qnt} rzeczy.',
    "Try to complete {qnt} things tomorrow.": 'Spróbuj ukończyć jutro {qnt} rzeczy.',
    "You're on good way. Try to complete one more thing tomorrow": 'Jesteś na dobrej drodze. Postaraj się ukończyć o jedna rzecz więcej niż dziś.',
    "You should do more things every day. Otherwise you'll have too many on your mind soon.": 'Powinieneś wykonywać więcej rzeczy każdego dnia. Inaczej będziesz mieć ich niebawem zbyt wiele na głowie.',
    "Try to do {qnt} things tomorrow. It will give you better result over the month.": 'Spróbuj ukończyć {qnt} rzeczy jutro. Da Ci to lepsze rezultaty w skali miesiąca.',
    "There not enough things you can do in next weeks. Maybe you should create them more in your drawers?": 'Nie masz wystarczającej liczby rzeczy na zbliżające się tygodnie. Może warto powinieneś stworzyć ich więcej do Twoich szufladek?',
    "You are really fast! Calm down. You don't need to do more than {qnt} things. Enjoy more your free time!": 'Jesteś bardzo szybki! Nie musisz się tak przepracowywać. Nie musisz ich robić więcej niż {qnt} rzeczy dziennie. Ciesz się wolnym czasem!',
    "You should add more things to your drawers. Otherwise your drawers will be soon empty.": 'Powinieneś dodać więcej rzeczy do swoich szufladek. Inaczej szybko zagości u nich pustka.',
    "You should create your plan at least in the morning. Otherwise you won't have enough time to get things done.": 'Powinieneś stworzyć swój plan najpóźniej rano. Inaczej nie będziesz miał wystarczająco czasu na pracę.',
    "It's better to plan a day before. Try it.": 'Lepiej jest planować dzień wcześniej. Spróbuj sam.',
    "Plan your next day today. Review and finish your plan in the evening. It gives the best results.": 'Zaplanuj jutro dzisiaj. Rozliczaj się z planu już pod wieczór. To da Ci lepsze wyniki.',
  },
};
