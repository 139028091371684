import glamorous from 'glamorous';
import { Color, MediaQuery, Font, Unselectable } from './Styles';

interface H1Props {
  showOnMobile?: boolean;
}

export const H1 = glamorous.h1<H1Props>(
  {
    color: Color.iris,
    fontSize: '42px',
    fontWeight: 600,
    letterSpacing: '-0.8px',
    lineHeight: 1.18,
    margin: '0 0 30px',
    padding: 0,
    ...Unselectable,
  },
  ({ showOnMobile = true }) => ({
    [MediaQuery.smallHeight]: {
      display: showOnMobile ? 'block' : 'none',
      fontSize: '24px',
    },
    [MediaQuery.mobileOnly]: {
      display: showOnMobile ? 'block' : 'none',
      fontSize: '24px',
      margin: '0 0 15px',
    },
  }),
);

export const H2 = glamorous.h2({
  color: Color.darkGreyBlue,
  padding: 0,
  fontWeight: 500,
  fontSize: '21px',
  margin: '0 0 30px 0',
});

export const H3 = glamorous.h3({
  color: Color.twilight,
  fontWeight: 500,
  fontSize: '20px',
  margin: '0 0 15px 0',
})

export const Small = glamorous.small({
  color: Color.dark74,
  fontSize: '14px',
  fontFamily: Font.condensed,
  letterSpacing: '-.3px',
  fontWeight: 400,
  lineHeight: '24px',
});
