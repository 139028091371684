import glamorous from 'glamorous';
import { Color } from '../Styles';

interface FormLabelProps {
  hasErrors: boolean;
}

export const FormLabel = glamorous.label<FormLabelProps>(
  {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.29,
    marginBottom: '5px',
    display: 'block',
    width: '100%',
  },
  ({ hasErrors }) => ({
    color: hasErrors ? Color.cherryRed : Color.dark74,
  })
);

interface FormInfoBoxProps {
  color?: string,
  backgroundColor: string;
  isVisible: boolean;
}

export const FormInfoBox = glamorous.span<FormInfoBoxProps>(
  {
    padding: '10px 15px',
    width: '100%',
    borderRadius: '4px',
    fontSize: '14px',
    marginBottom: '15px',
  },
  ({ color = Color.white, backgroundColor, isVisible }) => ({
    color,
    backgroundColor,
    display: isVisible ? 'block' : 'none',
  }),
)
