import React, { useContext } from 'react';
import { MonthlyPlansContext } from './Contexts/MonthlyPlansContext';
import { CalendarContext } from './Contexts/CalendarContext';
import { ExistingPlan } from './CalendarOverviewPlan/ExistingPlan';
import { EmptyPlan } from './CalendarOverviewPlan/EmptyPlan';
import { CalendarOverviewContext } from './Contexts/CalendarOverviewContext';
import { CreatePlan } from './CalendarOverviewPlan/CreatePlan';

export const CalendarOverviewPlan = () => {
  const { currentDate } = useContext(CalendarContext);
  const { monthlyPlans } = useContext(MonthlyPlansContext);
  const { isExpanded } = useContext(CalendarOverviewContext);

  return (
    <>
      { monthlyPlans !== undefined && monthlyPlans[Number(currentDate.day)] !== undefined
          ? <ExistingPlan plan={monthlyPlans[Number(currentDate.day)]} />
          : isExpanded ? <CreatePlan /> : <EmptyPlan />
      }
    </>
  );
}
