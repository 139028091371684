import { AuthContainer } from '../../Common/AuthContainer';
import { AuthClient } from '../../Api/index';
import React, { useState, FormEvent } from 'react';
import { handleEntityChange } from '../../Common/FormsHelper';
import { handleErrors } from '../../Common/Forms/FormHandler';
import { ConfirmEmail, EmailType } from '../Common/AuthConfirmEmail';
import { AuthForm } from '../Common/AuthForm';
import { AuthInput } from '../Common/AuhInput';
import { Small } from '../Common/AuthTypography';
import { AuthSubmit } from '../Common/AuthSubmit';
import useI18n from '../../Common/Hooks/useI18n';

interface Entity {
  email: string;
}

export const ResendActivation = () => {
  const { t } = useI18n();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [entity, setEntity] = useState<Entity>({
    email: '',
  });
  const [errors, setErrors] = useState({
    email: [],
  });

  const handleChange = (event: FormEvent) => !isLoading && handleEntityChange(event, setEntity, entity);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    AuthClient
      .resendActivationEmail(entity.email)
      .then(() => setIsFormSubmitted(true))
      .catch(err => {
        setErrors(handleErrors(errors, err));
        setIsLoading(false);
      });
  }

  if (isFormSubmitted) {
    return <ConfirmEmail email={entity.email} type={EmailType.ACTIVATE_ACCOUNT} />;
  }

  return (
    <AuthContainer isLoading={isLoading}>
      <AuthForm method="post" onSubmit={handleSubmit}>
        <AuthInput
          name="email"
          type="email"
          placeholder="Your e-mail address"
          value={entity.email}
          errors={errors.email}
          onChange={handleChange}
        />
        <Small>
          {t('Enter your email address that you used to register')}.<br/>
          {t("We'll send you an email with a link to activate your account")}.
        </Small>
        <AuthSubmit buttonText="Send activation link" />
      </AuthForm>
    </AuthContainer>
  )
}
