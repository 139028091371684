import React, { ReactNode } from 'react';
import glamorous from 'glamorous';
import { Color, hexToRgba } from '../Styles';
import { ThingStatus } from '../../Api/Dto/ThingDto';
import { DailyPlanStatus } from '../../Api/Dto/CommonDto';

interface Props {
  children: ReactNode;
  outlined?: boolean;
  omitMargin?: boolean;
  status?: ThingStatus | null;
  planStatus?: DailyPlanStatus;
}

interface ContainerProps {
  outlined: boolean;
  omitMargin: boolean;
  status: ThingStatus | null;
  planStatus: DailyPlanStatus;
}

const statusToColor = (status: ThingStatus, planStatus: DailyPlanStatus): string => {
  switch (status) {
    case ThingStatus.TO_DO:
      return planStatus === DailyPlanStatus.COMPLETED ? Color.cherryRed : Color.iris;
    case ThingStatus.DONE:
    case ThingStatus.CLOSED:
      return Color.appleGreen;
    default:
      return Color.iris;
  }
}

const Container = glamorous.div<ContainerProps>(
  {
    padding: '5px 13px',
    borderBottom: `1px solid ${Color.silver}`,
    alignItems: 'center',
    borderRadius: '4px',
  },
  ({ outlined, omitMargin, status, planStatus }) => ({
    backgroundColor: outlined 
      ? Color.transparent
      : status === null
        ? Color.white
        : hexToRgba(statusToColor(status, planStatus), 0.75),
    margin: omitMargin ? '0' : '15px 0',
    color: outlined ? Color.dark74 : Color.white,
  })
);

export const ThingSeparator = ({
  children,
  outlined = true,
  omitMargin = false,
  status = null,
  planStatus = DailyPlanStatus.ACTIVE,
}: Props) => (
  <Container outlined={outlined} omitMargin={omitMargin} status={status} planStatus={planStatus}>{children}</Container>
);
