import React from 'react';
import { Color } from '../../Common/Styles';
import { AuthContainer } from '../../Common/AuthContainer';
import { H1 } from '../Common/AuthTypography';
import { Icon, P, A } from '../Common/AuthStyles';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  requestUrl?: string;
}

export const SetNewPasswordFailed = ({ requestUrl = '/forgot-password' }: Props) => {
  const { t } = useI18n();

  return (
    <AuthContainer>
      <H1><Icon css={{ color: Color.cherryRed }}>error</Icon></H1>
      <P>{t('Your token is invalid or expired.')}</P>
      <P>{t('You can try reset your password again.')}</P>
      <A to={requestUrl}>{t('Request token again')}</A>
    </AuthContainer>
  );
}
