import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { Color, MediaQuery } from '../../Styles';
import { MenuItemProps } from '../../../Navigation/Interfaces';
import { MainMenuItem } from './MainMenuItem';
import { UserContext } from '../../Contexts/UserContext';
import { Config } from '../../../Config';
import useI18n from '../../Hooks/useI18n';

const Container = glamorous.section({
  width: '200px',
  height: '100%',
  paddingTop: '60px',
  backgroundColor: Color.darkGreyBlue,
  flex: '0 0 auto',

  [MediaQuery.mobileOnly]: {
    display: 'none',
  },
  [MediaQuery.mobileTablet]: {
    width: '55px',
  }
});

const navigationMenu: MenuItemProps[] = [
  {
    name: 'Your day',
    icon: 'class',
    url: '/',
    isExact: true,
  },
  {
    name: 'Things',
    icon: 'playlist_add_check',
    url: '/things',
    isExact: true,
  },
  {
    name: 'Calendar',
    icon: 'today',
    url: '/calendar',
    isExact: false,
  },
  {
    name: 'Support',
    icon: 'question_answer',
    url: '/support',
    isExact: true,
  },
];

export const MainMenu = () => {
  const { lastSeenVersion } = useContext(UserContext);
  const { t } = useI18n();

  return (
    <Container>
      {navigationMenu.length > 0 && navigationMenu.map((menuItem, key) => <MainMenuItem
        key={key}
        icon={menuItem.icon}
        name={t(menuItem.name)}
        url={menuItem.url}
        isExact={menuItem.isExact}
      />)}
      <MainMenuItem
        icon="rss_feed"
        name={lastSeenVersion === Config.CURRENT_VERSION ? t("What's new?") : t("New features!")}
        url="/whats-new"
        isExact={true}
        isHighlighted={lastSeenVersion !== Config.CURRENT_VERSION}
      />
    </Container>
  )
}