export class TokenContainer {
  private token: string | null;

  constructor(private readonly name: string | null) {
    this.token = localStorage.getItem(String(name));
  }

  getToken() {
    return this.token;
  }

  setToken(newToken: string) {
    this.token = newToken;

    localStorage.setItem(String(this.name), this.token);
  }
}
