import { Rgb } from './Interface';

export enum ZIndexLevel {
  level1 = 1,
  level2 = 2,
  level3 = 3,
  appTopBar = 300,
  calendarOverview = 110,
  calendarBackdrop = 105,
  modal = 150,
  globalModal = 500,
}

export enum Color {
  appleGreen = '#74c51d',
  appleGreen01 = 'rgba(116, 197, 29, 0.1)',
  black = '#000',
  cherryRed = '#eb002d',
  cloudyBlue = '#d0d0df',
  dark = '#20203b',
  dark44 = 'rgba(32,32,59,.44)',
  dark44hex = '#898895',
  dark54 = 'rgba(32, 32, 59, 0.54)',
  dark74 = 'rgba(32, 32, 59, 0.74)',
  dark66 = 'rgba(32, 32, 59, 0.66)',
  darkGreyBlue = '#35365d',
  disabled = '#e5e5e5',
  iris = '#5a5cc7',
  lawnGreen = '#5ba709',
  lightIris = '#585978',
  orange = '#ffad33',
  paleGrey = '#f4f4fa',
  silver = '#e6e6ec',
  transparent = 'transparent',
  twilight = '#4e4f88',
  white = '#fff',
}

export enum Font {
  normal = 'Barlow, sans-serif',
  condensed = '"Barlow Condensed", sans-serif',
}

export enum BoxShadow {
  light = '0 4px 8px 0 rgba(0,0,0,.12)',
  lightTransparent = '0 4px 8px 0 transparent',
  veryLight = '0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
  default = '0 1px 3px 0 rgba(0, 0, 0, 0.12)',
  dark = '0 1px 3px 0 rgba(0, 0, 0, 0.54)',
  defaultRightOnly = '0 -2px 3px 0 rgba(0, 0, 0, 0.12)',
  selected = '0 1px 3px 0 #d0d0df',
  none = 'none',
}

export enum MediaQuerySize {
  tinyMobile = 374,
  maxMobileSize = 480,
  maxTabletSize = 991,
  minTabletSize = 481,
  minDesktopSize = 992,
  maxSmallheightSize = 450,
}

export enum MediaQuery {
  tinyMobile = `@media only screen and (max-width: ${MediaQuerySize.tinyMobile}px)` as unknown as MediaQuery,
  mobileOnly = `@media only screen and (max-width: ${MediaQuerySize.maxMobileSize}px)` as unknown as MediaQuery,
  tabletOnly =`@media only screen and (min-width: ${MediaQuerySize.minTabletSize}px) and (max-width: ${MediaQuerySize.maxTabletSize}px)` as unknown as MediaQuery,
  desktopOnly = `@media only screen and (min-width: ${MediaQuerySize.minDesktopSize}px)` as unknown as MediaQuery,
  tableDesktop = `@media only screen and (min-width: ${MediaQuerySize.minTabletSize}px)` as unknown as MediaQuery,
  smallHeight = `@media only screen and (max-height: ${MediaQuerySize.maxSmallheightSize}px)` as unknown as MediaQuery,
  mobileTablet = `@media only screen and (max-width: ${MediaQuerySize.maxTabletSize}px)` as unknown as MediaQuery,
}

export enum Size {
  huge = 'huge',
  large = 'large',
  medium = 'medium',
  small = 'small',
  tiny = 'tiny',
}

export const important = (styles: string): string => `${styles} !important`;

export const srcSet = (image2x: string, image3x: string): string => (
  `${image2x} 2x, ${image3x} 3x`
);

export const Unselectable = {
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
} as unknown as { outline: 'none' };

export const Placeholder = (styles: object) => ({
  ':placeholder': styles,
  '::-webkit-input-placeholder': styles,
  '::-moz-placeholder': styles,
  ':-ms-input-placeholder': styles,
  ':-moz-placeholder': styles,
}) as unknown as { outline: 'none '};

export const ScrollbarHidden = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',

  '::-webkit-scrollbar': {
    display: 'none',
  }
} as unknown as { overflow: 'none' };

type TransitionType = [string, number | string];
export const Transition = (properties: TransitionType | TransitionType[]) =>  (
  Array.isArray(properties[0])
    ? ({
      transition: (properties as TransitionType[])
        .map(([property, time]) => `${property} ${typeof time === 'string' ? time : `${time}s`}`)
        .join(',')
    })
    : ({
      transition: `${properties[0]} ${typeof properties[1] === 'string' ? properties[1] : `${properties[1]}s`}`
    }) 
) as unknown as { transition: 'none' };

export const hexToRgb = (hex: string): Rgb => {
  if (hex.length !== 7) {
    throw new Error(`'${hex}' is not correct Hex`);
  }

  return {
    r: parseInt(`${hex[1]}${hex[2]}`, 16),
    g: parseInt(`${hex[3]}${hex[4]}`, 16),
    b: parseInt(`${hex[5]}${hex[6]}`, 16),
  }
};

export const hexToRgba = (hex: string, opacity: number): string => {
  const { r, g, b } = hexToRgb(hex);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const BorderDotted = (color: string) => ({
  backgroundImage: [
    `linear-gradient(90deg,${color} 50%,transparent 0)`,
    `linear-gradient(90deg,${color} 50%,transparent 0)`,
    `linear-gradient(180deg,${color} 50%,transparent 0)`,
    `linear-gradient(180deg,${color} 50%,transparent 0)`,
  ].join(','),
  backgroundPosition: '0 0,0 100%,0 0,100% 0',
  backgroundRepeat: 'repeat-x,repeat-x,repeat-y,repeat-y',
  backgroundSize: '8px 1px,8px 1px,1px 8px,1px 8px',
});
