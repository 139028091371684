import glamorous from 'glamorous';
import { Color, Font } from '../Styles';

export const ModalTitle = glamorous.div({
  textAlign: 'center',
  color: Color.dark,
  padding: '15px',
  fontFamily: Font.condensed,
  borderBottom: `1px solid ${Color.silver}`,
});

export const ModalContent = glamorous.div({
  color: Color.dark,
  padding: '15px',
});

export const ModalFooter = glamorous.div({
  padding: '15px',
  borderTop: `1px solid ${Color.silver}`,
});
