import { IsoDate } from './Interface';
import { Entity, GenericDate } from './Dto/CommonDto';

declare class X1<T extends GenericDate> implements Entity<T> {
  [key: string]: any;
  id: string;
  createdAt: T;
  updatedAt: T;
}

const IsoDateRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

export function transformEntity<T>(entity: Entity<IsoDate>): T {
  Object.keys(entity).forEach(property => {
    if (
      typeof entity[property] === 'string'
      && entity[property].match(IsoDateRegex)
    ) {
      entity[property] = new Date(entity[property]);
    }
  });

  return entity as unknown as T;
}