import { User } from '../../Api/Dto/CommonDto';
import { createContext, Dispatch, SetStateAction } from 'react';
import { uuidToColor } from '../Helpers';
import { UpdateUserDto } from '../../Api/Dto/UserDto';
import { Config } from '../../Config';
import { updateUserLastSeenVersion } from '../LocalStorage';

interface UserActionsProps {
  getInitals: () => string;
  getFullName: () => string;
  getColor: () => string;
  update: (updateUserDto: UpdateUserDto) => void;
  updateWholeEntity: (user: User<Date>) => void;
  updateLastSeenVersion: (user: User<Date>) => void;
}

interface UserContextProps {
  user: User<Date>;
  lastSeenVersion: string;
  userActions: UserActionsProps,
}

const userActions = (
  user: User<Date>,
  setUser: Dispatch<SetStateAction<User<Date>>>,
  lastSeenVersion: string,
  setLastSeenVersion: Dispatch<SetStateAction<string>>,
): UserActionsProps =>  ({
  getInitals: (): string => (
    `${(user.firstName || ' ')[0]}${(user.lastName || ' ')[0]}`.toUpperCase()
  ),
  getFullName: (): string => (
    `${user.firstName || ''} ${user.lastName || ''}`
  ),
  getColor: (): string => uuidToColor(user.id),
  update: (updateUserDto) => setUser({
    ...user,
    ...updateUserDto,
  }),
  updateWholeEntity: (userEntity) => setUser(userEntity),
  updateLastSeenVersion: (userEntity) => (
    lastSeenVersion !== Config.CURRENT_VERSION && setLastSeenVersion(updateUserLastSeenVersion(user))
  ),
});

export const SetUserContext = (
  [user, setUser]: [User<Date>, Dispatch<SetStateAction<User<Date>>>],
  [lastSeenVersion, setLastSeenVersion]: [string, Dispatch<SetStateAction<string>>],
) => ({
  user,
  lastSeenVersion,
  userActions: userActions(user, setUser, lastSeenVersion, setLastSeenVersion),
});

export const UserContext = createContext<UserContextProps>({} as unknown as UserContextProps);