import React, { FormEvent } from 'react';
import { ThingContainer } from '../../Common/Thing/ThingContainer';
import { Thing, ThingStatus } from '../../Api/Dto/CommonDto';
import { ThingTitle } from '../../Common/Thing/ThingTitle';
import { ThingTheme } from '../../Common/Thing/common';
import { ThingCheckbox } from '../../Common/Thing/ThingCheckbox';
import { ThingAvatar } from '../../Common/Thing/ThingAvatar';
import { ThingHintText } from '../../Common/Thing/ThingHintText';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  thing: Thing<Date>;
  isDone: boolean;
  isEditMode: boolean;
  canCheck: boolean;
  onCheck: (event: FormEvent) => void;
  onRemove: () => void;
}

export const PreparedPlanThingElement = ({ thing, isDone, onCheck, canCheck, onRemove, isEditMode }: Props) => {
  const { t } = useI18n();

  return (
    <ThingContainer
      theme={isEditMode 
        ? thing.status === ThingStatus.TO_DO
          ? ThingTheme.DEFAULT
          : ThingTheme.PREPARED_PLAN_EDIT_MODE_READONLY
        : ThingTheme.PREPARED_PLAN}
      onClick={isEditMode && thing.status === ThingStatus.TO_DO
        ? () => onRemove()
        : undefined
      }
    >
      {isEditMode && <ThingAvatar align="left" thing={thing} />}
      {!isEditMode && <ThingCheckbox isChecked={isDone} onClick={event => canCheck && onCheck(event)} />}
      <ThingTitle title={thing.title} />
      {!isEditMode && <ThingAvatar align="right" thing={thing} />}
      {isEditMode && thing.status === ThingStatus.TO_DO && <ThingHintText align="right">{t('Tap to remove')}</ThingHintText>}
    </ThingContainer>
  );
}
