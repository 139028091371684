import React from 'react';
import glamorous from 'glamorous';
import { Font, Color } from '../../Common/Styles';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

const IconElement = glamorous.span({
  fontSize: '72px',
});

export const Icon = ({ children, css = {} }: { children: ReactNode, css?: any }) => (
  <IconElement css={css} className="material-icons">{children}</IconElement>
);

export const P = glamorous.p({
  fontSize: '16px',
  margin: '0 auto 10px',
  padding: '0 10px',
  maxWidth: '450px',
  textAlign: 'center',
});

export const A = glamorous(Link)({
  padding: '11px 30px',
  borderRadius: '4px',
  fontFamily: Font.normal,
  fontSize: '16px',
  fontWeight: 600,
  cursor: 'pointer',
  backgroundColor: Color.iris,
  color: Color.white,
  display: 'inline-block',
  transitionProperty: 'background-color, color',
  transitionDuration: '.3s',
  marginTop: '20px',
  textDecoration: 'none',

  ':hover': {
    backgroundColor: Color.white,
    color: Color.iris,
  }
});
