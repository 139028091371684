import React, { useContext, useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { renderDate } from './helpers';
import { CalendarFormat } from './common';
import { CalendarContext } from './Contexts/CalendarContext';
import dayjs from 'dayjs';
import { Color, Unselectable } from '../Common/Styles';
import { AppStatus } from '../Common/AppStatus';
import useI18n from '../Common/Hooks/useI18n';

interface Props {
  asMobile?: boolean;
}

interface ChangeDateProps {
  canClick?: boolean;
}

const Container = glamorous.div({
  display: 'flex',
  padding: '17px 0',
  borderBottom: `1px solid ${Color.silver}`,
});

const ChangeDate = glamorous.div<ChangeDateProps>(
  {
  color: Color.iris,
  lineHeight: 1.38,
  fontSize: '16px',
  fontWeight: 500,
  ...Unselectable,
  },
  ({ canClick = true }) => ({
    color: canClick ? Color.iris : Color.cloudyBlue,
    cursor: canClick ? 'pointer' : 'default',
  }),
);

const ChangeDateCursor = glamorous.span({
  margin: '0 25px',
});

const CurrentDate = glamorous.div({
  flex: 1,
  fontWeight: 600,
  fontSize: '24px',
  color: Color.twilight,
  textAlign: 'center',
  ...Unselectable,
});

export const CalendarHeader = ({ asMobile = false }: Props) => {
  const { t } = useI18n();
  const { currentDate, calendarActions, calendarStatus } = useContext(CalendarContext);

  const [prevDate, setPrevDate] = useState<Date>(dayjs(new Date(currentDate.year, currentDate.month, 1)).subtract(1, 'month').toDate());
  const [nextDate, setNextDate] = useState<Date>(dayjs(new Date(currentDate.year, currentDate.month, 1)).add(1, 'month').toDate());

  useEffect(() => {
    setPrevDate(dayjs(new Date(currentDate.year, currentDate.month)).subtract(1, 'month').toDate());
    setNextDate(dayjs(new Date(currentDate.year, currentDate.month)).add(1, 'month').toDate());
  }, [currentDate]);

  return (
    <Container>
      <ChangeDate
        canClick={!(currentDate.year === 2019 && currentDate.month < 10) && calendarStatus !== AppStatus.LOADING}
        onClick={() => {
          !(currentDate.year === 2019 && currentDate.month < 10)
          && calendarStatus !== AppStatus.LOADING
          && calendarActions.setPreviousMonth()
        }}
      >
        <ChangeDateCursor>←</ChangeDateCursor>
        {!asMobile && renderDate(prevDate, CalendarFormat.MONTH_YEAR, false, t, 'month-alone')}
      </ChangeDate>
      <CurrentDate>
        {renderDate(new Date(currentDate.year, currentDate.month), CalendarFormat.MONTH_YEAR, asMobile, t, 'month-alone')}
      </CurrentDate>
      <ChangeDate
        canClick={calendarStatus !== AppStatus.LOADING}
        onClick={() => calendarStatus !== AppStatus.LOADING && calendarActions.setNextMonth()}
      >
        {!asMobile && renderDate(nextDate, CalendarFormat.MONTH_YEAR, false, t, 'month-alone')} 
        <ChangeDateCursor>→</ChangeDateCursor>
      </ChangeDate>
    </Container>
  );
}
