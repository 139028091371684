import React, { FormEvent, useState } from 'react';
import useI18n from '../Common/Hooks/useI18n';
import glamorous from 'glamorous';
import { Card, CardTitle } from '../Common/Card';
import { ButtonsGroup } from '../Common/Forms/ButtonsGroup';
import { SubmitButton } from '../Common/Forms/Buttons';
import { Color } from '../Common/Styles';
import { LanguageCode } from '../Common/Contexts/I18nContext';

const MainCard = glamorous(Card)({
  maxWidth: '620px',
});

interface LanguageElementProps {
  isSelected: boolean;
}

const LanguageElement = glamorous.div<LanguageElementProps>(
  {
    border: `1px solid ${Color.dark44}`,
    borderRadius: '4px',
    padding: '15px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  ({ isSelected }) => ({
    backgroundColor: isSelected ? Color.appleGreen : Color.white,
    borderColor: isSelected ? Color.white : Color.dark44,
    color: isSelected ? Color.white : Color.dark66,

    ':hover': {
      backgroundColor: isSelected ? Color.appleGreen : Color.appleGreen01,
      borderColor: isSelected ? Color.white : Color.appleGreen,
      color: isSelected ? Color.white : Color.appleGreen,
    }
  }),
);

const languages = [
  {
    code: 'en',
    label: 'English',
  },
  {
    code: 'pl',
    label: 'Polski',
  }
]

export default () => {
  const { t, currentLanguage } = useI18n();

  const [selectedLanguage, setSelectedLanguage] = useState<string>(currentLanguage);

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();

    localStorage.setItem('language', selectedLanguage);

    window.location.reload();
  }

  return (
    <MainCard>
      <CardTitle>{t('Change language')}</CardTitle>
      <form method="post" onSubmit={handleOnSubmit}>
        {languages.map(({ code, label }) => (
          <LanguageElement
            key={code}
            isSelected={code === selectedLanguage}
            onClick={() => setSelectedLanguage(code)}
          >
            {label}
          </LanguageElement>
        ))}
        <ButtonsGroup align="right">
          <SubmitButton text="Change" />
        </ButtonsGroup>
      </form>
    </MainCard>
  );
}
