import React, { useState, FormEvent } from 'react';
import glamorous from 'glamorous';
import { TextSubmit } from '../../Common/Forms/TextSubmit';
import { Thing } from '../../Api/Dto/CommonDto';
import { Small } from '../../Common/Typography';
import { AxiosError } from 'axios';
import { ThingClient } from '../../Api';
import { useAppStatus } from '../../Common/Hooks/useAppStatus';
import { AppStatus } from '../../Common/AppStatus';
import { Transition } from '../../Common/Styles';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  showLabel?: boolean;
  afterSave?: ((createdThing: Thing<Date>) => void) | undefined;
  onError?: ((error: AxiosError) => void) | undefined
}

interface ContainerProps {
  isLoading: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    paddingTop: '10px',
    textAlign: 'left',
    ...Transition(['opacity', .3]),
  },
  ({ isLoading }) => ({
    opacity: isLoading ? 0.4 : 1,
  }),
);

export const AddNewThing = ({ showLabel = false, afterSave, onError }: Props) => {
  const { t } = useI18n();
  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);

  const [thingTitle, setThingTitle] = useState<string>('');

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (appStatus === AppStatus.LOADING || thingTitle.length === 0) {
      return;
    }

    setAppStatus(AppStatus.LOADING);

    ThingClient
      .create({ title: thingTitle })
      .then(data => {
        setThingTitle('');
        setAppStatus(AppStatus.READY);
        afterSave !== undefined && afterSave(data);
      })
      .catch(err => {
        setAppStatus(AppStatus.READY);
        onError !== undefined && onError(err);
      });
  };

  return (
    <Container isLoading={appStatus === AppStatus.LOADING}>
      {showLabel === true && <Small>{t('Add new thing')}</Small>}
      <TextSubmit
        onSubmit={handleOnSubmit}
        onChange={({ currentTarget }) => appStatus !== AppStatus.LOADING && setThingTitle(currentTarget.value)}
        value={thingTitle}
        placeholder="Write here to add new thing"
        canSubmit={appStatus !== AppStatus.LOADING && thingTitle.length > 0}
        buttonText="Add"
      />
    </Container>
      
  )
};
