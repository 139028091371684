import React, { useContext, useEffect } from 'react';
import glamorous from 'glamorous';
import { DotLoader } from '../Common/Loaders/DotLoader';
import { CalendarContext } from './Contexts/CalendarContext';
import { AppStatus } from '../Common/AppStatus';
import { CalendarOverviewPlan } from './CalendarOverviewPlan';
import { Color } from '../Common/Styles';
import { CalendarOverviewContext } from './Contexts/CalendarOverviewContext';
import { H3, Small } from '../Common/Typography';
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import { ThingStatus } from "../Api/Dto/ThingDto";
import { ThingClient } from '../Api';
import { CalendarThings } from './CalendarThings';
import { CalendarThingsContext } from './Contexts/CalendarThingsContext';
import { PlanThingsHoc } from './Hoc/PlanThingsHoc';
import useI18n from '../Common/Hooks/useI18n';

interface ContainerLeftProps {
  isExpanded: boolean;
}

const Container = glamorous.div({
  flex: 1,
  width: '100%',
  height: '100%',
  padding: '10px 10px 0 10px',
  borderLeft: `1px solid ${Color.silver}`,
});

const ContainerLeft = glamorous.div<ContainerLeftProps>(
  {
    float: 'left',
    width: '320px',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '10px',
  },
  ({ isExpanded }) => ({
    textAlign: isExpanded ? 'left' : 'center',
  }),
);

const ContainerRight = glamorous.div({
  flex: 1,
  height: '100%',
  float: 'right',
  width: '320px',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '10px',
});

const ListContainer = glamorous.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
})

export const CalendarOverviewContent = () => {
  const { t } = useI18n();
  const [thingsStatus, setThingsStatus] = useAppStatus();

  const { calendarStatus } = useContext(CalendarContext);
  const { isExpanded } = useContext(CalendarOverviewContext);
  const { calendarThings, calendarThingsActions } = useContext(CalendarThingsContext);

  const fetchThings = () => ThingClient
    .getList({
      filter: {
        status: ThingStatus.TO_DO,
      },
      paginate: {
        limit: 50,
      },
    })
    .then(data => {
      calendarThingsActions.setThings(data);
      setThingsStatus(AppStatus.READY);
    })
    .catch(() => {
      calendarThingsActions.setThings([]);
      setThingsStatus(AppStatus.READY);
    });

  useEffect(() => {
    isExpanded && thingsStatus === AppStatus.LOADING && setTimeout(() => fetchThings(), 300);
  }, [isExpanded]);

  return (
    <Container>
      <PlanThingsHoc>
        <ContainerLeft isExpanded={isExpanded}>
          {calendarStatus === AppStatus.LOADING
              ? <DotLoader />
              : <CalendarOverviewPlan />
          }
        </ContainerLeft>
        <ContainerRight>
          <H3>Things</H3>
          <Small>{ thingsStatus === AppStatus.LOADING ? ' ' : `${calendarThings.length} ${t('things ready to start')}`}</Small>
          <ListContainer>
          { thingsStatus === AppStatus.LOADING
              ? <DotLoader />
              : <CalendarThings />
          }
          </ListContainer>
        </ContainerRight>
      </PlanThingsHoc>
    </Container>
  );
}
