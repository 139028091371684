export const uuidToColor = (uuid: string | null | undefined): string => {
  if (!uuid) {
    return '#fff';
  }

  switch (uuid.substr(0, 1)) {
    case '0':
      return '#f6685e';
    case '1':
      return '#ed4b82';
    case '2':
      return '#af52bf';
    case '3':
      return '#8561c5';
    case '4':
      return '#6573c3';
    case '5':
      return '#4dabf5';
    case '6':
      return '#35baf6';
    case '7':
      return '#33c9dc';
    case '8':
      return '#33ab9f';
    case '9':
      return '#6fbf73';
    case 'a':
      return '#a2cf6e';
    case 'b':
      return '#d7e360';
    case 'c':
      return '#ffef62';
    case 'd':
      return '#ffcd38';
    case 'e':
      return '#ffac33';
    case 'f':
      return '#ff784e';
    default:
      return '#fff';
  }
}

export const zerofill = (value: number | string, length: number) => (
  ('0'.repeat(length) + String(value)).slice(-length)
);
