import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { CircularProgressBar } from '../../Common/CircularProgressBar';
import { Color, Size, Font, hexToRgba } from '../../Common/Styles';
import { isReadyProps } from '../interface';
import { SmartRatingTransitionDuration, SmartRatingTransitionDelay } from '../constant';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  rating: number;
  tasksQnt: number;
  tasksDone: number;
}

const Container = glamorous.div<isReadyProps>(
  {
    margin: '0 15px',
    position: 'relative',

    transitionProperty: 'opacity, transform',
    transitionDuration: `${SmartRatingTransitionDuration.CircularProgressBar}ms`,
    transitionDelay: `${SmartRatingTransitionDelay.CircularProgressBar}ms`,
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
    transform: `translate3d(0, ${isReady ? '0' : '15px'}, 0)`,
  }),
);

const TextContainer = glamorous.div<isReadyProps>(
  {
    position: 'absolute',
    top: '60px',
    width: '100%',
    textAlign: 'center',

    transitionProperty: 'opacity',
    transitionDuration: `${SmartRatingTransitionDuration.CircularProgressBarText}ms`,
    transitionDelay: `${SmartRatingTransitionDelay.CircularProgressBarText}ms`,
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
  }),
);

const Rating = glamorous.span({
  display: 'block',
  fontSize: '58px',
  fontWeight: 500,
  lineHeight: 1,
  color: Color.dark,
});

const ThingsSummary = glamorous.span({
  display: 'block',
  fontSize: '12px',
  fontWeight: 500,
  fontFamily: Font.condensed,
  color: Color.dark74,
});

export const UserRating = ({ rating, tasksDone, tasksQnt }: Props) => {
  const { t } = useI18n();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <Container isReady={isReady}>
      <CircularProgressBar
        pathColor={Color.dark}
        trailColor={hexToRgba(Color.dark, .3)}
        size={Size.huge}
        percentage={isReady ? rating : 0}
        pathTransitionDelay={SmartRatingTransitionDelay.CircularProgressBarPath}
      />
      <TextContainer isReady={isReady}>
        <Rating>{rating}</Rating>
        <ThingsSummary>{tasksDone}/{tasksQnt} {t('things done')}</ThingsSummary>
      </TextContainer>
    </Container>
  )
};
