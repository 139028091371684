import React, { useState, useEffect } from 'react';
import { AuthContainer } from '../../Common/AuthContainer';
import glamorous from 'glamorous';
import { Color } from '../../Common/Styles';
import { A } from '../Common/AuthStyles';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  firstName: string;
}

interface ContinueButtonProps {
  isVisible: boolean;
}

const Title = glamorous.p({
  fontSize: '36px',
  color: 'rgba(244,244,250,.8)',
  marginBottom: '30px',
  lineHeight: 1,

  '& > strong': {
    fontWeight: 500,
    color: Color.white,
  }
});

const ContinueButton = glamorous(A)(
  
);

const ButtonContainer = glamorous.div<ContinueButtonProps>(
  {
    transition: 'opacity, 1s',
  },
  ({ isVisible }) => ({
    opacity: isVisible ? 1 : 0,
  }),
);

export const HelloGreetings = ({ firstName }: Props) => {
  const { t } = useI18n();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 500);
  }, []);

  const parseGreetings = (hours: number): string => {
    if (hours > 12 && hours < 18) {
      return t('Good afternoon');
    }

    if (hours > 17 || hours < 4) {
      return t('Good evening');
    }

    return t('Good morning');
  }

  return (
    <AuthContainer>
      <Title>
        {parseGreetings((new Date()).getHours())}
        {firstName.length > 0 && (<>, <strong>{firstName}</strong></>)}
      </Title>
      <ButtonContainer isVisible={isVisible}>
        <ContinueButton to="/">{t('Continue')}</ContinueButton>
      </ButtonContainer>
    </AuthContainer>
  )
}