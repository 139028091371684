import { createContext, Dispatch } from 'react';
import { Thing } from '../../Api/Dto/CommonDto';
import { CalendarThingsReducerAction, CalendarThingsAction } from '../Reducers/CalendarThingsReducer';

interface CalendarThingsActionsProps {
  add: (thing: Thing<Date>) => void;
  remove: (thing: Thing<Date>) => void;
  update: (thing: Thing<Date>) => void;
  updateMany: (things: Thing<Date>[]) => void;
  upsert: (thing: Thing<Date>) => void;
  setThings: (things: Thing<Date>[]) => void;
  correctIfOutdatedStatus: (thing: Thing<Date>) => Thing<Date>
}

interface CalendarThingsContextProps {
  calendarThings: Thing<Date>[];
  calendarThingsActions: CalendarThingsActionsProps;
}

const calendarThingsActions = (
  calendarThings: Thing<Date>[],
  dispatch: Dispatch<CalendarThingsReducerAction>
): CalendarThingsActionsProps => ({
  add: (thing) => dispatch({ type: CalendarThingsAction.ADD, value: thing }),
  remove: (thing) => dispatch({ type: CalendarThingsAction.REMOVE, value: thing }),
  update: (thing) => dispatch({ type: CalendarThingsAction.UPDATE, value: thing }),
  updateMany: (things) => dispatch({ type: CalendarThingsAction.UPDATE_MANY, values: things }),
  upsert: (thing) => dispatch({ type: CalendarThingsAction.UPSERT, value: thing }),
  setThings: (things) => dispatch({ type: CalendarThingsAction.SET_ALL, values: things }),
  correctIfOutdatedStatus: (thing) => {
    const foundThing = calendarThings.find(calendarThing => calendarThing.id === thing.id);

    if (foundThing === undefined) {
      return thing;
    }

    return thing.status !== foundThing.status
      ? foundThing
      : thing
  }
});

export const SetCalendarThingsContext = (
  calendarThings: Thing<Date>[],
  dispatch: Dispatch<CalendarThingsReducerAction>,
): CalendarThingsContextProps => ({
  calendarThings,
  calendarThingsActions: calendarThingsActions(calendarThings, dispatch),
});

export const CalendarThingsContext = createContext<CalendarThingsContextProps>({} as unknown as CalendarThingsContextProps);
