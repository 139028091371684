import React, { useState, useEffect } from 'react';
import { VerifyAccountWaiting } from './VerifyAccountWaiting';
import { VerifyAccountSuccess } from './VerifyAccountSuccess';
import { AuthTokenClient } from './../../Api';
import { RouteComponentProps } from 'react-router-dom';
import { VerifyAccountError } from './VerifyAcountError';

enum ViewType {
  WAITING = 'waiting',
  ERROR = 'error',
  SUCCESS = 'success',
};

interface Entity {
  firstName: string;
  lastName: string;
}

interface MatchProps {
  token?: string;
}

export const VerifyAccount = ({ match }: RouteComponentProps<MatchProps>) => {
  const [viewType, setViewType] = useState<ViewType>(ViewType.WAITING);
  const [token] = useState(match.params.token || '');
  const [entity, setEntity] = useState<Entity>({
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    verifyToken(token);
  }, []);

  const verifyToken = (token: string) => {
    AuthTokenClient
      .verifyActivationToken(token)
      .then(({ data }) => {
        const { firstName, lastName } = data;

        setEntity({ firstName, lastName });
        setViewType(ViewType.SUCCESS);
      })
      .catch(() => setViewType(ViewType.ERROR));
  }

  switch (viewType) {
    case ViewType.SUCCESS:
      return <VerifyAccountSuccess token={token} firstName={entity.firstName} lastName={entity.lastName} />
    case ViewType.ERROR:
      return <VerifyAccountError />
    default:
      return <VerifyAccountWaiting />;
  }
}