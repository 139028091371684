import React, { useContext } from 'react';
import { DayElement } from '../interfaces';
import { DailyPlan, DailyPlanStatus } from '../../Api/Dto/CommonDto';
import glamorous from 'glamorous';
import { BoxShadow, Color, Transition, hexToRgba } from '../../Common/Styles';
import { MobileDayNumber } from './MobileDayNumber';
import { MobileDayPlanCompleted } from './MobileDayPlanCompleted';
import { hasItem } from '../../Common/ArrayHelper';
import { MobileDayPlanActive } from './MobileDayPlanActive';
import { MobileDayNoPlan } from './MobileDayNoPlan';
import { CalendarContext } from '../Contexts/CalendarContext';
import { AppStatus } from '../../Common/AppStatus';
import { Today } from './Today';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  day: DayElement;
  plan: DailyPlan<Date> | undefined;
}

interface ContainerProps {
  isLoading: boolean;
  highlight: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    position: 'relative',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '4px',
    boxShadow: BoxShadow.light,
    display: 'flex',
    cursor: 'pointer',
    ...Transition(['opacity', .3]),
  },
  ({ isLoading, highlight }) => ({
    opacity: isLoading ? 0.6 : 1,
    backgroundColor: highlight ? hexToRgba(Color.iris, 0.15) : Color.white,
    border: highlight ? `1px solid ${Color.iris}` : 'none',

    '& > div:not(.calendar-day-number), & > span & > i': {
      opacity: isLoading ? 0 : 1,
      ...Transition(['opacity', .3]),
    }
  }),
);

export const MobileDay = ({ day, plan }: Props) => {
  const { t } = useI18n();
  const { calendarActions, calendarStatus, currentDate } = useContext(CalendarContext);

  const checkIsToday = () => calendarActions.isToday({ ...currentDate, day: day.dayNumber });

  return (
    <Container 
      isLoading={calendarStatus === AppStatus.LOADING} 
      highlight={checkIsToday()}
      onClick={() => calendarActions.selectDay(day.dayNumber)}
    >
      <MobileDayNumber day={day} />
      {plan !== undefined && plan.status === DailyPlanStatus.COMPLETED && <MobileDayPlanCompleted plan={plan} /> }
      {plan !== undefined && hasItem<DailyPlanStatus>([DailyPlanStatus.ACTIVE, DailyPlanStatus.DRAFT, DailyPlanStatus.PREPARED], plan.status) && <MobileDayPlanActive plan={plan} /> }
      {plan === undefined && <MobileDayNoPlan />}
      {checkIsToday() && <Today>{t('today')}</Today>}
    </Container>
  )
};
