import React, { useState, useContext } from 'react';
import glamorous from 'glamorous';
import { srcSet, ZIndexLevel, MediaQuery } from '../../Styles';
import LogoImage from '../../../assets/images/assets/logo.png';
import LogoImage2x from '../../../assets/images/assets/logo@2x.png';
import LogoImage3x from '../../../assets/images/assets/logo@3x.png';
import { Avatar } from '../../Avatar';
import { AvatarSize } from '../../Interface';
import { UserProfileDropdown } from './UserProfileDropdown';
import { UserContext } from '../../Contexts/UserContext';

const LOGO_WIDTH = '200px';

const Container = glamorous.section({
  position: 'absolute',
  left: 0,
  top: 0,
  width: LOGO_WIDTH,
  height: '60px',
  zIndex: ZIndexLevel.appTopBar,

  [MediaQuery.mobileOnly]: {
    display: 'none',
  },
  [MediaQuery.mobileTablet]: {
    width: '55px',
  }
});

const Logo = glamorous.div({
  textAlign: 'center',
  width: '100%',
});

const Img = glamorous.img({
  marginTop: '15px',
});

const Items = glamorous.section({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: ZIndexLevel.appTopBar,

  [MediaQuery.mobileOnly]: {
    display: 'none',
  }
});

const UserProfile = glamorous.div({
  position: 'relative',
  padding: '15px',
  cursor: 'pointer',
  float: 'right',
});

export const TopBar = () => {
  const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);
  const { userActions } = useContext(UserContext);

  return (
    <>
    <Container>
      <Logo>
        <Img src={LogoImage} srcSet={srcSet(LogoImage2x, LogoImage3x)} />
      </Logo>
    </Container>
    <Items>
        <UserProfile>
          <Avatar 
            color={userActions.getColor()}
            text={userActions.getInitals()}
            size={AvatarSize.SMALL}
            onClick={() => setIsDropdownActive(true)}
          />
        </UserProfile>
        <UserProfileDropdown
          isActive={isDropdownActive}
          setIsActive={setIsDropdownActive}
        />
      </Items>
    </>
  );
}
