import { Rating } from '../interface';
import { AlgorithmFactors } from '../../Api/Dto/AlgorithmDto';
import { PraisesGenerator } from './PraisesGenerator';
import { AdvicesGenerator } from './AdvicesGenerator';
import { TranslateFunction } from '../../Common/Contexts/I18nContext';

export const DEFAULT_TEXTS_AMOUNT = 3;

export const generatePraises = (
  factors: AlgorithmFactors,
  rating: Rating,
  tasksQnt: number,
  tasksDone: number,
  length: number = DEFAULT_TEXTS_AMOUNT,
  t: TranslateFunction,
): string[] => {
  const generator = new PraisesGenerator(
    [],
    factors,
    rating,
    { tasksDone, tasksQnt },
    t
  );

  generator.generate();

  return generator.toSortedMessages(length);
};

export const generateAdvices = (
  factors: AlgorithmFactors,
  rating: Rating,
  tasksQnt: number,
  tasksDone: number,
  length: number = DEFAULT_TEXTS_AMOUNT,
  t: TranslateFunction,
): string[] => {
  const generator = new AdvicesGenerator(
    [],
    factors,
    rating,
    { tasksDone, tasksQnt },
    t
  );

  generator.generate();

  return generator.toSortedMessages(length);
};
