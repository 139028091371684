import dayjs from 'dayjs';
import { DayElement, Ymd, DayOfWeek } from '../interfaces';

const transformDayToEuStandard = (day: number): number => day === 0 ? 6 : day - 1;

const incrementDayOfWeek = (dayOfWeek: DayOfWeek): DayOfWeek => dayOfWeek > 5 ? 0 : dayOfWeek + 1 as DayOfWeek;

export const prepareGridFromDate = ({ year, month }: Ymd): DayElement[][] => {
  const flatGrid: DayElement[] = [];
  const deepGrid: DayElement[][] = [];

  const selectedDate = dayjs(new Date(year, month, 1));
  const prevMonthDays = dayjs(new Date(year, month, 1)).subtract(1, 'month').daysInMonth();
  
  const firstDayOfMonth = transformDayToEuStandard(selectedDate.day());
  const remainingDays = 6 - transformDayToEuStandard(selectedDate.endOf('month').day());

  for (let i = 0; i < firstDayOfMonth; i++) {
    flatGrid.push({
      dayNumber: prevMonthDays - (firstDayOfMonth - i - 1),
      isActive: false,
    });
  }

  let dayOfWeek: DayOfWeek = firstDayOfMonth as DayOfWeek;

  for (let i = 0; i < selectedDate.daysInMonth(); i++) {
    dayOfWeek = incrementDayOfWeek(dayOfWeek);

    flatGrid.push({
      dayNumber: i + 1,
      isActive: true,
      dayOfWeek,
    });
  }

  for (let i = 0; i < remainingDays; i++) {
    flatGrid.push({
      dayNumber: i + 1,
      isActive: false,
    });
  }

  for (let i = 0; i < flatGrid.length; i += 7) {
    deepGrid.push(flatGrid.slice(i, i + 7));
  }

  return deepGrid;
}

export const dayOfWeekToString = (dayOfWeek: DayOfWeek, isShort: boolean = false): string => {
  switch (dayOfWeek) {
    case 0:
      return isShort ? 'Sun' : 'Sunday';
    case 1:
      return isShort ? 'Mon' : 'Monday';
    case 2:
      return isShort ? 'Tue' : 'Tuesday';
    case 3:
      return isShort ? 'Wed' : 'Wednesday';
    case 4:
      return isShort ? 'Thu' : 'Thursday';
    case 5:
      return isShort ? 'Fri' : 'Friday';
    case 6:
      return isShort ? 'Sat' : 'Saturday';
  }
}
