import React, { FormEvent } from 'react';
import glamorous from 'glamorous';
import { Color, ZIndexLevel, BoxShadow, hexToRgba, Transition, Unselectable } from './Styles';

interface Props {
  percentage: number;
  children: any;
  styles?: any;
  isActive?: boolean;
  onClick?: (event: FormEvent) => void;
}

interface ContainerProps {
  isActive: boolean;
}

interface ProgressProps {
  percentage: number;
  isActive: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    position: 'relative',
    textAlign: 'center',
    boxShadow: BoxShadow.light,
    padding: '12px',
    borderRadius: '4px',
    transitionProperty: 'transform, background-color',
    transitionDuration: '.15s',

    ':active': {
      transform: 'scale(0.97)',
    }
  },
  ({ isActive }) => ({
    cursor: isActive ? 'pointer' : 'default',
    backgroundColor: isActive ? hexToRgba(Color.iris, 0.7) : Color.silver,
  })
);

const Progress = glamorous.span<ProgressProps>(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: Color.appleGreen,
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    ...Transition([
      ['width', .3],
      ['opacity', .15],
    ]),
  },
  ({ percentage, isActive }) => ({
    width: `${percentage}%`,
    borderTopRightRadius: percentage < 100 ? '0' : '4px',
    borderBottomRightRadius: percentage < 100 ? '0' : '4px',
    opacity: isActive ? 1 : 0,
  })
);

const Text = glamorous.span({
  color: Color.white,
  position: 'relative',
  zIndex: ZIndexLevel.level1,
  fontSize: '16px',
  fontWeight: 600,
  ...Unselectable,
});

export const ProgressButton = ({ children, percentage, isActive = true, onClick = () => {}, styles = {} }: Props) => (
  <Container isActive={isActive} onClick={(event) => isActive && onClick(event)} css={styles}>
    <Text>{children}</Text>
    <Progress isActive={isActive} percentage={percentage} />
  </Container>
);
