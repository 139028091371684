import React from 'react';
import { Div, Span, Img } from 'glamorous';
import Image from '../../assets/images/assets/full-logo.png';
import Image2x from '../../assets/images/assets/full-logo@2x.png';
import Image3x from '../../assets/images/assets/full-logo@3x.png';
import { srcSet, Color, ZIndexLevel } from '../../Common/Styles';

const styles = {
  container: {
    top: 0,
    left: 0,
  },
  rectangle: {
    backgroundColor: Color.appleGreen,
    width: '200px',
    height: '200px',
    transform: 'rotate(30deg)',
    left: '-151px',
    top: '-65px',
  },
  logo: {
    zIndex: ZIndexLevel.level1,
    top: '16px',
    left: '25px',
  },
};

export const AuthLogo = () => (
  <Div position="absolute" css={styles.container}>
    <Span position="absolute" css={styles.rectangle}></Span>
    <Img position="absolute" css={styles.logo} src={Image} srcSet={srcSet(Image2x, Image3x)} />
  </Div>
);
