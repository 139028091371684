export const CALENDAR_OVERVIEW_HIDDEN_WIDTH = '339px';
export const CALENDAR_OVERVIEW_WIDTH = '672px';
export const CALENDAR_TRANSFORM_LENGTH = '333px';
export const CALENDAR_OVERVIEW_EXPANDED_WIDTH = '360px';

export enum CalendarFormat {
  MONTH_YEAR = 'MONTH_YEAR',
  MONTH = 'MONTH',
}

export const CALENDAR_DAYS: { full: string, short: string }[] = [
  { full: 'Monday', short: 'Mon' },
  { full: 'Tuesday', short: 'Tue' },
  { full: 'Wednesday', short: 'Wed' },
  { full: 'Thursday', short: 'Thu' },
  { full: 'Friday', short: 'Fri' },
  { full: 'Saturday', short: 'Sat' },
  { full: 'Sunday', short: 'Sun' },
];

export const CALENDAR_MONTHS: { full: string, short: string }[] = [
  { full: 'January', short: 'Jan' },
  { full: 'February', short: 'Feb' },
  { full: 'March', short: 'Mar' },
  { full: 'April', short: 'Apr' },
  { full: 'May', short: 'May' },
  { full: 'June', short: 'Jun' },
  { full: 'July', short: 'Jul' },
  { full: 'August', short: 'Aug' },
  { full: 'September', short: 'Sep' },
  { full: 'October', short: 'Oct' },
  { full: 'November', short: 'Nov' },
  { full: 'December', short: 'Dec' },
];

export const getCurrentDayOrFirstOne = (year: number, month: number): number => {
  const currentDate = new Date();

  return (currentDate.getFullYear() === year && currentDate.getMonth() === month)
    ? currentDate.getDate()
    : 1;
}