import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { ModalContent, ModalTitle, ModalFooter } from './styles';
import { Color } from '../Styles';
import { ButtonsGroup } from '../Forms/ButtonsGroup';
import { Button } from '../Forms/Buttons';
import { ModalTemplate, TemplateContext } from '../Contexts/TemplateContext';
import { useAppStatus } from '../Hooks/useAppStatus';
import { AppStatus } from '../AppStatus';
import { SubscriptionClient } from '../../Api';
import { UserContext } from '../Contexts/UserContext';
import useI18n from '../Hooks/useI18n';

interface Props {
  title: string;
  icon: string;
  freeLimits: string;
  proLimits: string;
}

const Content = glamorous(ModalContent)({
  display: 'flex',
  flexDirection: 'column',
});

const Icon = glamorous.i({
  margin: '15px auto',
  fontSize: '64px',
  color: Color.dark44,
});

const OldLimit = glamorous.div({
  color: Color.dark44,
  paddingBottom: '10px',
  marginBottom: '20px',
  borderBottom: `1px solid ${Color.silver}`,
});

const NewLimit = glamorous.div({
  color: Color.appleGreen,
});

const LimitTitle = glamorous.p({
  textAlign: 'center',
  fontSize: '14px',
  margin: '0 0 10px 0',
});

const LimitDescription = glamorous.p({
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 500,
  margin: '0 0 10px 0',
});

const Offer = glamorous.div({
  color: Color.dark,
  textAlign: 'center',
  padding: '30px 30px 15px 30px',
});

const OfferTitle = glamorous.p({
  margin: '0 0 15px 0',
  fontSize: '14px',
});

const OfferAdvantage = glamorous.p({
  margin: '0 0 2px 0',
  fontSize: '14px',
  fontWeight: 500,

  '&:before': {
    content: '- ',
  },
  '&:after': {
    content: ' -',
  }
});

export const ProTrialModal = ({ title, icon, freeLimits, proLimits }: Props) => {
  const { t } = useI18n();
  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);

  const { templateActions } = useContext(TemplateContext);
  const { userActions } = useContext(UserContext);

  const submitTrial = () => {
    setAppStatus(AppStatus.LOADING);

    SubscriptionClient
      .trial()
      .then(data => {
        userActions.updateWholeEntity(data);
        templateActions.setModal(ModalTemplate.WELCOME_PRO);
      })
      .catch(() => templateActions.setModal(ModalTemplate.WELCOME_PRO));
  }

  return (
    <>
      <ModalTitle>{t(title)}</ModalTitle>
      <Content>
        <Icon className="material-icons">{icon}</Icon>
        <OldLimit>
          <LimitTitle>{t('Your current limits')}</LimitTitle>
          <LimitDescription>{t(freeLimits)}</LimitDescription>
        </OldLimit>
        <NewLimit>
          <LimitTitle>Tauplan PRO</LimitTitle>
          <LimitDescription>{t(proLimits)}</LimitDescription>
        </NewLimit>
        <Offer>
          <OfferTitle>{t('Take off those limits and try Tauplan PRO Trial for free until')} 1 {t('July', 'month-of')} 2020</OfferTitle>
          <OfferAdvantage>{t('No payments')}</OfferAdvantage>
          <OfferAdvantage>{t('No credit card required')}</OfferAdvantage>
          <OfferAdvantage>{t('No additional data needed')}</OfferAdvantage>
          <OfferAdvantage>{t('Just click and go')}</OfferAdvantage>
        </Offer>
      </Content>
      <ModalFooter>
        <ButtonsGroup align="right">
          <Button
            isActive={appStatus !== AppStatus.LOADING}
            color={Color.dark44hex}
            onClick={() => templateActions.setModal(ModalTemplate.HIDDEN)}
            outlined
            tick
          >
            {t('Nah, go back')}
          </Button>
          <Button
            isActive={appStatus !== AppStatus.LOADING}
            color={Color.appleGreen}
            onClick={() => appStatus !== AppStatus.LOADING && submitTrial()}
          >
            {t("Yeah, let's try")}
          </Button>
        </ButtonsGroup>
      </ModalFooter>
    </>
  );
};
