import React, { useContext, useEffect } from 'react';
import { DotLoader } from '../Common/Loaders/DotLoader';
import glamorous from 'glamorous';
import { CalendarOverviewPlan } from './CalendarOverviewPlan';
import { CalendarContext } from './Contexts/CalendarContext';
import { AppStatus } from '../Common/AppStatus';
import { PlanThingsHoc } from './Hoc/PlanThingsHoc';
import { ShowMobileThingsContext } from './Contexts/ShowMobileThingsContext';
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import { ThingClient } from '../Api';
import { ThingStatus } from '../Api/Dto/ThingDto';
import { CalendarThingsContext } from './Contexts/CalendarThingsContext';
import { CalendarMobileThings } from './CalendarMobileThings';

const Container = glamorous.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  padding: '10px',
});

export const CalendarMobileOverviewContent = () => {
  const { calendarStatus } = useContext(CalendarContext);
  const { calendarThingsActions } = useContext(CalendarThingsContext);
  const { showMobileThings } = useContext(ShowMobileThingsContext);

  const [thingsStatus, setThingsStatus] = useAppStatus();

  const fetchThings = () => ThingClient
    .getList({
      filter: {
        status: ThingStatus.TO_DO,
      },
      paginate: {
        limit: 50,
      },
    })
    .then(data => {
      calendarThingsActions.setThings(data);
      setThingsStatus(AppStatus.READY);
    })
    .catch(() => {
      calendarThingsActions.setThings([]);
      setThingsStatus(AppStatus.READY);
    });

  useEffect(() => {
    showMobileThings && thingsStatus === AppStatus.LOADING && setTimeout(() => fetchThings(), 300);
  }, [showMobileThings]);

  return (
    <Container>
      <PlanThingsHoc>
        {showMobileThings === false
          ? calendarStatus === AppStatus.LOADING ? <DotLoader /> : <CalendarOverviewPlan />
          : thingsStatus === AppStatus.LOADING ? <DotLoader /> : <CalendarMobileThings />
        }
      </PlanThingsHoc>
    </Container>
  );
};
