import { User, GenericDate } from '../../Api/Dto/CommonDto';
import { Config } from './../../Config';

function setToLocalStorage(key: string, value: any, isJson: boolean = false) {
  localStorage.setItem(key, isJson ? JSON.stringify(value) : value);
}

function getFromLocalStorage<T = any | null>(key: string, isJson: boolean = false, defaultValue: T | null = null): T | null {
  const value = localStorage.getItem(key);

  if (value === null) {
    return defaultValue;
  }

  return isJson ? JSON.parse(value) : value;
}

export const getUserLastSeenVersion = (user: User<GenericDate>): string | null => (
  getFromLocalStorage<string>(`user/${user.id}/last-seen-version`)
);

export const updateUserLastSeenVersion = (user: User<Date>): string => {
  if (user.createdAt.getTime() > Config.APPLY_FIRST_VERSION_TIMESTAMP) {
    setToLocalStorage(
      `user/${user.id}/last-seen-version`,
      Config.CURRENT_VERSION,
    );

    return Config.CURRENT_VERSION;
  }

  const currentVersion = getUserLastSeenVersion(user);

  const newVersion = currentVersion === null ? Config.FIRST_VERSION : Config.CURRENT_VERSION

  setToLocalStorage(
    `user/${user.id}/last-seen-version`,
    newVersion,
  );

  return newVersion;
};
