import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { MediaQuery, Color, Unselectable } from '../../Common/Styles';
import { Button } from '../../Common/Forms/Buttons';
import { ShowMobileThingsContext } from '../Contexts/ShowMobileThingsContext';
import useI18n from '../../Common/Hooks/useI18n';

const EmptyHolder = glamorous.div({
  flex: 1,
  color: Color.dark74,
  margin: '10px 0',
  padding: '0 80px',
  textAlign: 'center',
  opacity: 0.5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: [
    `linear-gradient(90deg,${Color.dark44} 50%,transparent 0)`,
    `linear-gradient(90deg,${Color.dark44} 50%,transparent 0)`,
    `linear-gradient(180deg,${Color.dark44} 50%,transparent 0)`,
    `linear-gradient(180deg,${Color.dark44} 50%,transparent 0)`,
  ].join(','),
  backgroundPosition: '0 0,0 100%,0 0,100% 0',
  backgroundRepeat: 'repeat-x,repeat-x,repeat-y,repeat-y',
  backgroundSize: '8px 1px,8px 1px,1px 8px,1px 8px',
  ...Unselectable,
});

const EmptyHolderDesktop = glamorous.span({
  [MediaQuery.mobileOnly]: {
    display: 'none',
  },
});

const EmptyHolderMobile = glamorous.span({
  [MediaQuery.tableDesktop]: {
    display: 'none',
  }
});

const EmptyHolderMobileAddThingsButton = glamorous(Button)({
  marginTop: '30px',
});

export const AddThingsTutorial = () => {
  const { t } = useI18n();
  const { setShowMobileThings } = useContext(ShowMobileThingsContext);

  return (
    <EmptyHolder>
      <EmptyHolderDesktop>{t('Tap on a thing on the right side to add it here')}</EmptyHolderDesktop>
      <EmptyHolderMobile>
        {t('No things in the plan')}
        <EmptyHolderMobileAddThingsButton 
          isActive={true}
          color={Color.dark}
          onClick={() => setShowMobileThings !== undefined && setShowMobileThings(true)}
        >
          {t('Add things')}
        </EmptyHolderMobileAddThingsButton>
      </EmptyHolderMobile>
    </EmptyHolder>
  );
}
