import React from 'react';
import glamorous from 'glamorous';
import { Color, Unselectable, MediaQuery } from '../../Common/Styles';

interface Props {
  color: string;
  icon: string;
  isVisible: boolean;
  transitionDuration: string;
  onClick: () => void;
}

interface ContainerProps {
  color: string;
  isVisible: boolean;
  transitionDuration: string;
}

export const Container = glamorous.i<ContainerProps>(
  {
    textAlign: 'center',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    transitionProperty: 'transform, opacity',
    fontSize: '21px',
    lineHeight: '40px',
    borderRadius: '50%',
    margin: '0px 5px',
    ...Unselectable,

    [MediaQuery.tableDesktop]: {
      ':hover': {
        transform: 'scale(1.2)'
      }
    }
  },
  ({ color, isVisible, transitionDuration }) => ({
    backgroundColor: color,
    color: color === Color.white || color === Color.transparent ? Color.black : Color.white,
    opacity: isVisible ? 1 : 0,
    transform: `translate3d(0, ${isVisible ? 0 : 20}px, 0)`,
    transitionDuration,
  }),
);

export const DrawerTopToolsIcon = ({ color, icon, isVisible, transitionDuration, onClick }: Props) => (
  <Container 
    className="material-icons"
    isVisible={isVisible}
    color={color}
    transitionDuration={transitionDuration}
    onClick={onClick}
  >
    {icon}
  </Container>
);
