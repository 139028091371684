import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { MonthRaw } from '../../Common/Interface';
import { zerofill } from '../../Common/Helpers';
import { AlgorithmFactors } from '../Dto/AlgorithmDto';

export class AlgorithmApiClient extends AccessTokenApiClient {
  getFactors(year: number, month: MonthRaw, day: number): Promise<AlgorithmFactors> {
    return this.apiRequest
      .get<AlgorithmFactors>(`${year}-${zerofill(month, 2)}-${zerofill(day, 2)}`)
      .then(({ data }) => data);
  }
}
