import React from 'react';
import { DailyPlan, DailyPlanStatus } from '../../Api/Dto/CommonDto';
import { GridDayPlanCompleted } from './GridDayPlanCompleted';
import { GridDayPlanActive } from './GridDayPlanActive';

interface Props {
  plan: DailyPlan<Date>;
}

export const GridDayPlan = ({ plan }: Props) => {
  switch (plan.status) {
    case DailyPlanStatus.ACTIVE:
    case DailyPlanStatus.PREPARED:
    case DailyPlanStatus.DRAFT:
      return <GridDayPlanActive plan={plan} />
    case DailyPlanStatus.COMPLETED:
      return <GridDayPlanCompleted plan={plan} />
    default:
      return <></>
  }
};
