import { Reducer } from 'react';
import { Drawer } from '../../Api/Dto/CommonDto';

export type DrawerReducerAction =
| { type: DrawerAction.ADD, value: Drawer<Date> }
| { type: DrawerAction.REMOVE, value: Drawer<any> }
| { type: DrawerAction.SET, values: Drawer<Date>[] }
| { type: DrawerAction.UPDATE, value: Drawer<Date> }

export enum DrawerAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET = 'SET',
  UPDATE = 'UPDATE',
}

export const DrawerReducer: Reducer<Drawer<Date>[], DrawerReducerAction> = (state, action) => {
  switch (action.type) {
    case DrawerAction.ADD:
      return [...state, action.value];

    case DrawerAction.REMOVE:
      return state.filter(element => element.id !== action.value.id);

    case DrawerAction.SET:
      return [...action.values];

    case DrawerAction.UPDATE:
      return state.map(element => element.id === action.value.id
        ? action.value
        : element
      );

    default:
      throw new Error('DrawerReducer:: Undefined action');
  }
}