import React, { Dispatch, useRef } from 'react';
import glamorous from 'glamorous';
import { Color, BoxShadow, ZIndexLevel } from '../../Styles';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';
import { MenuItemProps } from '../../../Navigation/Interfaces';
import { Link } from 'react-router-dom';
import useI18n from '../../Hooks/useI18n';

interface Props {
  isActive: boolean;
  setIsActive: Dispatch<React.SetStateAction<boolean>>;
}

interface ElementProps {
  isActive: boolean;
}

const MENU_ITEM_BORDER_RADIUS = '4px';

const Element = glamorous.div<ElementProps>(
  {
    position: 'absolute',
    background: Color.white,
    borderRadius: '4px',
    right: '15px',
    top: '60px',
    boxShadow: BoxShadow.default,
    minWidth: '175px',

    transitionProperty: 'transform, opacity, visibility',
    transitionDuration: '300ms',
    zIndex: ZIndexLevel.level2,
  },
  ({ isActive }) => ({
    opacity: isActive ? 1 : 0,
    visibility: isActive ? 'visible' : 'hidden',
    transform: `translate3d(0, ${isActive ? '0' : '5px'}, 0)`,
  })
);

const MenuItem = glamorous(Link)({
  display: 'flex',
  fontSize: '14px',
  padding: '15px',
  textDecoration: 'none',
  color: Color.darkGreyBlue,
  lineHeight: '18px',

  transitionProperty: 'color, background-color',
  transitionDuration: '150ms',

  ':hover': {
    backgroundColor: Color.twilight,
    color: Color.white,
  },

  '&:first-child': {
    borderTopLeftRadius: MENU_ITEM_BORDER_RADIUS,
    borderTopRightRadius: MENU_ITEM_BORDER_RADIUS,
  },
  '&:last-child': {
    borderBottomLeftRadius: MENU_ITEM_BORDER_RADIUS,
    borderBottomRightRadius: MENU_ITEM_BORDER_RADIUS,
  },
});

const Icon = glamorous.i({
  fontSize: '18px',
  marginRight: '18px',
});

const Text = glamorous.span({

});

const menuItems: MenuItemProps[] = [
  {
    name: 'Your profile',
    icon: 'person',
    url: '/profile',
  },
  {
    name: 'Log out',
    icon: 'forward',
    url: '/logout',
  }
];

export const UserProfileDropdown = ({ isActive, setIsActive }: Props) => {
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsActive(false));
  const { t } = useI18n();

  return (
    <Element isActive={isActive} innerRef={dropdownRef}>
      {menuItems.map((menuItem, key) => <MenuItem key={key} to={menuItem.url}>
        <Icon className="material-icons">{menuItem.icon}</Icon><Text>{t(menuItem.name)}</Text>
      </MenuItem>)}
    </Element>
  )
}