import React, { ReactNode, FormEvent } from 'react';
import { ThingTheme } from './common';
import glamorous from 'glamorous';
import { Color, BoxShadow, BorderDotted } from '../Styles';

interface Props {
  theme?: ThingTheme;
  onClick?: (event: FormEvent) => void | undefined;
  children: ReactNode;
}

interface ContainerProps {
  theme: ThingTheme;
  canClick: boolean;
}

const getStylesByTheme = (theme: ThingTheme) => {
  switch (theme) {
    case ThingTheme.DEFAULT_SELECTED:
      return {
        boxShadow: BoxShadow.selected,
        borderBottom: `1px solid ${Color.transparent}`,
        borderColor: Color.cloudyBlue,
        backgroundColor: Color.silver,
      }
    case ThingTheme.EDIT_MODE:
      return {
        borderColor: Color.transparent,
        backgroundColor: Color.transparent,
        padding: 0,
        ...BorderDotted(Color.dark44),
      }
    case ThingTheme.HIDDEN:
      return {
        display: 'none',
      }
    case ThingTheme.PREPARED_PLAN:
      return {
        backgroundColor: 'hsla(0,0%,100%,.45)',
        borderColor: Color.transparent,
        borderBottom: `1px solid ${Color.silver}`
      }
    case ThingTheme.PREPARED_PLAN_EDIT_MODE_READONLY:
      return {
        backgroundColor: 'hsla(0,0%,100%,.45)',
        borderColor: Color.transparent,
        borderBottom: `1px solid ${Color.silver}`,
        cursor: 'normal',
        opacity: 0.3,

        ':hover': {
          cursor: 'normal',
        }
      }
    case ThingTheme.COMPLETED_PLAN:
      return {
        borderColor: Color.transparent,
        borderBottom: `1px solid ${Color.silver}`,
        backgroundColor: Color.transparent,
      }
    case ThingTheme.COMPLETED_PLAN_NO_BORDER:
        return {
          borderColor: Color.transparent,
          backgroundColor: Color.transparent,
          marginBottom: '15px',
        }
    case ThingTheme.DEFAULT:
    default:
      return {
        backgroundColor: Color.white,
        boxShadow: BoxShadow.default,
        borderBottom: `1px solid ${Color.transparent}`,
        borderColor: Color.transparent,
      }
  }
}

const Container = glamorous.div<ContainerProps>(
  {
    display: 'flex',
    padding: '11px 13px',
    borderWidth: '1px',
    borderStyle: 'solid',
    margin: '1px',
    transitionProperty: 'border-color, background-color',
    transitionDuration: '0.15s',
    alignItems: 'center',
  },
  ({ theme, canClick }) => ({
    ...getStylesByTheme(theme),
    cursor: canClick ? 'pointer' : 'default',
  }),
);

export const ThingContainer = ({ onClick, theme = ThingTheme.DEFAULT, children }: Props) => (
  // @ts-ignore
  <Container canClick={onClick !== undefined} onClick={event => onClick !== undefined && onClick(event)} theme={theme}>
    {children}
  </Container>
);