import React, { ReactNode, useContext } from 'react';
import glamorous from 'glamorous';
import { CALENDAR_OVERVIEW_HIDDEN_WIDTH } from '../common';
import { CalendarContext } from '../Contexts/CalendarContext';
import { AppStatus } from '../../Common/AppStatus';

interface Props {
  children: ReactNode;
}

interface ContainerProps {
  isLoading: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: `calc(100% - ${CALENDAR_OVERVIEW_HIDDEN_WIDTH})`,
    transition: 'opacity, .3s',
  },
  ({ isLoading }) => ({
    opacity: isLoading ? .5 : 1,
  })
);

export const CalendarContentHoc = ({ children }: Props) => {
  const { calendarStatus } = useContext(CalendarContext);

  return (
    <Container isLoading={calendarStatus === AppStatus.LOADING}>{children}</Container>
  )
};