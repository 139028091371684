import React from 'react';
import glamorous from 'glamorous';
import { Small } from '../../Common/Typography';
import { Color, BoxShadow, Unselectable } from '../../Common/Styles';
import { FormErrors } from '../../Common/Forms/FormErrors';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  isChecked: boolean;
  onCheck: () => void;
  errors: string[];
}

interface CheckboxProps {
  isChecked: boolean;
}

const Container = glamorous.div({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  ...Unselectable,
});

const Checkbox = glamorous.span<CheckboxProps>(
  {
    marginRight: '15px',
    borderRadius: '4px',
    boxShadow: BoxShadow.light,
    backgroundColor: Color.white,
    padding: '2px',
    fontSize: '16px',
  },
  ({ isChecked }) => ({
    color: isChecked ? Color.dark : Color.transparent,
  }),
);

const Text = glamorous(Small)({
  color: Color.white,
});

const Link = glamorous.a({
  color: Color.appleGreen,

  ':hover': {
    textDecoration: 'none',
  }
});

export const AuthTermsConditions = ({ isChecked, onCheck, errors }: Props) => {
  const { t } = useI18n();

  return (
    <>
      <Container onClick={onCheck}>
        <Checkbox isChecked={isChecked} className="material-icons">done</Checkbox>
        <Text>
          {t('I agree with')} <Link href="https://tauplan.com/en/terms-and-conditions" target="_blank">{t("terms and conditions")}</Link>
        </Text>
      </Container>
      <FormErrors errors={errors} />
    </>
  );
}
