import { AxiosError, AxiosResponse } from 'axios';

interface ApiFlatError {
  error: string;
  message: string;
  statusCode: number;
}

interface ApiError {
  property: string;
  constraints: { [key: string]: string };
}

function cleanErrors<T = any>(errors: T, deepClone: boolean = false) {
  const newState = deepClone ? JSON.parse(JSON.stringify(errors)) : { ...errors };

  Object.keys(newState).forEach(key => {
    newState[key] = [];
  });

  return newState;
}

export function handleErrors<T = any>(errors: T, errorResponse: AxiosError) {
  if (!errorResponse.response) {
    throw new Error('Missing response property in error handling');
  }

  const newState = cleanErrors<T>(errors);
  const { data } = errorResponse.response as AxiosResponse<ApiFlatError> & AxiosResponse<ApiError[]>;

  if (data.message && typeof data.message === 'string') {
    const property = Object.keys(errors)[0];

    newState[property] = [data.message];

    return newState;
  }

  if (!data.forEach) {
    newState[Object.keys(newState)[0]] = ['Something went wrong.'];

    return newState;
  }

  data.forEach((error: any) => {
    newState[error.property] = Object.values(error.constraints);
  });

  return newState;
}

