import { Dispatch, createContext } from 'react';

interface ShowMobileThingsContextProps {
  showMobileThings: boolean;
  setShowMobileThings: Dispatch<boolean>;
}

type ShowMobileThingsState = [boolean, Dispatch<boolean>];

export const SetShowMobileThingsContext = ([showMobileThings, setShowMobileThings]: ShowMobileThingsState): ShowMobileThingsContextProps => ({
  showMobileThings,
  setShowMobileThings,
});

export const ShowMobileThingsContext = createContext<ShowMobileThingsContextProps>({} as unknown as ShowMobileThingsContextProps);
