import { ApiRequest } from '../ApiRequest';
import { AxiosPromise } from 'axios';
import { GenerateTokenDto } from '../Dto/AuthDto';
import { User } from '../Dto/CommonDto';
import { IsoDate } from '../Interface';

export class AuthTokenApiClient {
  constructor(
    private readonly guestApiRequest: ApiRequest,
    private readonly organizationTokenApiRequest: ApiRequest,
    private readonly refreshTokenApiRequest: ApiRequest,
    private readonly accessTokenApiRequest: ApiRequest,
  ) {}

  generateAccessToken(): Promise<void> {
    return this.organizationTokenApiRequest
      .post<GenerateTokenDto>('access-token', {})
      .then(({ data }) => this.setToken(this.accessTokenApiRequest, data.hash));
  }

  generateOrganizationToken(organizationId?: string): Promise<void> {
    const url = 'organization-token' + (organizationId ? `/${organizationId}` : '');

    return this.refreshTokenApiRequest
      .post<GenerateTokenDto>(url, {})
      .then(({ data }) => this.setToken(this.organizationTokenApiRequest, data.hash));
  }

  verifyActivationToken(token: string): AxiosPromise<User<IsoDate>> {
    return this.guestApiRequest.get<User<IsoDate>>('activation-token', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  verifyResetPasswordToken(token: string): AxiosPromise {
    return this.guestApiRequest.head('reset-password-token', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private setToken(apiRequest: ApiRequest, token: string): void {
    apiRequest.tokenContainer.setToken(token);
  }
}
