import { GrouppedThings, GenericDate, Drawer, Thing } from '../../Api/Dto/CommonDto';
import { Dispatch, createContext } from 'react';
import { ThingsReducerAction, ThingsAction } from '../Reducers/ThingsReducer';

interface ThingsActionsProps {
  add: (drawer: Drawer<GenericDate>, thing: Thing<Date>) => void;
  addMany: (drawer: Drawer<GenericDate>, things: Thing<Date>[]) => void;
  remove: (drawer: Drawer<GenericDate>, thing: Thing<Date>) => void;
  removeMany: (drawer: Drawer<GenericDate>, things: Thing<Date>[]) => void;
  setDrawer: (drawer: Drawer<GenericDate>, things: Thing<Date>[]) => void;
  update: (drawer: Drawer<GenericDate>, thing: Thing<Date>) => void;
  count: (drawer: Drawer<GenericDate>) => number;
}

interface ThingsContextProps {
  things: GrouppedThings<Date>;
  thingsActions: ThingsActionsProps;
}

const thingsActions = (things: GrouppedThings<Date>, dispatch: Dispatch<ThingsReducerAction>): ThingsActionsProps => ({
  add: (drawer, thing) => dispatch({ type: ThingsAction.ADD, value: { drawer, thing } }),
  addMany: (drawer, things) => dispatch({ type: ThingsAction.ADD_MANY, value: { drawer, things } }),
  remove: (drawer, thing) => dispatch({ type: ThingsAction.REMOVE, value: { drawer, thing } }),
  removeMany: (drawer, things) => dispatch({ type: ThingsAction.REMOVE_MANY, value: { drawer, things } }),
  setDrawer: (drawer, things) => dispatch({ type: ThingsAction.SET, value: { drawer, things } }),
  update: (drawer, thing) => dispatch({ type: ThingsAction.UPDATE, value: {
    drawer,
    thing,
  } }),
  count: (drawer) => things[drawer.id] !== undefined ? things[drawer.id].length : 0,
});

export const SetThingsContext = (things: GrouppedThings<Date>, dispatch: Dispatch<ThingsReducerAction>) => ({
  things,
  thingsActions: thingsActions(things, dispatch),
});

export const ThingsContext = createContext<ThingsContextProps>({} as unknown as ThingsContextProps);
