import React, { ReactNode } from 'react';
import glamorous from 'glamorous';
import { Color } from '../Styles';

interface Props {
  children: ReactNode;
}

interface ContainerProps {
  
}

const Container = glamorous.div<ContainerProps>(
  {
    backgroundColor: Color.paleGrey,
    position: 'absolute',
    width: '100%',
    height: '100%',
  }
);

const Content = glamorous.section(
  {
    height: '100%',
    width: '100%',
    display: 'flex',
  }
);


const PageContent = glamorous.div(
  {
    flex: '1 1 auto',
    overflowX: 'hidden',
  }
)

export const BlankTheme = ({ children }: Props) => (
  <Container>
    <Content>
      <PageContent>
        {children}
      </PageContent>
    </Content>
  </Container>
)
