import React from 'react';
import { Input } from '../../Common/Forms/Input';
import { InputProps } from '../../Common/Interface';

interface Props extends InputProps {
  icon?: string;
  errors?: string[];
  autoComplete?: boolean;
};

export const AuthInput = ({
  name,
  value,
  type = 'text',
  placeholder = '',
  onChange,
  icon,
  errors = [],
  autoComplete = true,
}: Props) => (
  <Input
    type={type}
    placeholder={placeholder}
    name={name}
    value={value || ''}
    onChange={onChange}
    autoComplete={autoComplete}
    icon={icon}
    errors={errors}
  />
);
