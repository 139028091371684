import glamorous from 'glamorous';
import { Color, ZIndexLevel, Size } from './Styles';

const DEFAULT_PADDING = '15px 10px';

interface ModalProps {
  isVisible: boolean;
}

interface ModalHeaderProps {
  size: Size;
}

export const ModalHeader = glamorous.div<ModalHeaderProps>(
  {
    textAlign: 'center',
    lineHeight: 1,
    padding: DEFAULT_PADDING,
    color: Color.darkGreyBlue,
    fontWeight: 500,
  },
  ({ size }) => {
    switch (size) {
      case Size.small:
        return {
          fontSize: '12px',
        };
      default:
        return {
          fontSize: '14px',
        };
    }
  }
);

export const ModalFooter = glamorous.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: DEFAULT_PADDING,
});

export const Modal = glamorous.div<ModalProps>(
  {
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    position: 'absolute',
    zIndex: ZIndexLevel.modal,
    width: '200px',
    transitionProperty: 'opacity, transform, visibility',
    transitionDuration: '300ms',
    borderRadius: '4px',
  },
  ({ isVisible }) => ({
    transform: `translate3d(0, ${isVisible ? 0 : 10}px, 0)`,
    visibility: isVisible ? 'visible' : 'hidden',
    opacity: isVisible ? 1 : 0,
  }),
);
