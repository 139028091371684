import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { UpdateUserDto } from '../Dto/UserDto';
import { User } from '../Dto/CommonDto';
import { IsoDate, Uuid } from '../Interface';
import { transformEntity } from '../Helpers';

const transformUser = (user: User<IsoDate>): User<Date> => transformEntity<User<Date>>(user);

export class UserApiClient extends AccessTokenApiClient {
  update(updateUserDto: UpdateUserDto): Promise<User<Date>> {
    return this.apiRequest
      .put<User<IsoDate>>('', updateUserDto)
      .then(({ data }) => transformUser(data));
  }
}
