import React, { useContext } from 'react';
import { TemplateContext, ModalTemplate } from './Contexts/TemplateContext';
import glamorous from 'glamorous';
import { Color, ZIndexLevel, hexToRgba, BoxShadow, MediaQuerySize, ScrollbarHidden, MediaQuery } from './Styles';
import { ThingsLimitModal } from './Modals/ThingsLimitModal';
import { DrawersLimitModal } from './Modals/DrawersLimitModal';
import { ProTrialSuccessModal } from './Modals/ProTrialSuccessModal';

interface ContainerProps {
  isVisible: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    backgroundColor: hexToRgba(Color.dark, 0.7),
    zIndex: ZIndexLevel.globalModal,
    overflowY: 'scroll',
  ...ScrollbarHidden,
  },
  ({ isVisible }) => ({
    visibility: isVisible ? 'visible' : 'hidden',
  }),
);

const Modal = glamorous.div({
  backgroundColor: Color.white,
  borderRadius: '4px',
  boxShadow: BoxShadow.default,
  maxWidth: MediaQuerySize.maxMobileSize,
  margin: '35px auto 0',

  [MediaQuery.mobileOnly]: {
    margin: 0,
  }
});

export const GlobalModal = () => {
  const { modalTemplate } = useContext(TemplateContext);

  const loadView = () => {
    switch (modalTemplate) {
      case ModalTemplate.DRAWERS_EXCEEDED:
        return <DrawersLimitModal />
      case ModalTemplate.THINGS_EXCEEDED:
        return <ThingsLimitModal />
      case ModalTemplate.WELCOME_PRO:
        return <ProTrialSuccessModal />
      case ModalTemplate.HIDDEN:
      default:
        return <div></div>;
    }
  }

  return (
    <Container isVisible={modalTemplate !== ModalTemplate.HIDDEN}>
      <Modal>
        {loadView()}
      </Modal>
    </Container>
  );
};
