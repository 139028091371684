import glamorous from 'glamorous';
import { Link } from 'react-router-dom';
import { Color } from './Styles';

interface ContainerProps {
  isExpanded?: boolean;
}

export const MobileSubNavigation = glamorous.div<ContainerProps>(
  {
    display: 'flex',
    alignItems: 'center',
  },
  ({ isExpanded = false }) => ({
    justifyContent: isExpanded ? 'space-around' : 'space-between',
  })
);

export const MobileNavLink = glamorous(Link)({
  cursor: 'pointer',
  color: Color.dark,
  padding: '5px 10px',
  textDecoration: 'none',
  fontSize: '28px',
});

export const MobileNavButton = glamorous.i({
  cursor: 'pointer',
  color: Color.dark,
  padding: '10px 10px',
  fontSize: '21px',
});