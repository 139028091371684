import React, { useState, FormEvent } from 'react';
import { AuthContainer } from '../../Common/AuthContainer';
import { AuthSubmit } from '../Common/AuthSubmit';
import { AuthInput } from '../Common/AuhInput';
import { AuthInputPassword } from '../Common/AuthInputPassword';
import { handleEntityChange } from '../../Common/FormsHelper';
import { AuthForm } from '../Common/AuthForm';
import { H1 } from '../Common/AuthTypography';
import { AuthClient, AuthTokenClient } from '../../Api';
import { Redirect } from 'react-router-dom';
import { handleErrors } from '../../Common/Forms/FormHandler';

interface EntityProps {
  email: string | null;
  password: string | null;
}

interface EntityErrorsProps {
  email: string[];
  password: string[];
}

export const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [entity, setEntity] = useState<EntityProps>({
    email: null,
    password: null,
  });
  const [errors, setErrors] = useState<EntityErrorsProps>({
    email: [],
    password: [],
  });

  const handleChange = (event: FormEvent) => !isLoading && handleEntityChange(event, setEntity, entity);

  const handleSubmit = async (event: FormEvent) => {
    if (isLoading === true) {
      return;
    }

    event.preventDefault();
    
    setIsLoading(true);

    if (!entity.email || !entity.password) {
      setIsLoading(false);
      return;
    }

    const response = await AuthClient
      .signIn(entity.email, entity.password)
      .catch(err => {
        setIsLoading(false);
        setErrors(handleErrors(errors, err));
      });

    if (!response) {
      setIsLoading(false);
      return;
    }

    AuthTokenClient.generateOrganizationToken()
      .then(() => {
        localStorage.removeItem('access_token');

        setIsLoggedIn(true);
      })
      .catch(err => {
        setIsLoggedIn(false);
        setIsLoading(false);
        setErrors(handleErrors(errors, err));
      });
  }

  if (isLoggedIn) {
    return <Redirect to="/hello" />;
  }

  return (
    <AuthContainer isLoading={isLoading}>
      <H1>Sign in</H1>
      <AuthForm method="post" onSubmit={handleSubmit}>
        <AuthInput
          name="email"
          type="email"
          placeholder="Your e-mail address"
          icon="email"
          value={entity.email || ''}
          errors={errors.email || []}
          onChange={handleChange}
          autoComplete={false}
        />
        <AuthInputPassword
          value={entity.password || ''}
          errors={errors.password}
          onChange={handleChange}
        />
        <AuthSubmit
          buttonText="Log in"
          alterHref="/forgot-password"
          alterText="I forgot password"
        />
      </AuthForm>
    </AuthContainer>
  );
};
