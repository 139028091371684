import { Reducer } from 'react';
import { Thing } from '../../Api/Dto/CommonDto';

export type CalendarThingsReducerAction =
| { type: CalendarThingsAction.ADD, value: Thing<Date> }
| { type: CalendarThingsAction.UPDATE, value: Thing<Date> }
| { type: CalendarThingsAction.UPDATE_MANY, values: Thing<Date>[] }
| { type: CalendarThingsAction.UPSERT, value: Thing<Date> }
| { type: CalendarThingsAction.REMOVE, value: Thing<Date> }
| { type: CalendarThingsAction.SET_ALL, values: Thing<Date>[] }

export enum CalendarThingsAction {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  UPDATE_MANY = 'UPDATE_MANY',
  UPSERT = 'UPSERT',
  REMOVE = 'REMOVE',
  SET_ALL = 'SET_ALL',
}

export const CalendarThingsReducer: Reducer<Thing<Date>[], CalendarThingsReducerAction> = (state, action) => {
  switch (action.type) {
    case CalendarThingsAction.ADD:
      return [action.value, ...state];
    case CalendarThingsAction.UPDATE:
      return state.map(thing => thing.id === action.value.id
        ? action.value
        : thing
      );
    case CalendarThingsAction.UPDATE_MANY:
      return state.map(thing => action.values.find(newThing => newThing.id === thing.id) || thing);
    case CalendarThingsAction.UPSERT:
      return (state.find(thing => thing.id === action.value.id) === undefined)
        ? [...state, action.value].sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
        : state.map(thing => thing.id === action.value.id
            ? action.value
            : thing
          );
    case CalendarThingsAction.REMOVE:
      return state.filter(thing => thing.id !== action.value.id);
    case CalendarThingsAction.SET_ALL:
      return [...action.values];
    default:
        throw new Error('CalendarThingsReducer: Undefined action');
  }
}