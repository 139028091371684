import React from 'react';
import EditDrawer1 from '../../assets/images/whats-new/1-1-0/edit-drawer-1.png';
import EditDrawer2 from '../../assets/images/whats-new/1-1-0/edit-drawer-2.png';
import EditThing1 from '../../assets/images/whats-new/1-1-0/edit-thing-1.png';
import EditThing2 from '../../assets/images/whats-new/1-1-0/edit-thing-2.png';
import NewCalendar from '../../assets/images/whats-new/1-1-0/new-calendar.png';
import { ArticleContainer, ArticleSection, ArticleTitle, ArticleDescription } from '../styles';
import { ImagesTransformation } from '../ImagesTransformation';
import { ImagesWithList } from '../ImagesWithList';
import useI18n from '../../Common/Hooks/useI18n';

export const V110 = () => {
  const { t } = useI18n();
  
  return (
    <ArticleContainer>
      <ArticleSection>
        <ArticleTitle>{t("Edit a drawer's name")}</ArticleTitle>
        <ImagesTransformation
          imageLeft={EditDrawer1}
          imageRight={EditDrawer2}
          width={320}
        />
      </ArticleSection>

      <ArticleSection>
        <ArticleTitle>{t("Edit a thing's title")}</ArticleTitle>
        <ImagesTransformation
          imageLeft={EditThing1}
          imageRight={EditThing2}
          width={320}
        />
      </ArticleSection>

      <ArticleSection>
        <ArticleTitle>{t('Improved calendar usability')}</ArticleTitle>
        <ImagesWithList
          image={NewCalendar}
          imageWidth={200}
          title="Changes:"
          listItems={[
            "Today's plan is easier to be noticed",
            'Simplified create plan flow',
            'Quickly add things in the plan component',
            'Fixed some minor bugs',
            'Improved performance',
          ]}
        />
      </ArticleSection>
    </ArticleContainer>
  );
}
