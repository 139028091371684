import React from 'react';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';
import { Route, RouteComponentProps } from 'react-router-dom';
import { ProfileIndex } from './ProfileIndex';

export const Profile = ({ match }: RouteComponentProps) => (
  <DefaultTheme>
    <Route exact path={`${match.path}`} component={ProfileIndex} />
  </DefaultTheme>
);
