import { FormEvent } from 'react';

interface TargetNameValueEvent {
  target: {
    name: string;
    value: any;
  }
}

export function handleEntityChange<T = any>(
  event: FormEvent<Element | HTMLFormElement>,
  setEntity: (entity: T) => void,
  entity: T,
  deepClone: boolean = false,
): void {
  const { target } = event as unknown as TargetNameValueEvent;
  const newEntity = deepClone
    ? JSON.parse(JSON.stringify(entity))
    : { ...entity };

    newEntity[target.name] = target.value;

    setEntity(newEntity);
}