import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { UserAndProjectsDto } from '../Dto/UserProjectDto';
import { IsoDate } from '../Interface';
import { transformEntity } from '../Helpers';
import { User, Drawer } from '../Dto/CommonDto';

export class UserProjectApiClient extends AccessTokenApiClient {
  getUserAndProjects(): Promise<UserAndProjectsDto<Date>> {
    return this.apiRequest
      .get<UserAndProjectsDto<IsoDate>>('')
      .then(({ data }) => {
        const { user, projects } = data;

        return {
          user: transformEntity<User<Date>>(user),
          projects: projects
            .map(project => transformEntity<Drawer<Date>>(project))
            .sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
        }
      });
  }
}
