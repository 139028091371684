import { createContext, Dispatch } from 'react';
import { GrouppedDailyPlans, DailyPlan, Thing } from '../../Api/Dto/CommonDto';
import { ThingStatus } from "../../Api/Dto/ThingDto";
import { MonthlyPlansReducerAction, MonthlyPlansAction } from '../Reducers/MonthlyPlansReducer';

interface MonthlyPlansActionsProps {
  add: (dailyPlan: DailyPlan<Date>) => void;
  remove: (dailyPlan: DailyPlan<Date>) => void;
  setPlans: (grouppedDailyPlans: GrouppedDailyPlans<Date>) => void;
  getPlan: (dayNumber: number) => DailyPlan<Date> | undefined;
  updateThings: (dailyPlan: DailyPlan<Date>, things: Thing<Date>[]) => void,
  hasSameThings: (sourcePlan: DailyPlan<Date>, thingsToCompare: Thing<Date>[]) => boolean;
  calculatePercentage: (dailyPlan: DailyPlan<Date>) => number,
}

interface MonthlyPlansContextProps {
  monthlyPlans: GrouppedDailyPlans<Date>;
  monthlyPlansActions: MonthlyPlansActionsProps;
}

const monthlyPlansActions = (
  monthlyPlans: GrouppedDailyPlans<Date>,
  dispatch: Dispatch<MonthlyPlansReducerAction>
): MonthlyPlansActionsProps => ({
  add: (dailyPlan) => dispatch({ type: MonthlyPlansAction.ADD, value: dailyPlan }),
  remove: (dailyPlan) => dispatch({ type: MonthlyPlansAction.REMOVE, value: dailyPlan }),
  setPlans: (grouppedDailyPlans) => dispatch({ type: MonthlyPlansAction.SET_ALL, values: grouppedDailyPlans }),
  getPlan: (dayNumber) => monthlyPlans[dayNumber],
  updateThings: (dailyPlan, things) => dispatch({ type: MonthlyPlansAction.UPDATE_THINGS, values: { plan: dailyPlan, things } }),
  hasSameThings: (sourcePlan, thingsToCompare) => {
    if (sourcePlan.things.length !== thingsToCompare.length) {
      return false;
    }

    return thingsToCompare.map(thing => thing.id).sort().join('') === sourcePlan.things.map(thing => thing.id).sort().join('');
  },
  calculatePercentage: (dailyPlan) => (
    Number(
      ((dailyPlan.things.filter(thing => thing.status !== ThingStatus.TO_DO).length / dailyPlan.things.length) * 100)
      .toFixed()
    )
  )
});

export const SetMonthlyPlansContext = (
  monthlyPlans: GrouppedDailyPlans<Date>,
  dispatch: Dispatch<MonthlyPlansReducerAction>,
): MonthlyPlansContextProps => ({
  monthlyPlans,
  monthlyPlansActions: monthlyPlansActions(monthlyPlans, dispatch),
});

export const MonthlyPlansContext = createContext<MonthlyPlansContextProps>({} as unknown as MonthlyPlansContextProps);