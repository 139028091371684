import React, { useContext, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalFooter } from '../../Common/Modal';
import glamorous from 'glamorous';
import { Size, Color, ScrollbarHidden, MediaQuery } from '../../Common/Styles';
import { Button } from '../../Common/Forms/Buttons';
import { Drawer } from '../../Api/Dto/CommonDto';
import { DrawerContext } from '../../Common/Contexts/DrawerContext';
import { DrawerElement } from './DrawerElement';
import { ThingClient } from '../../Api';
import { ThingsContext } from '../Contexts/ThingsContext';
import { SelectedThingsContext } from '../Contexts/SelectedThingsContext';
import { hasItem } from '../../Common/ArrayHelper';
import { Uuid } from '../../Api/Interface';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  drawer: Drawer<Date>;
  isVisible: boolean;
  toggleModal: () => void;
}

const ModalContainer = glamorous(Modal)({
  [MediaQuery.tableDesktop]: {
    left: '130px',
    top: '50px',
  },
  [MediaQuery.mobileOnly]: {
    right: '105px',
    bottom: '50px',
  }
});

const ModalButton = glamorous(Button)({
  marginLeft: '10px',
});

const Content = glamorous.div(
  {
    maxHeight: '150px',
    ...ScrollbarHidden,
    overflowY: 'scroll',
  }
);

export const MoveToDrawer = ({ drawer, isVisible, toggleModal }: Props) => {
  const { t } = useI18n();
  const { drawers } = useContext(DrawerContext);
  const { things, thingsActions } = useContext(ThingsContext);
  const { selectedThings } = useContext(SelectedThingsContext);

  const [selectedDrawer, setSelectedDrawer] = useState<Drawer<Date> | null>(null);

  useEffect(() => {
    selectedDrawer !== null && setSelectedDrawer(null);
  }, [isVisible]);

  const toggleSelection = (drawerToSelect: Drawer<Date>) => selectedDrawer === null
    ? setSelectedDrawer(drawerToSelect)
    : selectedDrawer.id === drawerToSelect.id
      ? setSelectedDrawer(null)
      : setSelectedDrawer(drawerToSelect);

  const move = () => {
    if (selectedDrawer === null) {
      return;
    }

    const thingsToMove = things[drawer.id].filter(thing => hasItem<Uuid>(selectedThings, thing.id))
    

    ThingClient
      .moveToDrawer(thingsToMove.map(thing => thing.id), selectedDrawer.id)
      .then(data => {
        thingsActions.removeMany(drawer, thingsToMove);
        thingsActions.addMany(selectedDrawer, data);
        toggleModal();
      })
      .catch(err => console.error(err));
  }

  return (
    <ModalContainer isVisible={isVisible}>
      <ModalHeader size={Size.small as never}>{t('Select where would you like to move things')}</ModalHeader>
      <Content>
        {drawers
          .filter(userDrawer => userDrawer.id !== drawer.id)
          .map(userDrawer => <DrawerElement 
            key={userDrawer.id}
            drawer={userDrawer}
            isSelected={selectedDrawer !== null && selectedDrawer.id === userDrawer.id}
            onClick={() => toggleSelection(userDrawer)}
          />)
        }
      </Content>
      <ModalFooter>
        <ModalButton
          isActive={true}
          outlined
          size={Size.small as any}
          color={Color.cherryRed}
          onClick={toggleModal}
        >
          {t('Cancel')}
        </ModalButton>
        <ModalButton
          isActive={selectedDrawer !== null}
          size={Size.small as any}
          color={Color.appleGreen}
          onClick={move}
        >
          {t('Move')}
        </ModalButton>
      </ModalFooter>
    </ModalContainer>
  )
};
