import React, { useContext } from 'react';
import { Drawer } from '../../Api/Dto/CommonDto';
import glamorous from 'glamorous';
import { Avatar } from '../../Common/Avatar';
import { DrawerContext } from '../../Common/Contexts/DrawerContext';
import { AvatarSize } from '../../Common/Interface';
import { Color, Unselectable } from '../../Common/Styles';

interface Props {
  drawer: Drawer<Date>;
  isSelected: boolean;
  onClick: () => void;
}

interface IsSelectedProps {
  isSelected: boolean;
}

const Container = glamorous.div<IsSelectedProps>(
  {
    display: 'flex',
    alignItems: 'center',
    transitionProperty: 'color, background-color',
    transitionDuration: '.15s',
    padding: '10px 15px',
    cursor: 'pointer',
  },
  ({ isSelected }) => ({
    backgroundColor: isSelected ? Color.darkGreyBlue : Color.transparent,

    ':hover': {
      backgroundColor: isSelected ? Color.darkGreyBlue : Color.silver,
    },
  })
);

const Title = glamorous.span<IsSelectedProps>(
  {
    flex: 1,
    marginLeft: '15px',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'capitalize',
    ...Unselectable,
  },
  ({ isSelected }) => ({
    color: isSelected ? Color.white : Color.darkGreyBlue,
  })
)

export const DrawerElement = ({ drawer, isSelected, onClick }: Props) => {
  const { drawerActions } = useContext(DrawerContext)

  return (
    <Container isSelected={isSelected} onClick={onClick}>
      <Avatar
        color={drawerActions.getColor(drawer)}
        text={drawerActions.getCodename(drawer)}
        size={AvatarSize.SMALL}
      />
    <Title isSelected={isSelected}>{drawer.name}</Title>
    </Container>
  )
};
