import axios, { AxiosRequestConfig, AxiosPromise, AxiosError } from 'axios';
import { Config } from './../Config';
import { TokenContainer } from './TokenContainer';
import { GenerateTokenDto } from './Dto/AuthDto';
import { organizationToken, accessToken } from './Tokens';
import { get as getCookie, remove as removeCookie } from 'js-cookie';

const tokenUrl: string = `${Config.API_URL}/auth/token`;

const parseToAxiosRequest = (config: AxiosRequestConfig, bearerToken: string): AxiosPromise | undefined => {
  const { method, headers } = config;

  const url = config.url || '';
  const data = config.data || null;

  headers.Authorization = `Bearer ${bearerToken}`;

  switch (method) {
    case 'get':
      return axios.get(url, { headers });
    case 'post':
      return axios.post(url, data, { headers });
    case 'put':
      return axios.put(url, data, { headers });
    case 'head':
      return axios.head(url, { headers });
    case 'delete':
      throw new Error('unsupported method');
  }
};

const generateToken = async (
  tokenContainer: TokenContainer,
  uri: string,
  token: string | null
): Promise<string> => {
  const res = await axios.post<GenerateTokenDto>(`${tokenUrl}/${uri}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { hash } = res.data;

  tokenContainer.setToken(hash);

  return hash;
}

export const GuestTokenHandler = (error: AxiosError) => {
  throw error;
}

export const RefreshTokenHandler = () => {
  window.location.replace('/login');
}

export const OrganizationTokenHandler = async (
  error: AxiosError,
  organizationTokenContainer: TokenContainer,
) => {
  const refreshToken = localStorage.getItem('refresh_token') || getCookie('refresh_token');

  try {
    if (!refreshToken) {
      throw new Error('No token');
    }

    const token = await generateToken(organizationTokenContainer, 'organization-token', refreshToken);
    const accessTokenHash = await generateToken(accessToken, 'access-token', token);

    return parseToAxiosRequest(
      error.config,
      accessTokenHash,
    );
  } catch (e) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('organization_token');
    localStorage.removeItem('refresh_token');
    removeCookie('refresh_token');

    return RefreshTokenHandler();
  }
}

export const AccessTokenHandler = async (error: AxiosError, accessTokenContainer: TokenContainer) => {
  const organizationTokenHash = localStorage.getItem('organization_token');

  try {
    const token = await generateToken(accessToken, 'access-token', organizationTokenHash);

    return parseToAxiosRequest(
      error.config,
      token,
    );
  } catch (e) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('organization_token');

    return OrganizationTokenHandler(error, organizationToken);
  }
}
