import React, { useState, FormEvent, useContext } from 'react';
import glamorous from 'glamorous';
import { TextSubmit } from '../../Common/Forms/TextSubmit';
import { useAppStatus } from '../../Common/Hooks/useAppStatus';
import { AppStatus } from '../../Common/AppStatus';
import { ThingClient } from '../../Api';
import { Drawer } from '../../Api/Dto/CommonDto';
import { ThingsContext } from '../Contexts/ThingsContext';
import { Color } from '../../Common/Styles';
import { DrawerTopToolsIcon } from './DrawerTopToolsIcon';
import { MoveToDrawer } from './MoveToDrawer';
import { UserDrawerID } from '../common';
import { SelectedThingsContext } from '../Contexts/SelectedThingsContext';
import { hasItem } from '../../Common/ArrayHelper';
import { Uuid } from '../../Api/Interface';
import { AxiosError } from 'axios';
import { TemplateContext, ModalTemplate } from '../../Common/Contexts/TemplateContext';

interface Props {
  drawer: Drawer<Date>;
  showTools: boolean;
  isSingleSelection: boolean;
  isEditMode: boolean;
  onSubmitUpdate: () => void;
  turnOnEditMode: () => void;
  turnOffEditMode: () => void;
  align: 'top' | 'bottom';
}

interface ContainerProps {
  align: 'top' | 'bottom';
}

interface ToolsContainerProps {
  isVisible: boolean;
}

const Container = glamorous.div<ContainerProps>(
  {
    position: 'relative',
    
  },
  ({ align }) => ({
    margin: align === 'top' ? '15px 0 0 0' : '15px 0',
  }),
);

const ToolsContainer = glamorous.div<ToolsContainerProps>(
  {
    position: 'absolute',
    backgroundColor: Color.paleGrey,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transitionProperty: 'opacity, visibility',
    transitionDuration: '.3s',
  },
  ({ isVisible }) => ({
    opacity: isVisible ? 1 : 0,
    visibility: isVisible ? 'visible' : 'hidden',
  })
)

export const DrawerTopTools = ({ drawer, showTools, isSingleSelection, isEditMode, align, turnOnEditMode, turnOffEditMode, onSubmitUpdate }: Props) => {
  const { things, thingsActions } = useContext(ThingsContext)
  const { selectedThings, selectedThingsActions } = useContext(SelectedThingsContext);
  const { templateActions } = useContext(TemplateContext);

  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);
  const [newThingName, setNewThingName] = useState<string>('');
  const [moveToDrawerVisible, setMoveToDrawerVisible] = useState<boolean>(false);

  const handleOnChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => setNewThingName(currentTarget.value);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setAppStatus(AppStatus.LOADING);

    ThingClient
      .create({ title: newThingName, drawerId: drawer.id || null })
      .then(thing => {
        setAppStatus(AppStatus.READY);
        setNewThingName('');
        thingsActions.add(drawer, thing);
      })
      .catch((err: AxiosError) => {
        err.response && err.response.status === 409 && templateActions.setModal(ModalTemplate.THINGS_EXCEEDED);

        setNewThingName('');
        setAppStatus(AppStatus.READY);
      });
  }

  const toggleMoveToDrawerModal = () => setMoveToDrawerVisible(!moveToDrawerVisible);

  const deleteThings = () => ThingClient
    .bulkDelete(selectedThings)
    .then(() => {
      thingsActions.removeMany(drawer, things[drawer.id].filter(thing => hasItem<Uuid>(selectedThings, thing.id)));
      selectedThingsActions.clear();
    })
    .catch(() => {
      selectedThingsActions.clear();
    });

  return (
    <Container align={align}>
      <TextSubmit
        value={newThingName}
        onChange={handleOnChange}
        onSubmit={handleSubmit}
        placeholder="Write a new thing here"
        buttonText="Add"
        canSubmit={newThingName.length > 0 && appStatus !== AppStatus.LOADING}
      />
      <ToolsContainer isVisible={showTools}>
        {!isEditMode && (
          <>
            <DrawerTopToolsIcon
              icon="edit"
              isVisible={showTools && isSingleSelection}
              color={Color.orange}
              onClick={turnOnEditMode}
              transitionDuration={drawer.id === UserDrawerID ? '450ms' : '300ms'}
            />
            {drawer.id === UserDrawerID && <DrawerTopToolsIcon
              icon="assignment"
              isVisible={showTools}
              color={Color.twilight}
              onClick={toggleMoveToDrawerModal}
              transitionDuration="300ms"
            />}
            <DrawerTopToolsIcon
              icon="delete"
              isVisible={showTools}
              color={Color.cherryRed}
              onClick={deleteThings}
              transitionDuration="150ms"
            />
            <DrawerTopToolsIcon
              icon="close"
              isVisible={showTools}
              color={Color.transparent}
              onClick={() => selectedThingsActions.clear()}
              transitionDuration="0ms"
            />
          </>
        )}
        {isEditMode && (
          <>
            <DrawerTopToolsIcon 
              icon="close"
              isVisible={isEditMode}
              color={Color.cherryRed}
              onClick={turnOffEditMode}
              transitionDuration="300ms"
            />
            <DrawerTopToolsIcon
              icon="done"
              isVisible={isEditMode}
              color={Color.appleGreen}
              onClick={() => onSubmitUpdate()}
              transitionDuration="150ms"
            />
          </>
        )}
      </ToolsContainer>
      {drawer.id === UserDrawerID && <MoveToDrawer
        isVisible={moveToDrawerVisible}
        toggleModal={toggleMoveToDrawerModal}
        drawer={drawer}
      />}
    </Container>
  );
}