import React, { useState } from 'react';
import { Routing } from './Navigation/Routing/Routing';
import { I18nContext, LanguageCode, getLanguageFromStorage, SetI18nContext } from './Common/Contexts/I18nContext';
// @ts-ignore
import detectBrowserLanguage from 'detect-browser-language';

const App = () => {
  const browserLanguage = (detectBrowserLanguage() || '').split('-')[0];

  const languageState = useState<LanguageCode>(getLanguageFromStorage(browserLanguage));

  return (
    <I18nContext.Provider value={SetI18nContext(languageState)}>
      <Routing />
    </I18nContext.Provider>
  );
};

export default App;