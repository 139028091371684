import React from 'react';
import glamorous from 'glamorous';
import { BoxShadow, Color, MediaQuery } from '../Common/Styles';
import useI18n from '../Common/Hooks/useI18n';

interface Props {
  image: string;
  imageWidth: number;
  title: string;
  listItems: string[];
}

interface ImageProps {
  width: string | number;
}

const Container = glamorous.div({
  display: 'flex',

  [MediaQuery.mobileTablet]: {
    flexDirection: 'column',
  }
});

const Image = glamorous.img<ImageProps>(
  {
    boxShadow: BoxShadow.light,
    margin: '0 30px 0 0',

    [MediaQuery.mobileTablet]: {
      maxWidth: '100%',
      margin: '0 auto 30px auto',
    },
  },
  ({ width }) => ({
    width: `${width}px`,
  })
);

const Details = glamorous.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const ListTitle = glamorous.p({
  padding: 0,
  margin: 0,
  color: Color.iris,
  fontWeight: 500,
  fontSize: '21px',
});

const ListContainer = glamorous.ul({
  flex: 1,
  padding: 0,
});

const ListItem = glamorous.li({
  display: 'flex',
  alignItems: 'center',
  listStyle: 'none',
  fontSize: '18px',
  color: Color.dark66,
  marginBottom: '10px',
});

const ListIcon = glamorous.i({
  marginRight: '15px',
  fontSize: '21px',
  color: Color.appleGreen,
});

export const ImagesWithList = ({ image, imageWidth, title, listItems }: Props) => {
  const { t } = useI18n();

  return (
    <Container>
      <Image src={image} width={imageWidth} />
      <Details>
        <ListTitle>{title}</ListTitle>
        <ListContainer>
          {listItems.map((item, key) => (
            <ListItem key={key}>
              <ListIcon className="material-icons">done</ListIcon> {t(item)}
            </ListItem>
          ))}
        </ListContainer>
      </Details>
    </Container>
  )
};
