import React, { useEffect } from 'react';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';
import { PageLayout } from '../Common/PageLayout';
import { DotLoader } from '../Common/Loaders/DotLoader';

export const Logout = () => {
  useEffect(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('organization_token');
    localStorage.removeItem('refresh_token');

    window.location.reload();
  }, []);

  return (
    <DefaultTheme>
      <PageLayout asFlex>
        <DotLoader />
      </PageLayout>
    </DefaultTheme>
  )
};
