import React, { useContext, useEffect } from 'react';
import glamorous from 'glamorous';
import { Color, BoxShadow, Unselectable } from '../../Common/Styles';
import { CalendarOverviewContext } from '../Contexts/CalendarOverviewContext';
import { PlanThingsContext } from '../Contexts/PlanThingsContext';
import useI18n from '../../Common/Hooks/useI18n';

const Header = glamorous.div({
  fontSize: '20px',
  color: Color.twilight,
  lineHeight: 1.2,
  fontWeight: 500,
  marginBottom: '15px',
});

const Description = glamorous.div({
  color: Color.dark74,
  fontSize: '14px',
  lineHeight: 1.29,
  letterSpacing: '-.3px',
  margin: '0',
});

const CreatePlanButton = glamorous.span({
  cursor: 'pointer',
  color: Color.white,
  backgroundColor: Color.appleGreen,
  margin: '40px auto 0 auto',
  fontSize: '16px',
  textTransform: 'none',
  padding: '11px 28px',
  borderRadius: '4px',
  boxShadow: BoxShadow.light,
  ...Unselectable,
  transition: 'opacity, .3s',

  ':hover': {
    opacity: 0.7,
  }
});

export const EmptyPlan = () => {
  const { t } = useI18n();
  const { calendarOverviewActions } = useContext(CalendarOverviewContext);
  const { planThingsActions } = useContext(PlanThingsContext);

  useEffect(() => {
    planThingsActions.setThings([]);
  }, []);

  return (
    <>
      <Header>{t('No day plan')}</Header>
      <Description>{t("You don't have plan for this day.")}</Description>
      <Description>{t('Plan it now.')}</Description>
      <CreatePlanButton onClick={calendarOverviewActions.expand}>{t('Create day plan')}</CreatePlanButton>
    </>
  );
};
