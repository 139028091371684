import React, { useEffect, useState } from 'react';
import { AlgorithmFactors } from '../Api/Dto/AlgorithmDto';
import glamorous from 'glamorous';
import { RatingTitle } from './Elements/RatingTitle';
import { RatingHint } from './Elements/RatingHint';
import { UserRating } from './Elements/UserRating';
import { RatingFactor } from './Elements/RatingFactor';
import { Rating } from './interface';
import { generatePraises, generateAdvices, DEFAULT_TEXTS_AMOUNT } from './Generators';
import { calculateRating } from './Helpers/RatingCalculator';
import { Color, MediaQuery as MQ, MediaQuerySize, ScrollbarHidden } from '../Common/Styles';
import { GoHomeButton } from './Elements/GoHomeButton';
import MediaQuery from 'react-responsive';
import useI18n from '../Common/Hooks/useI18n';

interface Props {
  factors: AlgorithmFactors;
  tasksQnt: number;
  tasksDone: number;
  userRating: number | null;
  hideView: () => void;
}

interface GoodBad {
  good: string[];
  bad: string[];
}

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',

  [MQ.mobileTablet]: {
    padding: '0 15px',
    display: 'block',
    overflow: 'scroll',
    ...ScrollbarHidden,
  },
});

const UserRatingContainer = glamorous.div({
  display: 'flex',
  marginBottom: '30px',

  [MQ.mobileTablet]: {
    flexDirection: 'column',
  },
});

const FactorsContainer = glamorous.div({
  display: 'flex',
  justifyContent: 'space-around',

  [MQ.mobileTablet]: {
    flexDirection: 'column',
  },
});

export const SmartRatingSuccess = ({ factors, tasksQnt, tasksDone, userRating, hideView }: Props) => {
  const { t } = useI18n();
  const [rating, setRating] = useState<Rating | undefined>(undefined);
  const [goodBad, setGoodBad] = useState<GoodBad>({ good: [], bad: [] });

  const generateGoodBad = (algoFactors: AlgorithmFactors, algoRating: Rating): GoodBad => ({
    good: generatePraises(algoFactors, algoRating, tasksQnt, tasksDone, DEFAULT_TEXTS_AMOUNT, t),
    bad: generateAdvices(algoFactors, algoRating, tasksQnt, tasksDone, DEFAULT_TEXTS_AMOUNT, t),
  });

  useEffect(() => {
    const calculatedRating = calculateRating(factors, tasksQnt, tasksDone);
    
    setRating(calculatedRating);
    setGoodBad(generateGoodBad(factors, calculatedRating));
  }, [factors]);

  return (
    <Container>
      { rating !== undefined && (
        <>
          <RatingTitle rating={userRating || 0} />
          <UserRatingContainer>
            <MediaQuery minWidth={MediaQuerySize.minDesktopSize}>
              <RatingHint 
                align="right"
                title={t("Praises")}
                icon="emoji_events"
                highlightColor={Color.appleGreen}
                hints={goodBad.good}
              />
              <UserRating rating={userRating || 0} tasksDone={tasksDone} tasksQnt={tasksQnt} />
              <RatingHint
                align="left"
                title={t("Advices")}
                icon="emoji_objects"
                highlightColor={Color.cherryRed}
                hints={goodBad.bad}
              />
            </MediaQuery>
            <MediaQuery maxWidth={MediaQuerySize.maxTabletSize}>
              <UserRating rating={userRating || 0} tasksDone={tasksDone} tasksQnt={tasksQnt} />
              <RatingHint 
                align="left"
                title={t("Praises")}
                icon="emoji_events"
                highlightColor={Color.appleGreen}
                hints={goodBad.good}
              />
              <RatingHint
                align="left"
                title={t("Advices")}
                icon="emoji_objects"
                highlightColor={Color.cherryRed}
                hints={goodBad.bad}
              />
            </MediaQuery>
          </UserRatingContainer>
          <FactorsContainer>
            <RatingFactor name="Consistency" value={rating.factors.consistency} delay={150} />
            <RatingFactor name="Efficiency" value={rating.factors.efficiency} delay={300} />
            <RatingFactor name="Work balance" value={rating.factors.workBalance} delay={450} />
          </FactorsContainer>
          <GoHomeButton hideView={hideView} />
        </>
      ) }
    </Container>
  )
};
