import React from 'react';
import { Thing } from '../../Api/Dto/CommonDto';
import { ThingContainer } from '../../Common/Thing/ThingContainer';
import { ThingTheme } from '../../Common/Thing/common';
import { ThingTitle } from '../../Common/Thing/ThingTitle';
import { ThingAvatar } from '../../Common/Thing/ThingAvatar';
import { ThingCompletedStatusIcon } from '../../Common/Thing/ThingCompletedStatusIcon';

interface Props {
  thing: Thing<Date>;
  isLastElement: boolean;
}

export const CompletedPlanThingElement = ({ thing, isLastElement }: Props) => (
  <ThingContainer theme={isLastElement ? ThingTheme.COMPLETED_PLAN_NO_BORDER : ThingTheme.COMPLETED_PLAN}>
    <ThingAvatar align="left" thing={thing} />
    <ThingTitle title={thing.title} />
    <ThingCompletedStatusIcon status={thing.status} />
  </ThingContainer>
);
