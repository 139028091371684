import React, { FormEvent } from 'react';
import glamorous from 'glamorous';
import { Color, BoxShadow } from '../Styles';
import { FormErrors } from './FormErrors';
import { FormLabel } from './Styles';
import useI18n from '../Hooks/useI18n';

interface Props {
  name: string;
  value: string;
  label?: string | undefined;
  placeholder?: string;
  errors?: string[];
  onChange: (event: FormEvent) => void;
  height?: number | string;
}

interface ErrorProps {
  hasErrors: boolean;
}

interface FormElementProps extends ErrorProps {
  height: number | string;
}

const Container = glamorous.div({
  marginBottom: '10px',
});

const FormElement = glamorous.textarea<FormElementProps>(
  {
    width: '100%',
    borderRadius: '4px',
    border: `1px solid ${Color.cloudyBlue}`,
    backgroundColor: Color.white,
    fontSize: '14px',
    color: Color.dark74,
    padding: '12px 16px',
    outline: 'none',

    transitionProperty: 'border-color, box-shadow',
    transitionDuration: '.15s',

    ':focus': {
      borderColor: Color.transparent,
      boxShadow: BoxShadow.light,
    }
  },
  ({ hasErrors, height }) => ({
    borderColor: hasErrors ? Color.cherryRed : Color.cloudyBlue,
    height: typeof height === 'string' ? height : `${height}px`,
  }),
);

export const TextArea = ({
  name,
  label = undefined,
  value,
  placeholder,
  onChange,
  height = 'auto',
  errors = []
}: Props) => {
  const { t } = useI18n();
  
  return (
    <Container>
      { label !== undefined && <FormLabel hasErrors={errors.length > 0}>{t(label)}</FormLabel> }
      <FormElement
        name={name}
        onChange={onChange}
        placeholder={t(placeholder || '')}
        hasErrors={errors.length > 0}
        height={height}
        value={value}
      />
      <FormErrors errors={errors} />
    </Container>
  );
}