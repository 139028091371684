import React, { FormEvent } from 'react';
import glamorous from 'glamorous';
import { Color, BoxShadow, Unselectable, Transition } from './Styles';
import useI18n from './Hooks/useI18n';

interface Props {
  isActive?: boolean;
  fullWidth?: boolean;
  color?: string;
  text: string;
  styles?: any;
  onClick: (event: FormEvent) => void;
}

interface ContainerProps {
  isActive: boolean;
  fullWidth: boolean;
  color: string;
}

const Container = glamorous.div<ContainerProps>(
  {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px',
    padding: '11px 28px',
    borderRadius: '4px',
    boxShadow: BoxShadow.light,
    ...Transition([
      ['color', .3],
      ['background-color', .3],
      ['opacity', .3],
      ['transform', .15],
    ]),
  },
  ({ isActive, fullWidth, color }) => ({
    width: fullWidth ? '100%' : 'auto',
    backgroundColor: isActive ? color : Color.disabled,
    color: isActive ? Color.white : Color.paleGrey,
    cursor: isActive ? 'pointer' : 'default',

    ':hover': {
      opacity: isActive ? 0.7 : 1,
    },

    ':active': {
      transform: isActive ? `scale(0.97)` : `scale(1)`,
    }
  }),
);

const Text = glamorous.span({
  ...Unselectable,
});

/*
 * Use the dynamic button, if you don't want a form button element,
 * but just button looked like component
 */
export const DynamicButton = ({ 
  isActive = true,
  fullWidth = false,
  color = Color.appleGreen,
  text,
  styles = {},
  onClick,
}: Props) => {
  const { t } = useI18n();
  
  return (
    <Container
      isActive={isActive}
      fullWidth={fullWidth}
      color={color}
      css={styles}
      onClick={(event) => isActive && onClick(event)}>
      <Text>{t(text)}</Text>
    </Container>
);
}
