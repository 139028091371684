import React, { FormEvent } from 'react';
import { Thing } from '../../Api/Dto/CommonDto';
import { ThingContainer } from '../../Common/Thing/ThingContainer';
import { ThingTheme } from '../../Common/Thing/common';
import { ThingTitle } from '../../Common/Thing/ThingTitle';
import { ThingAvatar } from '../../Common/Thing/ThingAvatar';

interface Props {
  thing: Thing<Date>;
  isHidden?: boolean;
  onClick?: (event: FormEvent) => void;
}

export const ThingElement = ({ thing, isHidden = false, onClick = () => {} }: Props) => (
  <ThingContainer theme={isHidden ? ThingTheme.HIDDEN : ThingTheme.DEFAULT} onClick={onClick}>
    <ThingTitle title={thing.title} />
    <ThingAvatar align="right" thing={thing} />
  </ThingContainer>
);
