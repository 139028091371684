import React from 'react';
import { MenuItemProps } from '../../../Navigation/Interfaces';
import glamorous from 'glamorous';
import { NavLink } from 'react-router-dom';
import { Color, Unselectable, hexToRgba } from '../../Styles';

interface Props extends MenuItemProps {
  onToggle?: () => void | undefined;
}

interface ContainerProps {
  isHighlighted: boolean;
}

const Container = glamorous(NavLink)(
  {
    textDecoration: 'none',
    transition: 'background-color, .15s',
    padding: '10px 20px',
    height: '45px',
    ...Unselectable,
    position: 'relative',
    color: Color.white,
    
    '&.active i': {
      opacity: 1,
    },
    
    '&.highlighted': {
      color: hexToRgba(Color.orange, 0.9),
    }
  },
);

const Icon = glamorous.i<ContainerProps>(
  {
    fontSize: '24px',
    lineHeight: '26px',
    textAlign: 'center',
    transitionProperty: 'opacity, color',
    transitionDuration: '.15s',
  },
  ({ isHighlighted }) => ({
    opacity: isHighlighted ? 1 : 0.3,
  }),
);

export const MobileMenuItem = ({ icon, url, isExact = true, isHighlighted = false }: Props) => (
  <Container exact={isExact} to={url} className={isHighlighted ? 'highlighted' : ''}>
    <Icon className="material-icons" isHighlighted={isHighlighted}>{icon}</Icon>
  </Container>
);
