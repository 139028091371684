import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MonthRaw } from '../Common/Interface';
import { toMonthRaw } from '../Calendar/helpers';
import { DailyPlanClient, AlgorithmClient } from '../Api';
import { YmdStrict } from '../Calendar/interfaces';
import { AlgorithmFactors } from '../Api/Dto/AlgorithmDto';
import { SmartRatingLoading } from './SmartRatingLoading';
import { PageLayout } from '../Common/PageLayout';
import { AxiosError } from 'axios';
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import glamorous from 'glamorous';
import { AppStatus } from '../Common/AppStatus';
import { SmartRatingError } from './SmartRatingError';
import { SmartRatingErrorProps, isReadyProps } from './interface';
import { DailyPlanStatus, DailyPlan } from '../Api/Dto/CommonDto';
import { ThingStatus } from "../Api/Dto/ThingDto";
import { SmartRatingSuccess } from './SmartRatingSuccess';
import { Transition, MediaQuery } from '../Common/Styles';
import useI18n from '../Common/Hooks/useI18n';

interface MatchParamsProps {
  deadline?: string;
}

const Container = glamorous.div<isReadyProps>(
  {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    ...Transition(['opacity', .3]),

    [MediaQuery.mobileTablet]: {
      justifyContent: 'flex-start',
    },
  },
  ({ isReady }) => ({
    opacity: isReady ? 1 : 0,
  })
);

export const SmartRatingOverview = ({ match }: RouteComponentProps<MatchParamsProps>) => {
  const { t } = useI18n();
  const [appStatus, setAppStatus] = useAppStatus();

  const [isReady, setIsReady] = useState<boolean>(true);
  const [factors, setFactors] = useState<AlgorithmFactors | undefined>(undefined);
  const [plan, setPlan] = useState<DailyPlan<Date> | undefined>(undefined);
  const [error, setError] = useState<SmartRatingErrorProps>({
    reason: '',
    icon: '',
    buttonText: '',
    buttonUrl: '#',
  });

  const getCurrentDeadlineArray = (): [number, MonthRaw, number] => {
    const currentDate = new Date();

    return [
      currentDate.getFullYear(),
      toMonthRaw(currentDate.getMonth()),
      currentDate.getDate(),
    ];
  }

  const parseDeadline = (deadline: string | undefined): YmdStrict<MonthRaw> => {
    const deadlineArray = deadline !== undefined ? deadline.split('-') : getCurrentDeadlineArray();

    if (deadlineArray.length !== 3) {
      const [year, month, day] = getCurrentDeadlineArray();

      return { year, month, day };
    }

    return {
      year: Number(deadlineArray[0]),
      month: Number(deadlineArray[1]) as MonthRaw,
      day: Number(deadlineArray[2]),
    }
  }

  const fetchData = ({ year, month, day }: YmdStrict<MonthRaw>) => DailyPlanClient
    .getOne(year, month, day)
    .then(plan => {
      if (plan.status !== DailyPlanStatus.COMPLETED) {
        setError({
          icon: 'class',
          reason: t("We can't calculate unfinished plan"),
          buttonText: t('Go to Calendar'),
          buttonUrl: '/calendar',
        });
        setAppStatus(AppStatus.FAILED);

        return;
      }

      AlgorithmClient
        .getFactors(year, month, day)
        .then(data => {
          setPlan(plan);
          setFactors(data);
          setAppStatus(AppStatus.READY);
        })
    })
    .catch((error: AxiosError) => {
      if (error.response && error.response.status === 404) {
        setError({
          icon: 'class',
          reason: t('No plan found for this day'),
          buttonText: t('Create day plan'),
          buttonUrl: `/calendar/${year}/${month}/${day}`,
        });
        setAppStatus(AppStatus.FAILED);
      }
    });

  useEffect(() => {
    setFactors(undefined);
    fetchData(parseDeadline(match.params.deadline));
  }, [match.params]);

  const hideView = () => setIsReady(false);

  return (
    <PageLayout showMobileMenu={false}>
      <Container isReady={isReady}>
        { factors === undefined && appStatus === AppStatus.LOADING
          ? <SmartRatingLoading />
          : <>
              {appStatus === AppStatus.FAILED && <SmartRatingError 
                reason={error.reason}
                icon={error.icon}
                buttonText={error.buttonText}
                buttonUrl={error.buttonUrl}
                hideView={hideView}
              />}
              {plan !== undefined && factors !== undefined && appStatus === AppStatus.READY && <SmartRatingSuccess
                factors={factors}
                userRating={plan.rating}
                tasksQnt={plan.things.length}
                tasksDone={plan.things.filter(thing => thing.status !== ThingStatus.TO_DO).length}
                hideView={hideView}
              />}
            </>
        }
      </Container>
    </PageLayout>
  );
}
