import { createContext, Dispatch } from 'react';
import translations, { allowedLanguages } from '../../I18n';

export type LanguageCode = 'en' | 'pl';

export type TranslateFunction = (text: string, variant?: string) => string;

interface I18nContextProps {
  setLanguage: (languageCode: LanguageCode) => void;
  currentLanguage: LanguageCode;
  t: TranslateFunction;
}

const getLanguageCode = (code: LanguageCode, defaultLanguage: LanguageCode = 'en'): LanguageCode => allowedLanguages.includes(code)
  ? code
  : defaultLanguage;

export const getLanguageFromStorage = (defaultLanguage: LanguageCode = 'en') => {
  return getLanguageCode(localStorage.getItem('language') as LanguageCode, defaultLanguage);
}

export const SetI18nContext = ([currentLanguage, setLanguage]: [LanguageCode, Dispatch<LanguageCode>]): I18nContextProps => ({
  setLanguage,
  currentLanguage,
  //@ts-ignore
  t: (text, variant) => {
    const lang = getLanguageCode(currentLanguage);

    if (!variant) {
      return typeof translations[lang][text] === 'string'
        ? translations[lang][text] || text
        : text;
    }

    const foundVariant = translations[lang][variant];

    return typeof foundVariant === 'string'
      ? foundVariant || text
      : (foundVariant || {} as { [key: string]: string })[text] || text;
  }
});

export const I18nContext = createContext({} as any as I18nContextProps);
