import Decimal from 'decimal.js';
import { AlgorithmFactors } from '../../Api/Dto/AlgorithmDto';
import { Rating } from '../interface';

export const calculateRating = (
  { a, b, c, d, e }: AlgorithmFactors,
  tasksQnt: number,
  tasksDone: number,
): Rating => {
  if (tasksQnt === 0) {
    return {
      factors: {
        consistency: 0,
        efficiency: 0,
        workBalance: 0,
        tasksFactor: 0,
      },
      userRating: 0,
    };
  }

  const tasksFactor = Decimal.div(b, tasksQnt).toDecimalPlaces(2).toNumber();
  const calculatedEfficiency = (c + d + tasksFactor) / 3;
  const tasksEfficiencyLimit = tasksDone / tasksQnt;

  return {
    factors: {
      consistency: a > 1 ? 100 : Number((a * 100).toFixed()),
      efficiency: calculatedEfficiency > tasksEfficiencyLimit
        ? Number((tasksEfficiencyLimit * 100).toFixed())
        : Number((calculatedEfficiency * 100).toFixed()),
      workBalance: Number(((tasksFactor > 1 ? 1 : tasksFactor) * 100).toFixed()),
      tasksFactor,
    },
    userRating: e,
  }
}