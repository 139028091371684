import React, { useState, FormEvent } from 'react';
import glamorous from 'glamorous';
import { Card, CardTitle } from '../Common/Card';
import { useAppStatus } from '../Common/Hooks/useAppStatus';
import { AppStatus } from '../Common/AppStatus';
import { handleEntityChange } from '../Common/FormsHelper';
import { AuthClient } from '../Api';
import { handleErrors } from '../Common/Forms/FormHandler';
import { Input } from '../Common/Forms/Input';
import { ButtonsGroup } from '../Common/Forms/ButtonsGroup';
import { SubmitButton } from '../Common/Forms/Buttons';
import { FormInfoBox } from '../Common/Forms/Styles';
import { Color } from '../Common/Styles';
import useI18n from '../Common/Hooks/useI18n';

const MainCard = glamorous(Card)({
  maxWidth: '620px',
});

interface EntityProps<T> {
  password: T;
  repeatPassword: T;
}

export const ProfileChangePassword = () => {
  const { t } = useI18n();
  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [entity, setEntity] = useState<EntityProps<string>>({
    password: '',
    repeatPassword: '',
  });
  const [errors, setErrors] = useState<EntityProps<string[]>>({
    password: [],
    repeatPassword: [],
  });

  const clearErrors = () => setErrors({
    password: [],
    repeatPassword: [],
  });

  const handleOnChange = (event: FormEvent) => handleEntityChange(event, setEntity, entity);

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();

    setAppStatus(AppStatus.LOADING);
    setShowSuccess(false);

    AuthClient
      .changePassword(entity.password, entity.repeatPassword)
      .then(() => {
        clearErrors();
        setShowSuccess(true);
        setAppStatus(AppStatus.READY);
      })
      .catch(err => {
        setAppStatus(AppStatus.READY);
        setErrors(handleErrors(errors, err));
      });
  }

  return (
    <MainCard>
      <FormInfoBox backgroundColor={Color.appleGreen} isVisible={showSuccess}>
        {t('Personal data changed successfully')}
      </FormInfoBox>
      <CardTitle>{t('Change password')}</CardTitle>
      <form method="post" onSubmit={(event) => appStatus !== AppStatus.LOADING && handleOnSubmit(event)}>
        <Input
          label="Current password"
          name="password"
          type="password"
          onChange={handleOnChange}
          value={entity.password}
          errors={errors.password}
        />
        <Input
          label="New password"
          name="repeatPassword"
          type="password"
          onChange={handleOnChange}
          value={entity.repeatPassword}
          errors={errors.repeatPassword}
        />
        <ButtonsGroup align="right">
          <SubmitButton
            text="Change"
            isActive={appStatus !== AppStatus.LOADING}
          />
        </ButtonsGroup>
      </form>
    </MainCard>

  )
};
