import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { Drawer, GenericDate } from '../Dto/CommonDto';
import { IsoDate } from '../Interface';
import { transformEntity } from '../Helpers';

export class ProjectApiClient extends AccessTokenApiClient {
  create(name: string): Promise<Drawer<Date>> {
    return this.apiRequest
      .post<Drawer<IsoDate>>('', { name })
      .then(({ data }) => transformEntity<Drawer<Date>>(data));
  }

  update(drawer: Drawer<GenericDate>, name: string): Promise<Drawer<Date>> {
    return this.apiRequest
      .patch(drawer.id, { name })
      .then(({ data }) => transformEntity<Drawer<Date>>(data));
  }
}
