import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { Button } from '../../Common/Forms/Buttons';
import { Color } from '../../Common/Styles';
import { ButtonsGroup } from '../../Common/Forms/ButtonsGroup';
import { CalendarOverviewContext } from '../Contexts/CalendarOverviewContext';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {

}

const Container = glamorous.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const Icon = glamorous.i({
  color: Color.orange,
  fontSize: '48px',
});

const Text = glamorous.p({
  color: Color.dark74,
  fontSize: '14px',
  lineHeight: 1.29,
  letterSpacing: '-.3px',
  margin: '15px 0 45px 0',
});

const AddThingsButton = glamorous(Button)({

});

export const ExistingPlanWithoutThing = ({}: Props) => {
  const { t } = useI18n();
  const { calendarOverviewActions } = useContext(CalendarOverviewContext);

  return (
    <Container>
      <Icon className="material-icons">notification_important</Icon>
      <Text>{t('There are no things in this plan')}</Text>
      <ButtonsGroup align="center">
        <AddThingsButton onClick={() => calendarOverviewActions.expand()} isActive={true} color={Color.appleGreen}>{t('Add things')}</AddThingsButton>
      </ButtonsGroup>
    </Container>
  )
};
