import { GoodBadText, Rating } from '../interface';
import { AlgorithmFactors } from '../../Api/Dto/AlgorithmDto';
import { TranslateFunction } from '../../Common/Contexts/I18nContext';

export enum Priority {
  TRIVIAL = 1,
  GOOD_TO_KNOW = 2,
  IMPORTANT = 3,
  CRITICAL = 4,
}

interface ThingsSummary {
  tasksDone: number;
  tasksQnt: number;
}

export abstract class GoodBadGenerator {
  protected consistency: number;
  protected efficiency: number;
  protected tasksFactor: number;
  protected workBalance: number;
  protected t: (text: string) => string;

  constructor(
    protected readonly texts: GoodBadText[],
    protected readonly factors: AlgorithmFactors,
    { factors: { consistency, efficiency, tasksFactor, workBalance } }: Rating,
    protected readonly thingsSummary: ThingsSummary,
    t: TranslateFunction,
  ) {
    this.consistency = consistency;
    this.efficiency = efficiency;
    this.tasksFactor = tasksFactor;
    this.workBalance = workBalance;
    this.t = (text) => t(text, 'rating-advices');
  }

  public abstract generate(): void;

  public toSortedMessages(length: number): string[] {
    return this.texts
      .sort((a, b) => {
        if (a.priority > b.priority) {
          return -1;
        }
        if (a.priority < b.priority) {
          return 1;
        }
    
        return 0;
      })
      .slice(0, length).map(text => text.message);
  }

  protected add(message: string, priority: Priority): void {
    this.texts.push({
      message,
      priority,
    });
  }

  protected replaceQnt(text: string, qnt: number) {
    return text.replace('{qnt}', String(qnt));
  }
}