import React from 'react';
import { AuthContainer } from '../../Common/AuthContainer';
import { H1 } from '../Common/AuthTypography';
import { P } from 'glamorous';
import { Icon } from '../Common/AuthStyles';
import { Color } from '../../Common/Styles';
import { VerifyAccountSetUser } from './VerifyAccountSetUser';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  token: string;
  firstName: string;
  lastName: string;
}

export const VerifyAccountSuccess = ({ token, firstName, lastName }: Props) => {
  const { t } = useI18n();

  return (
    <AuthContainer>
      <H1><Icon css={{ color: Color.appleGreen }}>verified_user</Icon></H1>
      <P>{t('Your account has been verified.')}</P>
      <P>{t('Please update your data in order to activate account.')}</P>
      <VerifyAccountSetUser
        token={token}
        firstName={firstName}
        lastName={lastName}
      />
    </AuthContainer>
  );
}
