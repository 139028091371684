import { Reducer } from 'react';
import { Thing } from '../../Api/Dto/CommonDto';

export type PlanThingsReducerAction =
| { type: PlanThingsAction.ADD, value: Thing<Date> }
| { type: PlanThingsAction.UPDATE, value: Thing<Date> }
| { type: PlanThingsAction.REMOVE, value: Thing<Date> }
| { type: PlanThingsAction.SET_ALL, values: Thing<Date>[] }

export enum PlanThingsAction {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  SET_ALL = 'SET_ALL',
}

export const PlanThingsReducer: Reducer<Thing<Date>[], PlanThingsReducerAction> = (state, action) => {
  switch (action.type) {
    case PlanThingsAction.ADD:
      return [action.value, ...state];
      case PlanThingsAction.UPDATE:
        return state.map(
          oldThing => oldThing.id === action.value.id
            ? action.value
            : oldThing
          );
    case PlanThingsAction.REMOVE:
      return state.filter(thing => thing.id !== action.value.id);
    case PlanThingsAction.SET_ALL:
      return [...action.values];
    default:
        throw new Error('PlanThingsReducer: Undefined action');
  }
}
