import React from 'react';
import glamorous from 'glamorous';
import { Drawer } from '../Api/Dto/CommonDto';
import { DrawerAvatar } from '../Common/Drawer/DrawerAvatar';
import { ScrollbarHidden, Color, BoxShadow } from '../Common/Styles';
import { Uuid } from '../Api/Interface';

interface Props {
  drawers: Drawer<Date>[];
  activeDrawerId: Uuid | null;
  selectDrawer: (drawerId: Uuid | null) => void;
}

const Container = glamorous.div({
  display: 'flex',
  paddingRight: '15px',
  justifyContent: 'flex-start',
  alignItems: 'center',

  overflowY: 'hidden',
  overflowX: 'scroll',
  ...ScrollbarHidden,

  '& .avatar': {
    flex: '0 0 25px',
  }
});

const AddDrawerIcon = glamorous.span({
  cursor: 'pointer',
  fontSize: '21px',
  textAlign: 'center',
  color: Color.dark66,
  padding: '5px',
  backgroundColor: Color.white,
  borderRadius: '4px',
  boxShadow: BoxShadow.light,
  marginLeft: '5px',
});

export const DrawerList = ({ drawers, activeDrawerId, selectDrawer }: Props) => {
  return (
    <Container>
      {drawers.map(drawer => <DrawerAvatar
        key={drawer.id}
        drawerId={drawer.id}
        sideMargin={5}
        isActive={drawer.id === activeDrawerId}
        onClick={() => selectDrawer(drawer.id)}
      />)}
      <AddDrawerIcon onClick={() => selectDrawer(null)} className="material-icons">add</AddDrawerIcon>
    </Container>
  )
};
