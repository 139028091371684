import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { MonthRaw } from '../../Common/Interface';
import { zerofill } from '../../Common/Helpers';
import { DailyPlan, Thing, DailyPlanDto, GrouppedDailyPlans, GenericDate, DailyPlanStatus } from '../Dto/CommonDto';
import { IsoDate } from '../Interface';
import { transformEntity } from '../Helpers';
import { Ymd, YmdStrict } from '../../Calendar/interfaces';
import { toMonthJS, toMonthRaw } from '../../Calendar/helpers';
import { ThingStatus } from '../Dto/ThingDto';

const filterDeletedThings = (things: Thing<IsoDate>[]): Thing<IsoDate>[] => (
  things.filter(thing => thing.status !== ThingStatus.DELETED)
);

const transformThings = (things: Thing<IsoDate>[]): Thing<Date>[] => (
  filterDeletedThings(things).map(thing => transformEntity<Thing<Date>>(thing))
);

const transformPlan = (dailyPlan: DailyPlanDto): DailyPlan<Date> => {
  const deadlineArray = dailyPlan.deadline.split('-');

  return {
    ...transformEntity<DailyPlan<Date>>(dailyPlan),
    things: transformThings(dailyPlan.tasks),
    deadline: {
      year: Number(deadlineArray[0]),
      month: toMonthJS(Number(deadlineArray[1])),
      day: Number(deadlineArray[2].substr(0, 2)),
    }
  };
};

export class DailyPlanApiClient extends AccessTokenApiClient {
  getGrouppedList(year: number, month: MonthRaw): Promise<{ fetchedDate: Ymd, data: GrouppedDailyPlans<Date> }> {
    return this.apiRequest
      .get<DailyPlanDto[]>(`${year}/${zerofill(month, 2)}`)
      .then(({ data }) => {
        const grouppedPlans: GrouppedDailyPlans<Date> = {};

        data.forEach(dailyPlan => {
          grouppedPlans[Number(dailyPlan.deadline.split('-').pop())] = transformPlan(dailyPlan);
        });

        return {
          fetchedDate: { 
            year,
            month: toMonthJS(month)
          },
          data: grouppedPlans
        };
      });
  }

  getOne(year: number, month: MonthRaw, day: number): Promise<DailyPlan<Date>> {
    return this.apiRequest
      .get<DailyPlanDto>(`${year}-${zerofill(month, 2)}-${zerofill(day, 2)}`)
      .then(({ data }) => transformPlan(data));
  }

  getActive(): Promise<DailyPlan<Date> | null> {
    return this.apiRequest
      .get<DailyPlanDto>('active')
      .then(({ data }) => typeof data === 'string' 
        ? null
        : transformPlan(data)
      );
  }

  create({ year, month, day }: YmdStrict, things: Thing<GenericDate>[]): Promise<DailyPlan<Date>> {
    return this.apiRequest
      .post<DailyPlanDto>('', {
        status: DailyPlanStatus.PREPARED,
        deadline: `${year}-${zerofill(toMonthRaw(month), 2)}-${zerofill(day, 2)}`,
        tasks: things.map(thing => thing.id),
      })
      .then(({ data }) => transformPlan(data));
  }

  updateThings(plan: DailyPlan<Date>, things: Thing<Date>[]) {
    return this.apiRequest
      .put<DailyPlanDto>(`${plan.id}/tasks`, {
        tasks: things.map(thing => thing.id)
      })
      .then(({ data }) => transformPlan(data));
  }

  async complete(plan: DailyPlan<Date>): Promise<DailyPlan<Date>> {
    switch (plan.status) {
      case DailyPlanStatus.COMPLETED:
      case DailyPlanStatus.DELETED:
        throw new Error(`Cannot complete plan with status '${plan.status}'`);
    }

    return this.apiRequest
      .patch(plan.id, { status: DailyPlanStatus.COMPLETED })
      .then(({ data }) => transformPlan(data));
  }
}
