import glamorous from 'glamorous';
import { Card, CardTitle } from '../Common/Card';
import { Small } from '../Common/Typography';

export const ArticleContainer = glamorous.div({
  maxWidth: '720px',
  margin: 0,
});

export const ArticleSection = glamorous(Card)({
  padding: '15px',
  margin: '0 0 30px 0',
  width: 'auto',

  '& p': {
    margin: 0,
  }
});

export const ArticleTitle = glamorous(CardTitle)({
  paddingBottom: '15px',
});

export const ArticleDescription = glamorous(Small)({

});
