import React, { ReactNode } from 'react';
import glamorous from "glamorous";
import { InputProps } from '../Interface';
import { Font, Color, important, Unselectable } from '../Styles';
import { FormErrors } from './FormErrors';
import { FormLabel } from './Styles';
import useI18n from '../Hooks/useI18n';

interface Props extends InputProps {
  children?: ReactNode;
  icon?: string | null;
  errors?: string[];
  disableErrorIcon?: boolean;
  label?: string | undefined;
  css?: any;
}

interface InputElementProps {
  hasIcon: boolean;
  hasErrors: boolean;
}

export const InputIcon = glamorous.span({
  color: Color.dark74,
  position: 'absolute',
  top: '10px',
  left: '10px',
  ...Unselectable,
});

const InputContainer = glamorous.div({
  position: 'relative',
  marginBottom: '10px',
});

const InputErrorIcon = glamorous(InputIcon)({
  left: 'auto',
  right: '10px',
  color: Color.cherryRed,
});

const InputElement = glamorous.input<InputElementProps>(
  {
    width: '100%',
    height: '46px',
    borderRadius: '4px',
    backgroundColor: Color.white,
    fontSize: important('14px'),
    fontFamily: Font.normal,
    color: Color.dark74,
    padding: '12px 18px',
    outline: 'none',
  },
  ({ hasIcon, hasErrors }) => ({
    paddingLeft: hasIcon ? '40px' : '12px',
    border: hasErrors
      ? `1px solid ${Color.cherryRed}`
      : `1px solid ${Color.cloudyBlue}`,
  })
);

export const Input = ({
  children,
  name,
  onChange,
  placeholder = '',
  type = 'text',
  value,
  autoComplete = true,
  icon = null,
  errors = [],
  disableErrorIcon = false,
  label,
  css = {},
}: Props) => {
  const { t } = useI18n();
  
  return (
    <>
      { label !== undefined && <FormLabel hasErrors={errors.length > 0}>{t(label)}</FormLabel> }
      <InputContainer>
        <InputElement
          name={name}
          onChange={onChange}
          placeholder={t(placeholder)}
          type={type}
          value={value}
          autoComplete={autoComplete ? 'on' : 'nope'}
          hasIcon={icon !== null}
          hasErrors={errors.length > 0}
          css={css}
        />
        { icon !== null && <InputIcon className="material-icons">{icon}</InputIcon> }
        {children}
        { errors.length > 0 && (
          <>
            { !disableErrorIcon && <InputErrorIcon className="material-icons">report_problem</InputErrorIcon> }
            <FormErrors errors={errors} />
          </>
        ) }
      </InputContainer>
    </>
  );
}