import { ApiRequest } from '../ApiRequest';
import { OrganizationGetListDto, OrganizationCreateDto } from '../Dto/OrganizationDto';
import { UserOrganization, Organization } from '../Dto/CommonDto';
import { IsoDate } from '../Interface';

export class OrganizationApiClient {
  constructor(
    private readonly refreshTokenApiRequest: ApiRequest,
    private readonly organizationTokenApiRequest: ApiRequest,
    private readonly accessTokenApiRequest: ApiRequest,
  ) {}

  create(name: string): Promise<Organization<IsoDate>> {
    return this.refreshTokenApiRequest
      .post<OrganizationCreateDto>('', { name })
      .then(({ data }) => {
        this.organizationTokenApiRequest.tokenContainer.setToken(data.organizationToken);
        this.accessTokenApiRequest.tokenContainer.setToken(data.accessToken);

        return data.organization;
      });
  }

  getList(): Promise<UserOrganization<IsoDate>[]> {
    return this.refreshTokenApiRequest
      .get<OrganizationGetListDto>('')
      .then(({ data }) => data.organizations);
  }

  getUsers() {
    return this.organizationTokenApiRequest.get('users');
  }
}
