import React, { memo, useContext } from 'react';
import glamorous from 'glamorous';
import { Avatar } from '../Avatar';
import { AvatarSize } from '../Interface';
import { Thing, GenericDate } from '../../Api/Dto/CommonDto';
import { UserContext } from '../Contexts/UserContext';
import { uuidToColor } from '../Helpers';
import { DrawerContext } from '../Contexts/DrawerContext';

interface Props {
  thing: Thing<GenericDate>;
  size?: AvatarSize;
  align?: 'left' | 'right';
}

interface ContainerProps {
  align: 'left' | 'right';
}

const Container = glamorous.div<ContainerProps>(
  {},
  ({ align }) => ({
    margin: align === 'left' ? '0 10px 0 0' : '0 0 0 10px',
  })
);

const _ThingAvatar = ({ thing, size = AvatarSize.TINY, align = 'right' }: Props) => {
  const { user, userActions } = useContext(UserContext);
  const { drawerActions } = useContext(DrawerContext);

  return (
    <Container align={align}>
      <Avatar
        size={size}
        color={uuidToColor(thing.projectId || user.id)}
        text={thing.projectId ? (drawerActions.getCodenameById(thing.projectId) || '') : userActions.getInitals()}
      />
    </Container>
  );
}

export const ThingAvatar = memo(_ThingAvatar);
