import React, { useContext } from 'react';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';
import { PageLayout } from '../Common/PageLayout';
import glamorous from 'glamorous';
import { Color } from '../Common/Styles';
import { MainMenuItem } from './MainMenuItem';
import { Avatar } from '../Common/Avatar';
import { UserContext } from '../Common/Contexts/UserContext';
import { AvatarSize } from '../Common/Interface';
import useI18n from '../Common/Hooks/useI18n';

const Title = glamorous.span({
  fontSize: '14px',
  color: Color.dark54,
  margin: '10px 0',
  borderBottom: `1px solid ${Color.dark54}`,
});

const navigationMenu: {
  color: string;
  text: string;
  url: string;
  icon: string
}[] = [
  {
    text: 'Your Day',
    url: '/',
    icon: 'class',
    color: Color.iris,
  },
  {
    text: 'Things',
    url: '/things',
    icon: 'playlist_add_check',
    color: Color.appleGreen,
  },
  {
    text: 'Calendar',
    url: '/calendar',
    icon: 'today',
    color: Color.orange,
  },
  {
    text: 'Give us feedback',
    url: '/support',
    icon: 'question_answer',
    color: Color.twilight,
  },
  {
    text: "What's new?",
    url: '/whats-new',
    icon: 'rss_feed',
    color: Color.dark66,
  }
];

export const MainMenu = () => {
  const { t } = useI18n();
  const { userActions } = useContext(UserContext);

  return (
    <DefaultTheme>
      <PageLayout asFlex>
        <Title>Navigation</Title>
        {navigationMenu.map((menuItem, key) => <MainMenuItem
          key={key}
          icon={menuItem.icon}
          color={menuItem.color}
          text={menuItem.text}
          url={menuItem.url}
        />)}
        <Title>Other</Title>
        <MainMenuItem
          avatar={<Avatar
            color={userActions.getColor()}
            text={userActions.getInitals()}
            size={AvatarSize.MICRO}
          />}
          color={Color.transparent}
          text="Your profile"
          url="/profile"
        />
        <MainMenuItem
          icon="forward"
          color={Color.dark54}
          text="Log out"
          url="/logout"
        />
      </PageLayout>
    </DefaultTheme>
  )
};
