import React from 'react';
import glamorous from 'glamorous';
import { Color } from './Styles';

interface Props {
  percentage: number;
  size?: number
  pathColor?: string;
  trailColor?: string;
}

interface ContainerProps {
  color: string;
  size: number;
}

interface PathProps {
  percentage: number;
  color: string;
  size: number;
}

const Container = glamorous.div<ContainerProps>(
  {
    position: 'relative',
  },
  ({ color, size }) => ({
    backgroundColor: color,
    height: `${size}px`,
  }),
);

const Path = glamorous.span<PathProps>(
  {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  ({ color, size, percentage }) => ({
    width: `${percentage}%`,
    backgroundColor: color,
    height: `${size}px`,
  }),
);

export const LinearProgressBar = ({
  percentage,
  pathColor = Color.appleGreen,
  trailColor = Color.cherryRed,
  size = 5,
}: Props) => (
  <Container color={trailColor} size={size}>
    <Path percentage={percentage} color={pathColor} size={size} />
  </Container>
);
