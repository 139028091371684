import React, { useState, FormEvent, useContext } from 'react';
import glamorous from 'glamorous';
import { ScrollbarHidden, MediaQuery, Color } from '../../Common/Styles';
import { Input } from '../../Common/Forms/Input';
import { SubmitButton } from '../../Common/Forms/Buttons';
import { Small } from '../../Common/Typography';
import { ButtonsGroup } from '../../Common/Forms/ButtonsGroup';
import { useAppStatus } from '../../Common/Hooks/useAppStatus';
import { AppStatus } from '../../Common/AppStatus';
import { handleEntityChange } from '../../Common/FormsHelper';
import { DrawerContext } from '../../Common/Contexts/DrawerContext';
import { ProjectClient } from '../../Api';
import { handleErrors } from '../../Common/Forms/FormHandler';
import { Drawer } from '../../Api/Dto/CommonDto';
import { AxiosError } from 'axios';
import { TemplateContext, ModalTemplate } from '../../Common/Contexts/TemplateContext';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  afterCreate?: (createdDrawer: Drawer<Date>) => void | undefined;
}

interface EntityProps<T> {
  name: T;
}

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '15px 30px 30px 15px',
  textAlign: 'center',
  ...ScrollbarHidden,
  borderLeft: `1px solid ${Color.silver}`,

  [MediaQuery.tableDesktop]: {
    minWidth: '320px',
    maxWidth: '360px',
  },

  [MediaQuery.mobileOnly]: {
    margin: '0 auto',
    maxWidth: '290px',
    borderLeft: 'none',
    height: 'calc(100% - 40px)',
    padding: '0 15px 15px 0',
    flex: 1,
  }
});

const ContentContainer = glamorous.form({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  padding: '15px',
  borderRadius: '4px',
});

const Title = glamorous.span({
  letterSpacing: '-0.3px',
  color: Color.dark66,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '1.15',
  textTransform: 'capitalize',
});

export const CreateDrawer = ({ afterCreate = undefined }: Props) => {
  const { t } = useI18n();
  const [appStatus, setAppStatus] = useAppStatus(AppStatus.READY);
  const { drawerActions } = useContext(DrawerContext);
  const { templateActions } = useContext(TemplateContext);

  const [entity, setEntity] = useState<EntityProps<string>>({
    name: '',
  });
  const [errors, setErrors] = useState<EntityProps<string[]>>({
    name: [],
  });

  const handleOnChange = (event: FormEvent) => handleEntityChange(event, setEntity, entity);

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();

    setAppStatus(AppStatus.LOADING);

    ProjectClient
      .create(entity.name)
      .then(data => {
        setAppStatus(AppStatus.READY);
        drawerActions.add(data);
        typeof afterCreate === 'function' && afterCreate(data);
      })
      .catch((err: AxiosError) => {
        setAppStatus(AppStatus.READY);
        setEntity({ name: '' });

        return err.response && err.response.status === 409
          ? templateActions.setModal(ModalTemplate.DRAWERS_EXCEEDED)
          : setErrors(handleErrors(errors, err));
      });
  }

  return (
    <Container>
      <ContentContainer method="post" onSubmit={(event) => entity.name.length > 0 && handleOnSubmit(event)}>
        <Title>{t('Create a new drawer')}</Title>
        <Small>
          {t("It's hard to manage time when you have so many things.")}
          <br />
          {t('Create a drawer to group them.')}
        </Small>
        <ButtonsGroup flexDirection="column">
        <Input
          name="name"
          onChange={handleOnChange}
          placeholder="Write here a name for your drawer"
          value={entity.name}
          errors={errors.name}
          autoComplete={false}
          css={{
            padding: '15px 15px 0 15px',
            backgroundColor: 'transparent',
            border: 'none',
            borderBottom: '1px dotted silver',
            textAlign: 'center',
          }}
        />
        <SubmitButton
          isActive={appStatus !== AppStatus.LOADING && entity.name.length > 0}
          text="Create"
        />
        </ButtonsGroup>
      </ContentContainer>
    </Container>
  )
};
