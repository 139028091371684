import React from 'react';
import glamorous from 'glamorous';
import { Color, BoxShadow, MediaQuery } from '../Common/Styles';

interface Props {
  imageLeft: string;
  imageRight: string;
  width: number;
}

interface ImageProps {
  width: string | number;
}

const Container = glamorous.div({
  display: 'flex',
  alignItems: 'center',

  [MediaQuery.mobileTablet]: {
    flexDirection: 'column',
  }
});

const Image = glamorous.img<ImageProps>(
  {
    boxShadow: BoxShadow.light,

    [MediaQuery.mobileTablet]: {
      maxWidth: '100%',
    },
  },
  ({ width }) => ({
      width: `${width}px`,
  }),
);

const Icon = glamorous.i({
  color: Color.dark74,
  fontSize: '21px',
  margin: '15px',

  [MediaQuery.mobileTablet]: {
    transform: 'rotate(90deg)',
  },
});

export const ImagesTransformation = ({ imageLeft, imageRight, width }: Props) => {
  return (
    <Container>
      <Image src={imageLeft} width={width} />
      <Icon className="material-icons">double_arrow</Icon>
      <Image src={imageRight} width={width} />
    </Container>
  )
};
