import React, { FormEvent, useState } from 'react';
import { PageLayout } from '../Common/PageLayout';
import { H1 } from '../Common/Typography';
import { Card, CardTitle, CardSmall } from '../Common/Card';
import glamorous from 'glamorous';
import { TextArea } from '../Common/Forms/TextArea';
import { handleEntityChange } from '../Common/FormsHelper';
import { SupportClient } from '../Api';
import { handleErrors } from '../Common/Forms/FormHandler';
import { SubmitButton } from '../Common/Forms/Buttons';
import { ButtonsGroup } from '../Common/Forms/ButtonsGroup';
import { Color, BoxShadow } from '../Common/Styles';
import { DefaultTheme } from '../Common/Themes/DefaultTheme';
import useI18n from '../Common/Hooks/useI18n';

interface EntityProps {
  message: string;
}

const MainCard = glamorous(Card)({
  maxWidth: '620px',
});

const CardTextArea = glamorous.div({
  margin: '30px 0',
});

const SuccessContainer = glamorous.div({
  textAlign: 'center',
  marginTop: '30px',
  padding: '30px',
  boxShadow: BoxShadow.light,
  borderRadius: '4px',
});

const SuccessIcon = glamorous.i({
  color: Color.iris,
  fontSize: '42px',
});

const SuccessText = glamorous.p({
  margin: '30px 0',
  color: Color.dark66,
});

export const Support = () => {
  const { t } = useI18n();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false);
  const [entity, setEntity] = useState<EntityProps>({
    message: ''
  });
  const [errors, setErrors] = useState({
    message: [],
  });

  const handleOnChange = (event: FormEvent) => handleEntityChange(event, setEntity, entity);

  const handleSubmit = (event: FormEvent) => {
    if (isLoading === true) {
      return;
    }

    event.preventDefault();

    setIsLoading(true);

    SupportClient
      .create(entity.message)
      .then(() => {
        setIsLoading(false);
        setShowSuccessPage(true);
      })
      .catch(err => {
        setIsLoading(false);
        setErrors(handleErrors(errors, err));
      });
  }

  return (
    <DefaultTheme>
      <PageLayout>
        <H1>{t('Support')}</H1>
        <MainCard>
          <CardTitle>{t('Share your feedback with us')}</CardTitle>
          <CardSmall>
            {t('If you have something on mind, or you noticed any issues feel free to share them with us.') + ' '}
            {t('Just write down a message below.') + ' '}
            {t("We'll reply directly to your e-mail.")}
          </CardSmall>
            {!showSuccessPage ? (
              <form method="post" onSubmit={handleSubmit}>
                <CardTextArea>
                  <TextArea
                    name="message"
                    label="Message"
                    value={entity.message}
                    onChange={handleOnChange}
                    errors={errors.message}
                    height={200}
                  />
                </CardTextArea>
                <ButtonsGroup align="right">
                  <SubmitButton
                    text="Send"
                    isActive={!isLoading}
                  />
                </ButtonsGroup>
              </form>
            ) : (
              <SuccessContainer>
                <SuccessIcon className="material-icons">
                  email
                </SuccessIcon>
                <SuccessText>
                  {t('Thank you for sharing your feedback with us. We value every word from you.')}
                </SuccessText>
                <ButtonsGroup align="center">
                  <SubmitButton
                    text="Send another message"
                    onClick={() => setShowSuccessPage(true)}
                  />
                </ButtonsGroup>
            </SuccessContainer>
            )}
        </MainCard>
      </PageLayout>
    </DefaultTheme>
  )
}