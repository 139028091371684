import { FormEvent } from 'react';

export interface InputProps {
  name: string;
  value: string;
  type?: 'text' | 'password' | 'email' | 'tel';
  placeholder?: string;
  autoComplete?: boolean;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
}

export enum PageLayoutTheme {
  DEFAULT = 'DEFAULT',
  NO_PADDING = 'NO_PADDING',
  NO_TITLE = 'NO_TITLE',
  NO_BOTTOM_PADDING = 'NO_BOTTOM_PADDING',
  NO_BOTTOM_RIGHT_PADDING = 'NO_BOTTOM_RIGHT_PADDING',
}

export enum TemplateTheme {
  DEFAULT = 'DEFAULT',
  BLANK = 'BLANK',
  EMPTY = 'EMPTY',
}

export enum AvatarSize {
  MICRO = 21,
  TINY = 25,
  SMALL = 30,
  DEFAULT = 45,
  BIG = 58,
}

export enum AvatarTemplate {
  DEFAULT = 'DEFAULT',
  INACTIVE = 'INACTIVE',
}

export type MonthJS = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type MonthRaw = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface Rgb {
  r: number;
  g: number;
  b: number;
}
