import { createContext, Dispatch, SetStateAction } from 'react';
import { Uuid } from '../../Api/Interface';
import { hasItem } from '../../Common/ArrayHelper';

type SelectedThingsState = [Uuid[], Dispatch<SetStateAction<Uuid[]>>];

interface SelectedThingsActionsProps {
  removeMany: (thingsToRemove: Uuid[]) => void;
  clear: () => void;
}

interface SelectedThingsContextProps {
  selectedThings: Uuid[];
  selectedThingsActions: SelectedThingsActionsProps
}

const selectedThingsActions = ([selectedThings, setSelectedThings]: SelectedThingsState): SelectedThingsActionsProps => ({
  removeMany: (thingsToRemove) => setSelectedThings(thingsToRemove.filter(thingToRemove => !hasItem<Uuid>(selectedThings, thingToRemove))),
  clear: () => setSelectedThings([]),
})

export const SetSelectedThingsContext = (dispatch: SelectedThingsState): SelectedThingsContextProps => ({
  selectedThings: dispatch[0],
  selectedThingsActions: selectedThingsActions(dispatch),
});

export const SelectedThingsContext = createContext<SelectedThingsContextProps>({} as unknown as SelectedThingsContextProps);
