import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { Color, MediaQuery } from '../../Common/Styles';
import { CALENDAR_MONTHS } from '../common';
import { CalendarContext } from '../Contexts/CalendarContext';
import useI18n from '../../Common/Hooks/useI18n';

const Header = glamorous.div({
  backgroundColor: Color.iris,
  width: '100%',
  height: '98px',
  position: 'relative',
  borderLeft: `1px solid ${Color.iris}`,

  [MediaQuery.mobileOnly]: {
    height: '60px',
  },
});

const HeaderContent = glamorous.div({
  position: 'absolute',
  top: '40px',
  left: '20px',
  lineHeight: '20px',

  [MediaQuery.mobileOnly]: {
    left: '10px',
    top: '10px',
    lineHeight: '34px',
  },

  '& > span': {
    color: Color.white,
  }
});

const HeaderDay = glamorous.span({
  fontSize: '66px',
  letterSpacing: '-1.4px',
  fontWeight: 300,
  float: 'left',

  [MediaQuery.mobileOnly]: {
    fontSize: '48px',
  },
});

const HeaderMonthYear = glamorous.span({
  float: 'left',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 1.25,
  marginLeft: '10px',
});

export const CalendarOverviewHeader = () => {
  const { t } = useI18n();
  const { currentDate } = useContext(CalendarContext);

  return (
    <Header>
      <HeaderContent>
        <HeaderDay>{currentDate.day || 1}</HeaderDay>
        <HeaderMonthYear>
          {t((CALENDAR_MONTHS[currentDate.month] || {}).full, 'month-of')}
          <br />
          {currentDate.year}
        </HeaderMonthYear>
      </HeaderContent>
    </Header>
  );
}
