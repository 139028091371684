import { Reducer } from 'react';
import { DailyPlan, GrouppedDailyPlans, Thing } from '../../Api/Dto/CommonDto';

export type MonthlyPlansReducerAction =
| { type: MonthlyPlansAction.ADD, value: DailyPlan<Date> }
| { type: MonthlyPlansAction.REMOVE, value: DailyPlan<Date> }
| { type: MonthlyPlansAction.SET_ALL, values: GrouppedDailyPlans<Date> }
| { type: MonthlyPlansAction.UPDATE_THINGS, values: {
  plan: DailyPlan<Date>,
  things: Thing<Date>[],
} }

export enum MonthlyPlansAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET_ALL = 'SET_ALL',
  UPDATE_THINGS = 'UPDATE_THINGS'
}

export const MonthlyPlansReducer: Reducer<GrouppedDailyPlans<Date>, MonthlyPlansReducerAction> = (state, action) => {
  switch (action.type) {
    case MonthlyPlansAction.ADD:
      return {
        ...state,
        [action.value.deadline.day]: action.value,
      };
    case MonthlyPlansAction.REMOVE:
      return {
        ...state,
        [action.value.deadline.day]: undefined,
      };
    case MonthlyPlansAction.SET_ALL:
      return { ...action.values };
    case MonthlyPlansAction.UPDATE_THINGS:
      return {
        ...state,
        [action.values.plan.deadline.day]: {
          ...action.values.plan,
          things: action.values.things,
        },
      };
    default:
      throw new Error('MonthlyPlansReducer: Undefined action');
  }
}
