import React from 'react';
import { DayElement } from '../interfaces';
import { dayOfWeekToString } from '../Helpers/CalendarDaysHelper';
import glamorous from 'glamorous';
import { Color, Font } from '../../Common/Styles';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  day: DayElement;
}

const BASE_COLOR = Color.twilight;

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  border: `1px solid ${BASE_COLOR}`,
  textAlign: 'center',
  width: '35px',
  height: '42px',
  marginRight: '15px',
});

const DayNumber = glamorous.span({
  color: Color.white,
  backgroundColor: BASE_COLOR,
  padding: '5px 0',
  fontSize: '14px',
  fontFamily: Font.condensed,
  height: '27px'
});

const DayText = glamorous.span({
  textTransform: 'uppercase',
  color: BASE_COLOR,
  fontFamily: Font.condensed,
  fontSize: '11px',
  height: '13px',
  backgroundColor: Color.white,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
});

export const MobileDayNumber = ({ day }: Props) => {
  const { t } = useI18n();
  
  return (
    <Container className="calendar-day-number">
      <DayNumber>
        {day.dayNumber}
      </DayNumber>
      <DayText>
        {day.dayOfWeek !== undefined && t(dayOfWeekToString(day.dayOfWeek, true))}
      </DayText>
    </Container>
  );
}
