import React, { useContext } from 'react';
import glamorous from 'glamorous';
import { LinearProgressBar } from '../../Common/LinearProgressBar';
import { DailyPlan, ThingStatus } from '../../Api/Dto/CommonDto';
import { MonthlyPlansContext } from '../Contexts/MonthlyPlansContext';
import { Color, Font } from '../../Common/Styles';
import useI18n from '../../Common/Hooks/useI18n';

interface Props {
  plan: DailyPlan<Date>;
}

interface TextProps {
  color: string;
}

const Container = glamorous.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingTop: '15px',
});

const ProgressbarContainer = glamorous.div({
  marginTop: '2px',
})

const PlanSummary = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const ThingsQnt = glamorous.span<TextProps>(
  {
    fontFamily: Font.condensed,
    letterSpacing: '0.28px',
    fontSize: '16px',
    lineHeight: 0.9,
    fontWeight: 400,
  },
  ({ color }) => ({
    color,
  }),
);

const ThingsText = glamorous.span<TextProps>(
  {
    fontFamily: Font.condensed,
    letterSpacing: '0.28px',
    fontSize: '12px',
    fontWeight: 400,
  },
  ({ color }) => ({
    color,
  }),
);

const RatingContainer = glamorous.div({
  position: 'absolute',
  top: 0,
  left: 'calc(50% - 18px)',
  width: '30px',
  height: '30px',
  border: `1px solid ${Color.transparent}`,
  borderRadius: '50%',
  color: Color.white,
  backgroundColor: Color.twilight
});

const RatingNumber = glamorous.div({
  width: '100%',
  marginTop: '4px',
  textAlign: 'center',
  fontSize: '16px',
  fontFamily: Font.condensed,
})

export const MobileDayPlanCompleted = ({ plan }: Props) => {
  const { t } = useI18n();
  const { monthlyPlansActions } = useContext(MonthlyPlansContext);

  return (
    <Container>
      <RatingContainer>
        <RatingNumber>
          {plan.rating}
        </RatingNumber>
      </RatingContainer>
      <PlanSummary>
        <ThingsQnt color={Color.appleGreen}>
          {plan.things.filter(thing => thing.status === ThingStatus.DONE || thing.status === ThingStatus.CLOSED).length}
        </ThingsQnt>
        <ThingsQnt color={Color.dark66}>
          {plan.things.filter(thing => thing.status === ThingStatus.TO_DO).length}
        </ThingsQnt>
      </PlanSummary>
      <PlanSummary>
        <ThingsText color={Color.appleGreen}>{t('Done')}</ThingsText>
        <ThingsText color={Color.twilight}>{t('Rating')}</ThingsText>
        <ThingsText color={Color.dark66}>{t('Failed')}</ThingsText>
      </PlanSummary>
      <ProgressbarContainer>
        <LinearProgressBar percentage={monthlyPlansActions.calculatePercentage(plan)} trailColor={Color.paleGrey} size={7} />
      </ProgressbarContainer>
    </Container>
  );
};
