import React from 'react';
import { MenuItemProps } from '../../../Navigation/Interfaces';
import glamorous from 'glamorous';
import { NavLink } from 'react-router-dom';
import { Color, Font, MediaQuery, hexToRgba } from '../../Styles';
import useI18n from '../../Hooks/useI18n';

interface ElementProps {
  isHighlighted: boolean;
}

const Element = glamorous(NavLink)(
  {
    width: '100%',
    display: 'flex',
    padding: '13px 15px',
    color: Color.white,
    textDecoration: 'none',
    transition: 'background-color, .15s',

    '&.active': {
      backgroundColor: Color.twilight,
    },

    '&:hover i, &.active i': {
      opacity: 1,
    },

    '&.highlighted': {
      backgroundColor: hexToRgba(Color.orange, 0.9)
    }
  },
);

const Icon = glamorous.i<ElementProps>(
  {
    fontSize: '24px',
    lineHeight: '26px',
    marginRight: '18px',
    textAlign: 'center',
    transition: 'opacity .15s',
  },
  ({ isHighlighted }) => ({
    opacity: isHighlighted ? 1 : 0.3,
  }),
);

const Text = glamorous.span({
  fontFamily: Font.condensed,
  fontSize: '16px',
  lineHeight: '26px',
  fontWeight: 500,
  textTransform: 'uppercase',
  display: 'inline',

  [MediaQuery.mobileTablet]: {
    display: 'none',
  }
})

export const MainMenuItem = ({ name, icon, url, isExact = true, isHighlighted = false }: MenuItemProps) => {
  const { t } = useI18n();

  return (
  <Element className={isHighlighted ? 'highlighted' : ''} exact={isExact} to={url}>
    <Icon isHighlighted={isHighlighted} className="material-icons">{icon}</Icon><Text>{t(name)}</Text>
  </Element>  
  );
}
