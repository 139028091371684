import { AccessTokenApiClient } from '../AccessTokenApiClient';
import { GrouppedThings, Thing, SearchDto, MessageResponse } from '../Dto/CommonDto';
import { ThingStatus } from "../Dto/ThingDto";
import { IsoDate, Uuid } from '../Interface';
import { transformEntity } from '../Helpers';
import { UserDrawerID } from '../../Things/common';

interface Filter {
  author?: Uuid;
  assiggnee?: Uuid;
  title?: string;
  status: ThingStatus;
  withProject?: string;
}

interface UpdateDataProps {
  title?: string;
  description?: string;
  status?: ThingStatus;
}

interface CreateThingProps {
  title: string;
  drawerId?: Uuid | null;
}

export class ThingApiClient extends AccessTokenApiClient {
  getAll(): Promise<GrouppedThings<Date>> {
    return this.apiRequest
      .get<GrouppedThings<IsoDate>>('')
      .then(({ data }) => {
        const grouppedThings: GrouppedThings<Date> = {};

        Object.keys(data).forEach(drawerId => {
          grouppedThings[drawerId] = data[drawerId].map(thing => transformEntity<Thing<Date>>(thing));
        });

        return grouppedThings;
      });
  }

  getList(params?: SearchDto<Filter>): Promise<Thing<Date>[]> {
    return this
      .apiRequest
        .get<Thing<IsoDate>[]>('drawer', { params: params || {} })
        .then(({ data }) => data.map(thing => transformEntity<Thing<Date>>(thing)));
  }

  create({ title, drawerId }: CreateThingProps): Promise<Thing<Date>> {
    return this.apiRequest
      .post<Thing<IsoDate>>('', {
        title,
        status: ThingStatus.TO_DO,
        ...(drawerId !== UserDrawerID && { projectId: drawerId }),
      })
      .then(({ data }) => transformEntity<Thing<Date>>(data));
  }

  update(thing: Thing<Date>, updateData: UpdateDataProps = {}): Promise<Thing<Date>> {
    if (Object.keys(updateData).length === 0) {
      throw new Error('Missing update data');
    }

    return this
      .apiRequest.patch<Thing<IsoDate>>(thing.id, updateData)
      .then(({ data }) => transformEntity<Thing<Date>>(data));
  }

  delete(id: Uuid): Promise<MessageResponse> {
    return this.apiRequest.delete(id).then(({ data }) => data);
  }

  bulkDelete(ids: Uuid[]): Promise<Thing<IsoDate>[]> {
    return this.apiRequest
      .post<Thing<IsoDate>[]>('bulk-delete', { tasks: ids })
      .then(({ data }) => data);
  }

  moveToDrawer(thingsIds: Uuid | Uuid[], drawerId: Uuid): Promise<Thing<Date>[]> {
    return this.apiRequest
      .post<Thing<IsoDate>[]>('move-to-project', {
        tasks: Array.isArray(thingsIds) ? thingsIds : [thingsIds],
        projectId: drawerId,
      })
      .then(({ data }) => data.map(thing => transformEntity<Thing<Date>>(thing)));
  }
}
