import { GoodBadGenerator, Priority } from './GoodBadGenerator';

export class PraisesGenerator extends GoodBadGenerator {
  public generate(): void {
    this.rateConsistency();
    this.rateEfficiency();
    this.rateBacklog();
    this.rateTimeRemain();
  }

  private rateConsistency() {
    if (this.consistency === 100) {
      this.add(this.t("You're planning every day consistently. You are awesome!"), Priority.CRITICAL);
    } else if (this.consistency > 75) {
      this.add(this.t("You're very consistent in planning. Keep going!"), Priority.TRIVIAL);
    } else if (this.consistency >= 50) {
      this.add(this.t("You know daily plans are very important. Just do them every day."), Priority.GOOD_TO_KNOW);
    }
  }

  private rateEfficiency() {
    if (this.efficiency === 100) {
      this.add(this.t("You did outstanding job today! Keep the tempo and your dreams will come true."), Priority.CRITICAL);
    } else if (this.efficiency > 75) {
      this.add(this.t("You're very efficient in dealing with your things"), Priority.IMPORTANT)
    } else if (this.efficiency >= 50) {
      this.add(this.t("You're on good way with your efficiency."), Priority.GOOD_TO_KNOW);
    }
  }

  private rateBacklog() {
    if (this.factors.d === 1) {
      this.add(this.t("You had perfectly balanced plan. You rock!"), Priority.CRITICAL);
    } else if (this.efficiency > 0.4 && this.efficiency <= 0.7) {
      this.add(this.t("Your plan is balanced very well. Weekly reviews will improve your planning."), Priority.IMPORTANT);
    }
  }

  private rateTimeRemain() {
    if (this.factors.c === 1) {
      this.add(this.t("You plan a day before and review your job after it's done. This is what we call a perfection."), Priority.IMPORTANT);
    } else if (this.factors.c > 0.95) {
      this.add(this.t("You're extraordinary organized. Remember to create plan a day before and review it when a job is done"), Priority.GOOD_TO_KNOW);
    }
  }
}
